import { FC, useState } from 'react';
import { Box, IconButton, InputBase } from '@mui/material';
import { Edit, Numbers, Save } from '@mui/icons-material';
import { GridColumnHeaderParams } from '@mui/x-data-grid';

export interface EditableHeaderProps {
  params: GridColumnHeaderParams;
  onColumnChange: (currentName: string, newName: string) => void;
  onFormatIconClick: () => void;
  isNumeric?: boolean;
  isDate?: boolean;
}
// Component for Editable Header
export const EditableHeader: FC<EditableHeaderProps> = ({
  params,
  onColumnChange,
  onFormatIconClick,
  isNumeric = false,
  isDate = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [headerText, setHeaderText] = useState(params.colDef.headerName);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    // Update column header name if needed
    params.colDef.headerName = headerText;
    onColumnChange(params.field, headerText ?? '');
  };

  return (
    <Box display="flex" alignItems="center">
      {isEditing ? (
        <>
          <InputBase
            value={headerText}
            onChange={(e) => setHeaderText(e.target.value)}
            sx={{ ml: 1, flex: 1 }}
            onClick={(e) => e.stopPropagation()}
          />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleSaveClick();
            }}
            size="small"
          >
            <Save fontSize="small" />
          </IconButton>
        </>
      ) : (
        <>
          <span>{headerText}</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick();
            }}
            size="small"
          >
            <Edit fontSize="small" />
          </IconButton>

          {(isNumeric || isDate) && (
            <IconButton
              role="button"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onFormatIconClick();
              }}
              aria-label="format options"
            >
              <Numbers fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};
