import React, { useRef, useState } from 'react';
// material-ui
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared/constants.ts';
import { queryClient } from '@/api/queryClient';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';

import MainLogo from '@/shared/components/MainLogo';

// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import Transitions from '@themes/components/extended/Transitions';
import { ThemeMode } from '@themes/types/config';
import MainCard from '@themes/components/cards/MainCard';
import AnimateButton from '@themes/components/extended/AnimateButton';
import { useGetMenuMaster } from '@themes/api/menu';
import { DynamicHeader } from '@/modules/workspaces/components/DynamicHeader';
// assets
import User from '@assets/images/users/user-round.svg';
import { IconLogout, IconMenu2, IconServer2, IconSettings, IconShare, IconUser } from '@tabler/icons-react';
import useConfig from '@/themes/hooks/useConfig';

export interface HeaderProps {
  handleModalOpen: () => void;
  handleDrawerState: () => void;
  handleShareModalOpen: () => void;
  title?: string;
  showHeaderEndAdornment?: boolean;
  isWorkspaceOwner?: boolean;
}

const Header = ({
  handleModalOpen,
  handleDrawerState,
  handleShareModalOpen,
  title,
  showHeaderEndAdornment = false,
  isWorkspaceOwner,
}: HeaderProps) => {
  const theme = useTheme();
  const hasAccess = usePowerUserAccess();
  const { mode, borderRadius } = useConfig();
  const navigate = useNavigate();

  const anchorRef = useRef<any>(null);
  const settingsRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
    setSelectedIndex(index);

    if (route && route !== '') {
      navigate(route);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSettingsToggle = () => {
    setSettingsOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={10} md={9}>
          <Stack direction="row" spacing={3} alignItems="center">
            {drawerOpen && <MainLogo />}
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                '&:hover': {
                  background:
                    theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                  color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
                },
                ...(drawerOpen ? { right: '-12px' } : { right: '2px' }),
              }}
              onClick={handleDrawerState}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="20px" />
            </Avatar>
            <DynamicHeader title={title} />
          </Stack>
        </Grid>

        <Grid item xs={2} md={3}>
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-end">
            {showHeaderEndAdornment && (
              <>
                <AnimateButton>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={handleModalOpen}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Ask a Question+
                  </Button>
                </AnimateButton>
                {isWorkspaceOwner && (
                  <Tooltip title="Share the workspace" placement="top">
                    <IconButton
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        width: '38px',
                        height: '38px',
                        border: '1px solid',
                        borderColor:
                          theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        background:
                          theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        transition: 'all .2s ease-in-out',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                          borderColor: theme.palette.primary.main,
                          background: theme.palette.primary.main,
                          color: theme.palette.primary.light,
                        },
                      }}
                      ref={anchorRef}
                      onClick={handleShareModalOpen}
                      color="inherit"
                    >
                      <IconShare stroke={2} size="24px" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {hasAccess && (
              <Tooltip title="Settings" placement="top">
                <IconButton
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    width: '38px',
                    height: '38px',
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    color: theme.palette.primary.dark,
                    transition: 'all .2s ease-in-out',
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      borderColor: theme.palette.primary.main,
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    },
                  }}
                  ref={settingsRef}
                  color="inherit"
                  onClick={handleSettingsToggle}
                >
                  <IconSettings stroke={2} />
                </IconButton>
              </Tooltip>
            )}
            <Avatar
              src={User}
              sx={{
                ...theme.typography.mediumAvatar,
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                color: theme.palette.primary.dark,
                transition: 'all .2s ease-in-out',
                cursor: 'pointer',
                ml: 2,
                height: '48px',
                width: '48px',
                alignItems: 'center',

                bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
                '&[aria-controls="menu-list-grow"], &:hover': {
                  borderColor: 'primary.main',
                  bgcolor: `${theme.palette.primary.main} !important`,
                  color: 'primary.light',
                  '& svg': {
                    stroke: theme.palette.primary.light,
                  },
                },
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              color="inherit"
              onClick={handleToggle}
              aria-label="user-account"
            />
          </Stack>
        </Grid>
        <Popper
          placement="bottom"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Transitions in={open} {...TransitionProps}>
                <Paper>
                  {open && (
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                      <Box sx={{ p: 1 }}>
                        {/* TODO: Move to separete component */}
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            borderRadius: `${borderRadius}px`,
                            '& .MuiListItemButton-root': { mt: 0.5 },
                          }}
                        >
                          {/* Logout Start */}
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 3}
                            onClick={() => {
                              queryClient.removeQueries();
                              localStorage.clear();
                              navigate(ROUTES.AUTH.LOGIN);
                            }}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="body2">
                                      <FormattedMessage id="logout" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </MainCard>
                  )}
                </Paper>
              </Transitions>
            </ClickAwayListener>
          )}
        </Popper>
        <Popper
          placement="bottom"
          open={settingsOpen}
          anchorEl={settingsRef.current}
          role={undefined}
          transition
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => setSettingsOpen(false)}>
              <Transitions in={settingsOpen} {...TransitionProps}>
                <Paper>
                  {settingsOpen && (
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                      <Box sx={{ p: 2, pt: 0 }}>
                        {/* TODO: Move to separete component */}
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            borderRadius: `${borderRadius}px`,
                            '& .MuiListItemButton-root': { mt: 0.5 },
                          }}
                        >
                          {/* Users Start */}
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 0}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                              handleListItemClick(event, 0, ROUTES.USERS.LIST)
                            }
                          >
                            <ListItemIcon>
                              <IconUser stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="body2">
                                      <FormattedMessage id="users" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                          {/* Users End */}

                          {/* Data Sources Start */}
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 1}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                              handleListItemClick(event, 1, ROUTES.DATA_SOURCES.LIST)
                            }
                          >
                            <ListItemIcon>
                              <IconServer2 stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="body2">
                                      <FormattedMessage id="Data Sources" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                          {/* Data Sources End */}
                        </List>
                      </Box>
                    </MainCard>
                  )}
                </Paper>
              </Transitions>
            </ClickAwayListener>
          )}
        </Popper>
      </Grid>
    </>
  );
};

export default Header;
