import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

interface AlertProps {
  title?: string;
  variant?: 'info';
}

const Alert = ({ title, variant = 'info' }: AlertProps) => {
  if (variant === 'info') {
    return (
      <Box sx={{ padding: '15px 32px 0' }}>
        <Paper variant="elevation" sx={{ borderRadius: '4px', padding: '20px', backgroundColor: '#f5f5f5' }}>
          <Typography variant="body1">{title}</Typography>
        </Paper>
      </Box>
    );
  }

  return null;
};

export default Alert;
