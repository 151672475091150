import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout';
import BigQueryImage from '@assets/images/datasources/bigquery-google.png';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared/constants';

// material-ui
import {
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Grid,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material';
import MainCard from '@/themes/components/cards/MainCard';
import Accordion from '@/themes/components/extended/Accordion';
import { IconSearch } from '@tabler/icons-react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { CategoriesFilter } from '@/modules/datasources/filters/CategoriesFilter';

// Base size wich used in the card size calculation
const BASE_CARD_SIZE_UNIT = 36;

/**
 * Represents a choose data source page component.
 *
 * @component
 * @returns {React.ReactNode} The choose data source page component.
 */
export const ChooseDataSourcePage = () => {
  const navigate = useNavigate();

  // Handle choose data source
  const handleChooseDataSource = (dataSource: string) => {
    navigate({
      pathname: ROUTES.DATA_SOURCES.CONNECT,
      search: createSearchParams({
        data_source: dataSource,
      }).toString(),
    });
  };

  const filterData = [
    {
      id: 'categories',
      defaultExpand: true,
      title: 'Categories',
      content: <CategoriesFilter />,
    },
  ];

  return (
    <AuthorizedPageLayout title={'Connect Data Source'}>
      <MainCard
        title={
          <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h3">Select Data Source</Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1}>
                <OutlinedInput
                  id="input-search-list-style1"
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start">
                      <IconSearch stroke={1.5} size="16px" />
                    </InputAdornment>
                  }
                  size="small"
                />
                <Button
                  size="large"
                  aria-label="navigation icon"
                  startIcon={<NavigateNextRoundedIcon sx={{ transform: 'rotate(180deg)' }} />}
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>
              </Stack>
            </Grid>
          </Grid>
        }
        content={false}
      />
      <Grid container justifyContent="space-between">
        {/* Cards of data sources */}
        <Grid item xs={9}>
          <Card sx={{ background: 'transparent' }}>
            <Grid container>
              <Grid item xs={3} sx={{ pt: 5 }}>
                <Card
                  sx={{
                    boxShadow: 3,
                    width: 'max-content',
                    ':hover': {
                      boxShadow: 5,
                      transform: 'scale(1.05)',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  <CardActionArea
                    sx={{ width: BASE_CARD_SIZE_UNIT * 6, height: BASE_CARD_SIZE_UNIT * 4 }}
                    onClick={() => handleChooseDataSource('big_query')}
                  >
                    <Stack direction="column" spacing={1} justifyItems="center" alignItems="center">
                      <CardMedia
                        component="img"
                        height="72"
                        sx={{ maxHeight: BASE_CARD_SIZE_UNIT * 2, maxWidth: BASE_CARD_SIZE_UNIT * 2 }}
                        image={BigQueryImage}
                        title="BigQuery"
                      />
                      <Typography variant="h5">BigQuery</Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* Card with filters */}
        <Grid item xs={3}>
          <Card sx={{ background: 'transparent', width: 1 }}>
            <Grid container>
              <Grid item xs={12} sx={{ pt: 5 }}>
                <Card sx={{ boxShadow: 3 }}>
                  <CardHeader title="Filters" />
                  <Accordion data={filterData} />
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </AuthorizedPageLayout>
  );
};
