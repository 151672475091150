import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { Box, Tab, Tabs, Grid, Button } from '@mui/material';

// project imports
import UserProfileTab from '@/modules/users/components/UserProfileTab';
import MainCard from '@themes/components/cards/MainCard';
import { UserProfileActionsEnum } from '@modules/users/types.ts';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

export interface TabsProps {
  children?: React.ReactElement | React.ReactNode | string;
  value: string | number;
  index: number;
}

interface UserViewProps {
  title: string;
  mode: UserProfileActionsEnum;
}

// tabs
function TabPanel({ children, value, index, ...other }: TabsProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UserView = ({ title, mode }: UserViewProps) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <MainCard
      title={
        <Grid container justifyContent="space-between">
          {title}
          <Button
            size="large"
            aria-label="navigation icon"
            startIcon={<NavigateNextRoundedIcon sx={{ transform: 'rotate(180deg)' }} />}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        </Grid>
      }
    >
      <div>
        <Tabs
          value={value}
          indicatorColor="primary"
          onChange={handleChange}
          sx={{
            mb: 3,
            minHeight: 'auto',
            '& button': {
              minWidth: 100,
            },
            '& a': {
              minHeight: 'auto',
              minWidth: 10,
              py: 1.5,
              px: 1,
              mr: 2.25,
              color: 'grey.600',
            },
            '& a.Mui-selected': {
              color: 'primary.main',
            },
          }}
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab component={Link} to="#" label="Profile" {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserProfileTab mode={mode} />
        </TabPanel>
      </div>
    </MainCard>
  );
};

export default UserView;
