import { SUPPORTED_CHART_TYPES } from '@shared/constants';
import { ApexOptions } from 'apexcharts';
import DownloadIcon from '@/assets/images/icons/download.png';
import { ChartColorScheme, ChartPalette } from '@modules/query/types';

type SupportedChartType = (typeof SUPPORTED_CHART_TYPES)[number];

export interface ToolbarCustomIcon {
  icon?: string;
  title?: string;
  index?: number;
  class?: string;

  click?(chart?: any, options?: any, e?: any): any;
}

export const formatNumbersInString = (input: any) => {
  if (input === null) {
    return undefined as any;
  }

  if (!['number', 'string'].includes(typeof input)) {
    return input;
  }

  return String(input).replace(/\d+(\.\d+)?/g, (match) => {
    const num = parseFloat(match);

    // Check if the number has two or more decimal places
    if (match.includes('.') && match.split('.')[1].length > 2) {
      return num.toFixed(2);
    }

    return match; // Return the original number if no formatting is needed
  });
};

// Common toolbar options for all charts
const getToolbar = (customIcons?: ToolbarCustomIcon[], disableToolbar = false): ApexChart['toolbar'] => {
  return {
    show: !disableToolbar,
    tools: {
      ...(customIcons && { customIcons }),
      download: disableToolbar ? false : `<img src=${DownloadIcon} alt="download" class="ico-download" width="30" />`,
      selection: false,
      zoom: false,
      zoomin: false,
      zoomout: false,
      pan: false,
      reset: false,
    },
  };
};

// Common x-axis options for all charts
const xaxis: ApexXAxis = {
  categories: [],
  labels: {
    hideOverlappingLabels: true,
    trim: true,
    formatter: formatNumbersInString,
  },
  tooltip: {
    formatter: formatNumbersInString,
  },
};

const yaxis = {
  labels: {
    formatter: formatNumbersInString,
  },
};

const getDataLabels = (formatter?: ApexDataLabels['formatter']): ApexDataLabels => {
  return {
    enabled: true,
    formatter: formatter ?? formatNumbersInString,
  };
};

const legend: ApexLegend = {
  showForSingleSeries: true,
};

const Events = {
  mounted: (chart) => {
    chart.windowResizeHandler();
  },
};

const getColorSchemeTheme = (colorScheme?: ChartColorScheme) => {
  return {
    palette: colorScheme?.palette || ChartPalette.Palette1,
  };
};

interface GetChartOptionsParams {
  type: SupportedChartType;
  colorScheme?: ChartColorScheme;
  toolbarCustomIcons?: ToolbarCustomIcon[];
  disableToolbar?: boolean;
  xAxisFormatter?: (val: any, params: any) => any;
  yAxisFormatter?: (val: any, params: any) => any;
}

/**
 * Chart props helper which returns the default chart options for the given chart type.
 * Allows to manage the chart options in a single place.
 * Specific to the ApexCharts library.
 *
 * @param {SupportedChartType} type - The type of the chart.
 * @param {ChartColorScheme} colorScheme - The color scheme for the chart.
 * @param {ToolbarCustomIcon[]} toolbarCustomIcons - Custom icons for the toolbar.
 * @param {boolean} disableToolbar - Whether to disable the toolbar.
 * @param {ApexDataLabels['formatter']} xAxisFormatter - The formatter function for the x-axis.
 * @param {ApexDataLabels['formatter']} yAxisFormatter - The formatter function for the y-axis.
 * @returns {ApexOptions} The default chart options for the given chart type.
 */
const getDefaultChartOptions = ({
  type,
  colorScheme,
  toolbarCustomIcons,
  disableToolbar,
  xAxisFormatter,
  yAxisFormatter,
}: GetChartOptionsParams): ApexOptions => {
  const toolbar = getToolbar(toolbarCustomIcons, disableToolbar);
  const primaryColor = colorScheme?.palette ? undefined : 'rgb(103, 58, 183)';
  const theme = getColorSchemeTheme(colorScheme);
  const dataLabels = getDataLabels(yAxisFormatter);

  switch (type) {
    case 'bar':
      return {
        theme,
        legend,
        chart: {
          events: Events,
          toolbar,
          stacked: true,
        },
        plotOptions: { bar: { horizontal: false, dataLabels: { orientation: 'vertical' } } },
        xaxis: {
          ...xaxis,
          labels: {
            ...xaxis.labels,
            formatter: xAxisFormatter,
          },
        },
        yaxis,
        dataLabels,
      };
    case 'line':
      return {
        theme,
        legend,
        chart: {
          events: Events,
          toolbar,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        markers: {
          size: 6,
          colors: ['#fff'],
          strokeWidth: 2,
          hover: {
            size: 8,
          },
        },
        xaxis: {
          ...xaxis,
          tickAmount: 10,
          labels: {
            ...xaxis.labels,
            formatter: xAxisFormatter,
          },
        },
        yaxis: {
          labels: {
            formatter: yAxisFormatter,
            style: {
              fontSize: '12px',
            },
          },
          title: {
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: yAxisFormatter,
          style: {
            colors: ['#333'],
            fontSize: '12px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            borderRadius: 4,
          },
        },
        grid: {
          borderColor: 'rgba(103, 58, 183, 0.2)',
        },
        tooltip: {
          theme: 'light',
          style: {
            fontSize: '12px',
          },
        },
      };
    case 'area':
      return {
        theme,
        legend,
        chart: {
          events: Events,
          toolbar,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
        },
        markers: {
          size: 5,
          colors: ['#fff'],
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        xaxis: {
          ...xaxis,
          tickAmount: 10,
          labels: {
            ...xaxis.labels,
            formatter: xAxisFormatter,
            style: {
              ...xaxis?.labels?.style,
              colors: '#333',
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: yAxisFormatter,
            style: {
              colors: '#333',
              fontSize: '12px',
            },
          },
          title: {
            style: {
              color: primaryColor,
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: yAxisFormatter,
          style: {
            colors: ['#333'],
            fontSize: '12px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            borderRadius: 4,
          },
        },
        grid: {
          borderColor: 'rgba(103, 58, 183, 0.2)',
        },
        tooltip: {
          theme: 'light',
          style: {
            fontSize: '12px',
          },
        },
      };
    case 'boxPlot':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        plotOptions: { boxPlot: { colors: { upper: '#00E396', lower: '#008FFB' } } },
        xaxis,
        yaxis,
        dataLabels,
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
      };
    case 'rangeBar':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        plotOptions: { bar: { horizontal: true } },
        xaxis,
        yaxis,
        dataLabels,
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
      };
    case 'rangeArea':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        stroke: { curve: 'smooth' },
        xaxis,
        yaxis,
        dataLabels,
      };
    case 'heatmap':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        plotOptions: { heatmap: { shadeIntensity: 0.5, radius: 0, useFillColorAsStroke: true } },
        yaxis,
        xaxis: { tooltip: { enabled: false } },
        dataLabels: { enabled: false },
      };
    case 'treemap':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        plotOptions: { treemap: { distributed: true } },
        dataLabels: { enabled: true },
        yaxis: { labels: { show: false } },
        xaxis: { labels: { show: false } },
      };
    case 'donut':
      return {
        theme,
        chart: { toolbar, events: Events },
        labels: [],
        legend: { ...legend, position: 'right' },
        yaxis,
        dataLabels,
      };
    case 'pie':
      return {
        theme,
        chart: { toolbar, events: Events },
        labels: [],
        legend: { ...legend, position: 'right' },
        yaxis,
        dataLabels,
      };
    case 'radar':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        stroke: { width: 2 },
        fill: { opacity: 0.1 },
        markers: { size: 4 },
        xaxis,
        yaxis,
        dataLabels,
      };
    case 'radialBar':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        yaxis,
        dataLabels,
      };
    case 'scatter':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        yaxis,
        xaxis,
        dataLabels,
      };
    case 'bubble':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        yaxis,
        dataLabels,
      };
    case 'candlestick':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        yaxis,
        dataLabels,
      };

    case 'polarArea':
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        yaxis,
        dataLabels,
      };

    default:
      return {
        theme,
        legend,
        chart: { toolbar, events: Events },
        xaxis,
        yaxis,
        dataLabels,
      };
  }
};

export default getDefaultChartOptions;
