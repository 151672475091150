import { FC } from 'react';
import { RgbaColor } from 'react-colorful';
import { Box } from '@mui/material';

interface SwatchesPickerProps {
  onChange: (color: RgbaColor) => void;
  presetColors: RgbaColor[];
}

export const SwatchesPicker: FC<SwatchesPickerProps> = ({ onChange, presetColors }) => {
  return (
    <Box className="picker" sx={{ position: 'absolute', right: 0, top: 55, zIndex: 9 }}>
      {/* <HexColorPicker color={color} onChange={onChange} /> */}
      {/* <RgbaColorPicker color={color} onChange={onChange} /> */}

      <div className="picker__swatches">
        {presetColors.map((presetColor) => {
          return (
            <button
              key={`rgba(${presetColor.r}, ${presetColor.g}, ${presetColor.b}, ${presetColor.a})`}
              className="picker__swatch"
              style={{ background: `rgba(${presetColor.r}, ${presetColor.g}, ${presetColor.b}, ${presetColor.a})` }}
              onClick={() => onChange(presetColor)}
            />
          );
        })}
      </div>
    </Box>
  );
};
