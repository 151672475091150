import { useMutation, useQuery } from '@tanstack/react-query';

import { login } from '@/modules/auth/api.ts';
import { getCurrentUser } from '@/modules/users/api.ts';
import { Login } from '@/modules/auth/types.ts';
import { UserResponse } from '@/modules/users/types.ts';

import { REFRESH_TOKEN_LIFETIME, LOCAL_STORAGE } from '@shared/constants';

export const CURRENT_USER_QUERY_KEY = 'xanalytica/current-user';

export const useLogInUserMutation = () => {
  return useMutation({
    gcTime: 1000 * 60 * 60 * 24 * 7, // 7 days
    mutationFn: async (loginRequest: Login) => {
      const response = await login(loginRequest);
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem(LOCAL_STORAGE.IS_LOGGED_IN, 'true');
      localStorage.setItem(LOCAL_STORAGE.TOKEN, data.access_token);
      localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, data.refresh_token);
      localStorage.setItem(LOCAL_STORAGE.TOKEN_TYPE, data.token_type);
    },
  });
};

export const useCurrentUserQuery = (disabled = false) => {
  const query = useQuery<UserResponse>({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: async () => {
      const response = await getCurrentUser();
      return response.data;
    },
    staleTime: REFRESH_TOKEN_LIFETIME,
    refetchInterval: REFRESH_TOKEN_LIFETIME,
    enabled: disabled,
  });

  return query;
};
