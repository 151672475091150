import { FC, useCallback, useMemo, useState } from 'react';
import { QueryRawData, QueryRawDataItem, TableColorScheme } from '@/modules/query/types.ts';
import { EditableHeader } from '@/modules/query/components/table/header/EditableHeader.tsx';
import { ModalTableToolbar } from '@/modules/query/components/table/toolbar/ModalTableToolbar';
import { BaseTable } from '@/shared/components/BaseTable';
import { getEditableColumns, getRows, renameKeyInObject } from '@/modules/query/helpers/tableHelper.tsx';
import { FieldsToFormat, isNumber, ItemFormatMap } from '@shared/helpers/formatCellValue.ts';
import { GridCellParams, GridEventListener, GridRowModel, GridSortModel } from '@mui/x-data-grid';
import { isDate } from 'lodash';

interface ModalTableProps {
  data: QueryRawData;
  onDataChange: (data: QueryRawData) => void;
  isOwner: boolean;
  isIconButtonClicked: boolean;
  onVisualizationSave: VoidFunction;
  onDrawerOpen: () => void;
  colorScheme?: TableColorScheme;
  columnsFormatters?: ItemFormatMap;
  selectDataToFormat: (data: FieldsToFormat) => void;
  onSortChange: (sort: number[]) => void;
}

export const ModalTable: FC<ModalTableProps> = ({
  data,
  isOwner,
  onVisualizationSave,
  isIconButtonClicked,
  onDrawerOpen,
  onDataChange,
  colorScheme,
  columnsFormatters = {},
  selectDataToFormat,
  onSortChange,
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  // Update table state with new cell value
  const processRowUpdate = useCallback(
    (updatedRow: GridRowModel) => {
      const { id: updatedRowId, ...updatedRowData } = updatedRow;
      onDataChange(
        data.map((row, index) => {
          return index === updatedRowId ? { ...row, ...updatedRowData } : row;
        })
      );
      return updatedRow;
    },
    [data, onDataChange]
  );

  // TODO: update cell formatters on table column change
  const handleTableColumnNameChange = useCallback(
    (currentName: string, newName: string) => {
      onDataChange(data.map((row) => renameKeyInObject(row, currentName, newName) as QueryRawDataItem));
    },
    [data, onDataChange]
  );

  const handleCellClick: GridEventListener<'cellClick'> = useCallback(
    (params: GridCellParams) => {
      const column = params.field;
      if (isNumber(params.value)) {
        selectDataToFormat({ field: column, type: 'number' });
      }
      if (isDate(params.value)) {
        selectDataToFormat({ field: column, type: 'date' });
      }
    },
    [selectDataToFormat]
  );

  const columns = useMemo(
    () => getEditableColumns(data, EditableHeader, handleTableColumnNameChange, selectDataToFormat, columnsFormatters),
    [data, handleTableColumnNameChange, selectDataToFormat, columnsFormatters]
  );

  const rows = useMemo(() => getRows(data), [data]);

  return (
    <BaseTable
      columns={columns}
      rows={rows}
      onCellClick={handleCellClick}
      editMode="cell"
      processRowUpdate={processRowUpdate}
      slots={{
        toolbar: () => (
          <ModalTableToolbar
            onOpen={onDrawerOpen}
            isOwner={isOwner}
            handleChartSave={onVisualizationSave}
            isIconButtonClicked={isIconButtonClicked}
          />
        ),
      }}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      colorScheme={colorScheme}
      sx={{ minHeight: '100%' }}
      onStateChange={(params) => {
        onSortChange(params.sorting.sortedRows);
      }}
    />
  );
};
