import { httpClient } from '@/api/httpClient.ts';
import { WORKSPACE_ROUTES } from '@/api/routes.ts';
import { AxiosResponse } from 'axios';
import {
  GetWorkspaceResponse,
  GetPaginateWorkspaceResponse,
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  ShareWorkspaceRequest,
  UnshareWorkspaceRequest,
} from '@/modules/workspaces/types.ts';
import { UsersPaginateResponse } from '@/modules/users/types.ts';
import { generatePath } from 'react-router-dom';

/**
 * Returns a list of users.
 *
 * @async
 * @returns {Promise<AxiosResponse<UsersPaginateResponse, any>>} The list of users.
 */
export const getWorkspaceList = async (
  page: number,
  pageSize: number
): Promise<AxiosResponse<GetPaginateWorkspaceResponse>> => {
  return httpClient.get(WORKSPACE_ROUTES.WORKSPACES, { params: { page, size: pageSize } });
};

/**
 * Returns a specific workspace.
 *
 * @async
 * @returns {Promise<AxiosResponse<GetWorkspaceResponse>>} Workspace data.
 */
export const getWorkspace = async (id: number): Promise<AxiosResponse<GetWorkspaceResponse>> => {
  const path = generatePath(WORKSPACE_ROUTES.WORKSPACE, { id });
  return httpClient.get(path);
};

/**
 * Updates a workspace.
 *
 * @async
 * @param {any} data - The workspace data to create.
 * @returns {Promise<AxiosResponse<UpdateWorkspaceResponse>>} The created workspace.
 */
export const updateWorkspace = async (
  id: number,
  data: UpdateWorkspaceRequest
): Promise<AxiosResponse<UpdateWorkspaceResponse>> => {
  const path = generatePath(WORKSPACE_ROUTES.WORKSPACE, { id });
  return httpClient.put(path, data, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * Creates a new workspace.
 */
export const createWorkspace = async ({
  name,
}: CreateWorkspaceRequest): Promise<AxiosResponse<CreateWorkspaceResponse>> => {
  return httpClient.post(WORKSPACE_ROUTES.WORKSPACES, { name }, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * Deletes a workspace.
 */
export const deleteWorkspace = async (id: number): Promise<AxiosResponse<unknown>> => {
  const path = generatePath(WORKSPACE_ROUTES.WORKSPACE, { id });
  return httpClient.delete(path);
};

/**
 * Shares a workspace.
 */
export const shareWorkspace = async (id: number, payload: ShareWorkspaceRequest[]): Promise<AxiosResponse<unknown>> => {
  const path = generatePath(WORKSPACE_ROUTES.SHARE_WORKSPACE, { id });
  return httpClient.post(path, payload, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * Unshares a workspace.
 */
export const unshareWorkspace = async (
  id: number,
  payload: UnshareWorkspaceRequest
): Promise<AxiosResponse<unknown>> => {
  const path = generatePath(WORKSPACE_ROUTES.UNSHARE_WORKSPACE, { id });
  return httpClient.post(path, payload, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * Returns a list of recipients workspace shared with.
 */
export const getSharedWorkspaceRecipients = async (id: number): Promise<AxiosResponse<UsersPaginateResponse>> => {
  const path = generatePath(WORKSPACE_ROUTES.SHARED_WORKSPACE_RECIPIENTS, { id });
  return httpClient.get(path);
};
