import { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { TableColorScheme } from '@modules/query/types';
import { formatRgbaToString } from '@/shared/helpers/colorFormatters';
import { ColorSelect } from '@/modules/query/components/table/customization/ColorSelect.tsx';
import { RgbaColor } from 'react-colorful';

interface CustomStylesTableProps {
  onColorChange: (scheme: TableColorScheme) => void;
  colorScheme: TableColorScheme;
}

export const CustomStylesTable: FC<CustomStylesTableProps> = ({ onColorChange, colorScheme }) => {
  const handleHeaderColorChange = (color: RgbaColor) => {
    onColorChange({ ...colorScheme, header: color });
  };

  const handlePrimaryRowColorChange = (color: RgbaColor) => {
    onColorChange({ ...colorScheme, primaryRow: color });
  };

  const handleSecondaryRowColorChange = (color: RgbaColor) => {
    onColorChange({ ...colorScheme, secondaryRow: color });
  };

  return (
    <Box>
      {/* Custom Color Schemas */}
      <Typography variant="h4">Custom styles</Typography>

      {/* Color Options */}
      <Box mt={2}>
        <Box display="flex" height={50} mb={1} gap={2} alignItems="center">
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              p: 1,
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #eee',
              backgroundColor: formatRgbaToString(colorScheme.header),
            }}
          >
            <Typography variant="body1" sx={{ flex: 1 }}>
              Header
            </Typography>
          </Paper>
          <ColorSelect selectedColor={colorScheme.header} onChange={handleHeaderColorChange} />
        </Box>

        <Box display="flex" height={50} mb={1} gap={2} alignItems="center">
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              p: 1,
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #eee',
              backgroundColor: formatRgbaToString(colorScheme.primaryRow),
            }}
          >
            <Typography variant="body1" sx={{ flex: 1 }}>
              Primary Row
            </Typography>
          </Paper>
          <ColorSelect selectedColor={colorScheme.primaryRow} onChange={handlePrimaryRowColorChange} />
        </Box>

        <Box display="flex" height={50} mb={1} gap={2} alignItems="center">
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              p: 1,
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #eee',
              backgroundColor: formatRgbaToString(colorScheme.secondaryRow),
            }}
          >
            <Typography variant="body1" sx={{ flex: 1 }}>
              Secondary Row
            </Typography>
          </Paper>
          <ColorSelect selectedColor={colorScheme.secondaryRow} onChange={handleSecondaryRowColorChange} />
        </Box>
      </Box>
    </Box>
  );
};
