import { AxiosError, AxiosResponse } from 'axios';
import { FormikErrors, FormikValues } from 'formik';
import { Renderable, toast } from 'react-hot-toast';

export interface XAnalyticaApiError {
  error_code: number;
  error_detail: string;
  error_message: string;
  validation_errors: ValidationErrorDetailItem[];
  detail: string | object;
}

export interface ValidationErrorDetailItem {
  loc: string[];
  msg: string;
  type: string;
}

export function formatApiErrorMessage(error: AxiosError<XAnalyticaApiError>): string {
  return (
    error.response?.data?.error_message || (error.response?.data?.detail as string) || error.message || 'Unknown error'
  );
}

export function extractValidationErrors(error: AxiosError<XAnalyticaApiError>): FormikErrors<FormikValues> {
  if (error.response?.status === 400 && error.response?.data?.validation_errors) {
    return error.response?.data?.validation_errors.reduce((acc, item) => {
      const key = item.loc.filter((node) => node !== 'body').join('.');
      acc[key] = item.msg;
      return acc;
    }, {} as FormikErrors<FormikValues>);
  }
  if (error.response?.status === 400 && error.response?.data?.detail) {
    return error.response?.data.detail as FormikErrors<FormikValues>;
  }
  return {};
}

export async function withErrorHandling<T>(
  fn: Promise<AxiosResponse<T>>,
  params: {
    loading: string | Renderable;
    success: string | Renderable;
    setErrors?: (errors: FormikErrors<FormikValues>) => void;
    notificationDuration?: number;
    customErrorMessage?: string;
  } = {
    loading: 'Loading...',
    success: 'Success!',
    notificationDuration: 2500,
    customErrorMessage: undefined,
  }
): Promise<AxiosResponse<T>> {
  const response = await toast.promise(
    fn,
    {
      loading: params.loading,
      success: params.success,
      error: (e: AxiosError<XAnalyticaApiError>) => {
        if (e.response?.status === 400 && params?.customErrorMessage) return <div>{params?.customErrorMessage}</div>;
        if (e.response?.status === 400 && e.response?.data?.validation_errors && params.setErrors) {
          params.setErrors?.(extractValidationErrors(e));
          return <div>Validation error occurred, check your input data.</div>;
        }
        if (e.response?.status === 400 && e.response?.data?.detail && params.setErrors) {
          if (typeof e.response?.data?.detail === 'string') {
            return <div>{e.response?.data?.detail}</div>;
          }
          params.setErrors?.(extractValidationErrors(e));
          return <div>Validation error occurred, check your input data.</div>;
        }
        return <div>{formatApiErrorMessage(e)}</div>;
      },
    },
    { duration: params.notificationDuration ?? 2500 }
  );
  return response;
}
