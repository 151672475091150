import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';

import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { PinButton } from '@modules/query/components/PinButton.tsx';
import {
  ChartColorScheme,
  ChartType,
  QueryRawData,
  StoredChartVisualization,
  StoredTableVisualization,
} from '@/modules/query/types.ts';
import Fade from '@mui/material/Fade';
import getDefaultChartOptions, {
  formatNumbersInString,
  ToolbarCustomIcon,
} from '@shared/helpers/charts/chartPropsHelper.ts';
import { getChartDataFromVisualization } from '@modules/query/helpers/getChartDataFromVisualization.ts';
import { CellFormat, formatCellValue } from '@shared/helpers/formatCellValue.ts';

export interface ModalChartProps {
  question: string;
  chartType: ChartType;
  categoryKey: string;
  seriesKeys: string[];
  isOwner: boolean;
  isIconButtonClicked?: boolean;
  onVisualizationSave: (data: StoredChartVisualization | StoredTableVisualization) => void;
  colorScheme?: ChartColorScheme;
  onSelectChartToStyle?: (chartType: ChartType) => void;
  children?: ReactNode;
  onPreview?: () => void;
  dataFormatters?: Record<string, CellFormat>;
  preview?: boolean;
  data: QueryRawData;
}

/**
 * Displays a chart for the provided question, type, and data in the AskQuestionModal.
 */
export const ModalChart: FC<ModalChartProps> = ({
  question,
  chartType,
  categoryKey,
  seriesKeys,
  isOwner,
  isIconButtonClicked,
  onVisualizationSave,
  onSelectChartToStyle,
  onPreview,
  colorScheme,
  preview,
  data,
  dataFormatters,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { categories, series, yAxisColumnName } = getChartDataFromVisualization(
    data,
    chartType,
    categoryKey,
    seriesKeys
  );

  const yAxisFormatter: ApexDataLabels['formatter'] = useCallback(
    (val) => {
      const formatType = yAxisColumnName ? dataFormatters?.[yAxisColumnName] : undefined;
      if (formatType) {
        return formatCellValue(val, formatType);
      }

      return formatNumbersInString(val);
    },
    [dataFormatters, yAxisColumnName]
  );

  const xAxisFormatter = useCallback(
    (val) => {
      // Format categories corresponding to the format of the column
      const categoryFormat = categoryKey ? dataFormatters?.[categoryKey] : undefined;
      if (categoryFormat) {
        return formatCellValue(val, categoryFormat);
      }
      return formatNumbersInString(val);
    },
    [dataFormatters, categoryKey]
  );

  const chartOptions = useMemo(() => {
    const chartStyleIcon: ToolbarCustomIcon = {
      icon: `<svg fill="gray" focusable="false" viewBox="0 0 24 24" width="24px" height="24px" aria-hidden="true"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path></svg>` as any,
      index: 0,
      title: 'Customization',
      class: 'customization-icon',
      click: (_chart?: any, _options?: any, e?: any) => {
        e?.stopPropagation();
        onSelectChartToStyle?.(chartType);
      },
    };
    return getDefaultChartOptions({
      type: chartType,
      colorScheme,
      toolbarCustomIcons: preview ? [chartStyleIcon] : undefined,
      disableToolbar: !preview,
      xAxisFormatter,
      yAxisFormatter,
    });
  }, [chartType, onSelectChartToStyle, colorScheme, preview, xAxisFormatter, yAxisFormatter]);

  const chartVisualization: StoredChartVisualization = useMemo(
    () => ({
      series,
      categories,
      xAxisColumnName: categoryKey,
      yAxisColumnName,
      chart: {
        type: chartType,
      },
    }),
    [series, categories, chartType, yAxisColumnName, categoryKey]
  );

  const isPreviewOverlayShown = isHovered && !!onPreview;
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: '0 !important',
        '& .customization-icon': {
          position: 'relative',
          top: '3px',
          marginLeft: '25px',
          zIndex: 999,
        },
        ...(preview && {
          '& .apexcharts-toolbar': {
            right: '80px !important',
          },
        }),
      }}
    >
      <CardContent
        sx={{
          width: '100%',
          height: '100%',
          padding: '0 !important',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: 1 }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            {question}
          </Typography>

          {children}
        </Box>

        <PinButton
          isOwner={isOwner}
          handleChartSave={() => onVisualizationSave(chartVisualization)}
          isIconButtonClicked={!!isIconButtonClicked}
          sx={{
            position: 'absolute',
            top: '28px',
            right: preview ? '30px' : '0px',
            cursor: 'pointer',
            zIndex: 3,
          }}
        />

        <Box
          data-testid="preview-box"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            minHeight: '300px',
            zIndex: 1,
            padding: 0,
          }}
        >
          <Fade in={isPreviewOverlayShown}>
            <Box
              sx={{
                // TODO: use to styled component instead
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                border: '1px solid #ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                zIndex: 2,
                borderRadius: 4,
              }}
            >
              <Button variant="contained" color="primary" onClick={onPreview}>
                Preview
              </Button>
            </Box>
          </Fade>

          <Chart
            // TODO: think of a better solution for re-render
            key={chartType + Object.entries(dataFormatters ?? {}).join(',')}
            type={chartType}
            series={series ?? []}
            options={{
              ...chartOptions,
              chart: { ...chartOptions.chart, type: chartType },
              xaxis: {
                ...chartOptions?.xaxis,
                categories,
              },
            }}
            width="100%"
            height="100%"
          />
        </Box>
      </CardContent>
    </Card>
  );
};
