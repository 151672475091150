import { Props } from 'react-apexcharts';
import { WorkspaceTable } from '@/modules/query/components/WorkspaceTable.tsx';
import { QueryRawData } from '@modules/query/types.ts';
import { TableColorScheme } from '@modules/query/types';
import { WorkspaceItemCard } from '@modules/workspaces/components/WorkspaceItemCard.tsx';

interface TableItemContentProps {
  rawData: Props;
  query?: string;
  colorScheme?: TableColorScheme;
}

export const TableItemContent = ({ rawData, query, colorScheme }: TableItemContentProps) => {
  return (
    <WorkspaceItemCard query={query}>
      <WorkspaceTable rawData={rawData as QueryRawData} colorScheme={colorScheme} />
    </WorkspaceItemCard>
  );
};
