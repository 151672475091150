import { Box, Card, CardContent, Typography } from '@mui/material';
import { fontSizeHelper } from '@/modules/query/helpers/fontSizeHelper';
import { SingleItemData } from '@/modules/workspaces/types';
import ResizeObserverComponent from '@shared/components/ResizeObserverComponent.tsx';

interface SingleItemContentProps {
  el: SingleItemData;
}

// TODO: Use WorkspaceItemCard instead
export const SingleItemContent = ({ el }: SingleItemContentProps) => {
  const lines = String(el.chartData).split('\n');
  const largestLine = lines.reduce((a, b) => (a.length > b.length ? a : b), '');
  return (
    <Card sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <CardContent sx={{ padding: 0, width: '100%', height: '100%' }}>
        <Typography sx={{ fontSize: 14, alignSelf: 'flex-start', padding: 0 }} color="text.secondary" gutterBottom>
          {el.query}
        </Typography>
      </CardContent>
      <CardContent sx={{ flexGrow: 2, flexShrink: 0.7, padding: '0 !important', width: '100%', height: '100%' }}>
        <ResizeObserverComponent>
          {(width) => (
            <Box
              sx={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%', width: '100%' }}
            >
              {lines.map((line, index) => (
                <Typography
                  key={index}
                  variant="h1"
                  sx={{ fontSize: fontSizeHelper(largestLine, width) }}
                  align="center"
                  whiteSpace="nowrap"
                >
                  {line}
                </Typography>
              ))}
            </Box>
          )}
        </ResizeObserverComponent>
      </CardContent>
    </Card>
  );
};
