import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout';
import UserView from '@/modules/users/components/UserView';
import { UserProfileActionsEnum } from '@/modules/users/types.ts';

const EditUserPage = () => {
  return (
    <AuthorizedPageLayout title={'Edit User'}>
      <UserView title="Edit User Accout" mode={UserProfileActionsEnum.EDIT} />
    </AuthorizedPageLayout>
  );
};

export default EditUserPage;
