import { useDeleteDatasourceMutation, useGetDataSourcesQuery } from '@modules/datasources/queries.ts';
import { DataSourceListItem } from '@modules/datasources/components/DataSourceList/DataSourceListItem';
// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Loader from '@themes/components/Loader.tsx';
import ConfirmationModal from '@/shared/components/ConfirmationModal';
import { useState } from 'react';
import { DataSource } from '@modules/datasources/types.ts';
import toast from 'react-hot-toast';
import { useCurrentUser } from '@modules/auth/hooks/useCurrentUser.ts';
import * as _ from 'lodash';
/**
 * Renders a list of data sources.
 * @returns The DataSourceList component.
 */
const DataSourceList = () => {
  const [dataSourceToDelete, setDataSourceToDelete] = useState<DataSource | null>(null);
  const currentUser = useCurrentUser();
  const { data: dataSources, isFetching } = useGetDataSourcesQuery(currentUser?.id, currentUser?.tenant_id);
  const { mutate: deleteDataSource } = useDeleteDatasourceMutation();

  const onDeleteDataSource = async () => {
    try {
      if (!dataSourceToDelete) return;
      await deleteDataSource(dataSourceToDelete.id);
      toast.success('Data source deleted successfully');
    } catch {
      toast.error('Something went wrong, the data source cannot be deleted');
    } finally {
      setDataSourceToDelete(null);
    }
  };

  // If fetching data sources - show loader
  if (isFetching) {
    return <Loader />;
  }

  // If there is no current user or no data sources, render a message indicating no connected data sources.
  if (!currentUser || _.isEmpty(dataSources)) {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>
                <Typography variant="h4">No connected data sources</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>Label</TableCell>
              <TableCell sx={{ pl: 3 }}>Data Source Name</TableCell>
              <TableCell sx={{ pl: 3 }}>Category</TableCell>
              <TableCell sx={{ pl: 3 }}>Status</TableCell>
              <TableCell sx={{ pl: 3 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSources?.map((dataSource, index) => (
              <DataSourceListItem
                onDeleteDataSource={(dataSource) => setDataSourceToDelete(dataSource)}
                key={index}
                dataSource={dataSource}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        open={!!dataSourceToDelete}
        text={`Are you sure to delete the data source?`}
        onClose={() => {
          setDataSourceToDelete(null);
        }}
        onConfirm={onDeleteDataSource}
      />
    </>
  );
};

export default DataSourceList;
