import { PropsWithChildren } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CURRENT_USER_QUERY_KEY, useCurrentUserQuery } from '@/modules/auth/queries';
import { queryClient } from '@/api/queryClient.ts';
import { User } from '@/modules/users/types';

import { ROUTES } from '@shared/constants.ts';
import HomePage from '@pages/index.tsx';
import SettingsPage from '@pages/settings.tsx';
import LoginPage from '@/pages/auth/login.tsx';
import UsersPage from '@/pages/users/list.tsx';
import UserCreatePage from '@pages/users/create.tsx';
import UserPage from '@/pages/users/index.tsx';
import UserEditPage from '@pages/users/edit.tsx';
import DataSourcesListPage from '@/pages/datasources/list.tsx';
import DataSourceConnectPage from '@/pages/datasources/create.tsx';
import { ChooseDataSourcePage } from '@/pages/datasources/edit.tsx';
import { DataSourcePage } from './pages/datasources/index.tsx';

interface ProtectedRouteProperties {}

/**
 * A higher-order component that protects a route by checking if the user is authenticated and has the required permissions.
 */
export const ProtectedRoute = ({ children }: PropsWithChildren<ProtectedRouteProperties>) => {
  const currentUser = queryClient.getQueryData<User>([CURRENT_USER_QUERY_KEY]);
  const userToken = localStorage.getItem('token');

  /**
   * Check if the user is authenticated.
   */
  if (!userToken) {
    /**
     * If not authenticated, redirect to the login page.
     */
    localStorage.clear();
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  if (!currentUser) {
    useCurrentUserQuery(true);
  }

  /**
   * If authenticated, render the child routes.
   */
  return children;
};

/**
 * Defines the routes for the application.
 */
export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.SETTINGS}
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.USERS.LIST}
        element={
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.USERS.USER}
        element={
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.USERS.CREATE}
        element={
          <ProtectedRoute>
            <UserCreatePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.USERS.EDIT}
        element={
          <ProtectedRoute>
            <UserEditPage />
          </ProtectedRoute>
        }
      />
      <Route path={ROUTES.AUTH.LOGIN} element={<LoginPage />} />
      <Route
        path={ROUTES.DATA_SOURCES.LIST}
        element={
          <ProtectedRoute>
            <DataSourcesListPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.DATA_SOURCES.TYPE}
        element={
          <ProtectedRoute>
            <ChooseDataSourcePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.DATA_SOURCES.CONNECT}
        element={
          <ProtectedRoute>
            <DataSourceConnectPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.DATA_SOURCES.DATA_SOURCE}
        element={
          <ProtectedRoute>
            <DataSourcePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.DATA_SOURCES.EDIT}
        element={
          <ProtectedRoute>
            <DataSourcePage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
    </Routes>
  );
};
