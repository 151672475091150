import { FC } from 'react';
import { IconButton, IconButtonProps, styled, Tooltip } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';

interface IconButtonStyledProps extends IconButtonProps {
  clicked?: boolean;
}

/**
 * Styled IconButton component.
 */
const IconButtonStyled = styled(({ clicked, ...other }: IconButtonStyledProps) => <IconButton {...other} />)(
  ({ theme, clicked, color }) => ({
    color: color ?? theme.palette.grey[500],
    transition: theme.transitions.create(['transform', 'color'], {
      duration: theme.transitions.duration.standard,
    }),
    transform: 'rotate(0deg)',
    '&:hover': {
      transform: 'rotate(45deg)', // Additional rotation on hover
      color: theme.palette.grey[600],
    },
    '@keyframes pin': {
      '0%': {
        transform: 'translate(0) rotate(45deg)',
      },
      '25%': {
        transform: 'translate(10%, -10%) rotate(45deg)',
      },
      '50%': {
        transform: 'translate(-10%, 10%) rotate(45deg)',
      },
      '100%': {
        transform: 'translate(0) rotate(45deg)',
      },
    },
    animation: `${clicked && 'pin .5s'}`,
  })
);

interface PinButtonProps extends Partial<IconButtonStyledProps> {
  isOwner: boolean;
  isIconButtonClicked: boolean;
  handleChartSave: () => void;
}

export const PinButton: FC<PinButtonProps> = ({ isOwner, isIconButtonClicked, handleChartSave, ...props }) => {
  return (
    <IconButtonStyled data-testid="pinButton" {...props} disabled={!isOwner} clicked={isIconButtonClicked} onClick={handleChartSave}>
      <Tooltip title="Pin chart to the workspace">
        <PushPinIcon />
      </Tooltip>
    </IconButtonStyled>
  );
};
