import { useEffect, useState } from 'react';
import { Skeleton, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { DataSourceCategoryEnum } from '@modules/datasources/types.ts';

/**
 * Component for rendering a categories filter.
 *
 * @component
 * @returns The CategoriesFilter component.
 */
export const CategoriesFilter = () => {
  const [isCategoriesLoading, setCategoriesLoading] = useState(true);

  useEffect(() => {
    setCategoriesLoading(false);
  }, []);

  const categories = [
    'all',
    DataSourceCategoryEnum.WAREHOUSE,
    DataSourceCategoryEnum.DATABASE,
    DataSourceCategoryEnum.FLAT_FILE,
  ];

  /**
   * Handles the filter logic.
   * @param key - The key of the filter.
   * @param value - The value of the filter.
   */
  const handleFilter = (key: string, value: string) => {
    // TODO: Implement filter logic
    // eslint-disable-next-line no-console
    console.log(key, value);
  };

  return (
    <Grid container spacing={1}>
      {isCategoriesLoading ? (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={96} />
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={categories.some((item) => item === 'all')} />}
              onChange={() => handleFilter('categories', 'all')}
              label="All"
            />
            <FormControlLabel
              control={<Checkbox checked={categories.some((item) => item === DataSourceCategoryEnum.WAREHOUSE)} />}
              onChange={() => handleFilter('categories', DataSourceCategoryEnum.WAREHOUSE)}
              label={DataSourceCategoryEnum.WAREHOUSE}
            />
            <FormControlLabel
              control={<Checkbox checked={categories.some((item) => item === DataSourceCategoryEnum.DATABASE)} />}
              onChange={() => handleFilter('categories', DataSourceCategoryEnum.DATABASE)}
              label={DataSourceCategoryEnum.DATABASE}
            />
            <FormControlLabel
              control={<Checkbox checked={categories.some((item) => item === DataSourceCategoryEnum.FLAT_FILE)} />}
              onChange={() => handleFilter('categories', DataSourceCategoryEnum.FLAT_FILE)}
              label={DataSourceCategoryEnum.FLAT_FILE}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
