import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@shared/constants';

import { Img } from './Img';

import Logo from '@assets/images/logo.png';
// Shortened main logo

/**
 * Represents a main logo component.
 *
 * @component
 * @returns {React.ReactNode} The main logo component.
 */
const MainLogo = (): React.ReactNode => {
  const navigate = useNavigate();

  return (
    <Img
      width={200}
      src={Logo}
      alt="logo"
      pr={2}
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(ROUTES.DASHBOARD)}
    />
  );
};

export default MainLogo;
