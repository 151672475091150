/**
 * Data sources types
 */

export type DatasourceType = 'Google BigQuery';

export interface DataSource {
  created_at: string;
  updated_at: string;
  id: number;
  label: string;
  type: DatasourceType;
  category: DataSourceCategoryEnum;
  status: DataSourceStatusEnum;
  config: DataSourceConfig;
  tenant_id: number;
  user_id: number;
}

/**
 * DataSource config Type
 */
export type DataSourceBigQueryConfig = {
  project_id: string;
  dataset_id: string;
};

export type DataSourceConfig = DataSourceBigQueryConfig;

export interface Connector {
  created_at: string;
  updated_at: string;
  id: number;
  config: ConnectorConfig;
}

/**
 * Connector response types
 * @extends Connector
 */
export interface UpdateConnectorResponse extends Connector {}

/**
 * Data sources response types
 * @extends DataSource
 */
export interface GetDataSourcesResponse extends DataSource {}

/**
 * Data sources details response types
 * @extends DataSource
 */
export interface GetDataSourcesDetailsResponse extends DataSource {
  connector: Connector;
  projects: Project[];
}

/**
 * Data sources create request types
 */
export interface Project {
  id: string;
  datasets: Dataset[];
}

/**
 * Data sources create request types
 */
export interface Dataset {
  id: string;
}

export interface GetDatasetsResponse extends Dataset {}

/**
 * Data sources create request types
 */
export interface CreateDataSourceResponse {
  id: number;
  projects: Project[];
}

/**
 * Connected data source config types
 */
export interface ConnectorBigQueryConfig {
  email: string;
  image: string;
}

export type ConnectorConfig = ConnectorBigQueryConfig;

/**
 * Data sources finalize request types
 */
export interface FinalizeDataSourceRequest {
  datasource_type: DatasourceType;
  config: DataSourceConfig;
  label: string;
  id: number;
}

/**
 * Data sources create request types
 */
export interface CreateDataSourceRequest {
  type: DatasourceType;
  connector_id?: number;
  code?: string;
}

/**
 * Connector update request types
 */
export interface UpdateConnectorRequest {
  code: string;
}

/**
 * Type to filter connectors
 */
export type ConnectorsFilters = {
  type?: string;
};

/**
 * Data sources finalize response types
 */
export enum DataSourceCategoryEnum {
  WAREHOUSE = 'Data Warehouse',
  DATABASE = 'Database',
  FLAT_FILE = 'Flat File',
}

/**
 * Data sources status types
 */
export enum DataSourceStatusEnum {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
  PENDING = 'Pending',
}
