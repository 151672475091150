import { FC } from 'react';
import { SwatchesPicker } from '@shared/components/SwatchesPicker';
import { RgbaColor } from 'react-colorful';
import '@shared/components/styles/ColorPalette.css';

interface ColorPaletteProps {
  onChange: (color: RgbaColor) => void;
}

export const ColorPalette: FC<ColorPaletteProps> = ({ onChange }) => {
  const presetColors = [
    { r: 0, g: 0, b: 0, a: 1 },
    { r: 0, g: 0, b: 0, a: 0.8 },
    { r: 0, g: 0, b: 0, a: 0.6 },
    { r: 0, g: 0, b: 0, a: 0.4 },
    { r: 0, g: 0, b: 0, a: 0.3 },
    { r: 0, g: 0, b: 0, a: 0.2 },
    { r: 0, g: 0, b: 0, a: 0.1 },
    { r: 255, g: 255, b: 255, a: 1 },
    // Primary colors
    { r: 211, g: 47, b: 47, a: 1 },
    { r: 255, g: 87, b: 34, a: 1 },
    { r: 255, g: 235, b: 59, a: 1 },
    { r: 205, g: 220, b: 57, a: 1 },
    { r: 76, g: 175, b: 80, a: 1 },
    { r: 0, g: 188, b: 212, a: 1 },
    { r: 63, g: 81, b: 181, a: 1 },
    { r: 156, g: 39, b: 176, a: 1 },
    // Primary colors with opacity 0.2
    { r: 211, g: 47, b: 47, a: 0.2 },
    { r: 255, g: 87, b: 34, a: 0.2 },
    { r: 255, g: 235, b: 59, a: 0.2 },
    { r: 205, g: 220, b: 57, a: 0.2 },
    { r: 76, g: 175, b: 80, a: 0.2 },
    { r: 0, g: 188, b: 212, a: 0.2 },
    { r: 63, g: 81, b: 181, a: 0.2 },
    { r: 156, g: 39, b: 176, a: 0.2 },
    // Primary colors with opacity 0.4
    { r: 211, g: 47, b: 47, a: 0.4 },
    { r: 255, g: 87, b: 34, a: 0.4 },
    { r: 255, g: 235, b: 59, a: 0.4 },
    { r: 205, g: 220, b: 57, a: 0.4 },
    { r: 76, g: 175, b: 80, a: 0.4 },
    { r: 0, g: 188, b: 212, a: 0.4 },
    { r: 63, g: 81, b: 181, a: 0.4 },
    { r: 156, g: 39, b: 176, a: 0.4 },
    // Primary colors with opacity 0.6
    { r: 211, g: 47, b: 47, a: 0.6 },
    { r: 255, g: 87, b: 34, a: 0.6 },
    { r: 255, g: 235, b: 59, a: 0.6 },
    { r: 205, g: 220, b: 57, a: 0.6 },
    { r: 76, g: 175, b: 80, a: 0.6 },
    { r: 0, g: 188, b: 212, a: 0.6 },
    { r: 63, g: 81, b: 181, a: 0.6 },
    { r: 156, g: 39, b: 176, a: 0.6 },
    // Primary colors with opacity 0.8
    { r: 211, g: 47, b: 47, a: 0.8 },
    { r: 255, g: 87, b: 34, a: 0.8 },
    { r: 255, g: 235, b: 59, a: 0.8 },
    { r: 205, g: 220, b: 57, a: 0.8 },
    { r: 76, g: 175, b: 80, a: 0.8 },
    { r: 0, g: 188, b: 212, a: 0.8 },
    { r: 63, g: 81, b: 181, a: 0.8 },
    { r: 156, g: 39, b: 176, a: 0.8 },
  ];

  return <SwatchesPicker onChange={onChange} presetColors={presetColors} />;
};
