import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout';
import UserView from '@/modules/users/components/UserView';
import { UserProfileActionsEnum } from '@/modules/users/types';

const CreatePage = () => {
  return (
    <AuthorizedPageLayout title={'Users'}>
      <UserView title={'User creation page'} mode={UserProfileActionsEnum.CREATE} />
    </AuthorizedPageLayout>
  );
};

export default CreatePage;
