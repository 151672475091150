import { memo, useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Drawer, Stack, useMediaQuery, IconButton, Tooltip, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import MiniDrawerStyled from './MiniDrawerStyled';
import Chip from '@themes/components/extended/Chip';

import useConfig from '@themes/hooks/useConfig';
import { drawerWidth } from '@themes/store/constant';

import { DEFAULT_WORKSPACE_NAME } from '@/modules/workspaces/constants';
import { useCreateWorkspaceMutation } from '@/modules/workspaces/queries';

import { handlerDrawerOpen, useGetMenuMaster } from '@themes/api/menu';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';

// icons
import AddIcon from '@mui/icons-material/Add';

// types
import { MenuOrientation } from '@themes/types/config';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const hasAcess = usePowerUserAccess();
  const { mutateAsync: createWorkspaceAsync, isPending: isWorkspaceCreationPending } = useCreateWorkspaceMutation();

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;

  const { menuOrientation, miniDrawer } = useConfig();

  const drawer = useMemo(() => {
    const isVerticalOpen = menuOrientation === MenuOrientation.VERTICAL && drawerOpen;
    const drawerContent = (
      <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
        <Chip
          label={import.meta.env.VITE_APP_VERSION}
          disabled
          chipcolor="secondary"
          size="small"
          sx={{ cursor: 'pointer' }}
        />
      </Stack>
    );

    const drawerSX = { paddingLeft: '6px', paddingRight: '0px', marginTop: '90px' };

    return (
      <>
        {downMD ? (
          <Box sx={drawerSX}>
            <MenuList />
            {isVerticalOpen && drawerContent}
          </Box>
        ) : (
          <PerfectScrollbar style={{ height: 'calc(100vh - 88px)', ...drawerSX }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: drawerOpen ? 'space-between' : 'center',
                alignItems: 'center',
                pl: 3,
                mb: 3,
                ...(drawerOpen ? { pr: '6px' } : { pr: 3 }),
              }}
            >
              {hasAcess && drawerOpen && <Typography variant="h4">Add Workspace</Typography>}
              <Tooltip title="Add Workspace" placement="right" sx={{ position: 'relative', right: 0 }}>
                <IconButton
                  disabled={isWorkspaceCreationPending}
                  sx={{
                    color: 'text.primary',
                    bgcolor: 'background.paper',
                    boxShadow: 4,
                  }}
                  size="medium"
                  onClick={() => createWorkspaceAsync({ name: DEFAULT_WORKSPACE_NAME })}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
            <MenuList />
            {isVerticalOpen && drawerContent}
          </PerfectScrollbar>
        )}
      </>
    );
  }, [downMD, drawerOpen, menuOrientation]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: { xs: 'auto', md: drawerWidth } }}
      aria-label="mailbox folders"
    >
      {downMD || (miniDrawer && drawerOpen) ? (
        <Drawer
          variant={downMD ? 'temporary' : 'persistent'}
          anchor="left"
          open={drawerOpen}
          onClose={() => handlerDrawerOpen(!drawerOpen)}
          sx={{
            '& .MuiDrawer-paper': {
              mt: downMD ? 0 : 11,
              zIndex: 1099,
              width: drawerWidth,
              bgcolor: 'background.default',
              color: 'text.primary',
              borderRight: 'none',
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default memo(Sidebar);
