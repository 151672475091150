import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useAdminAccess } from '@/modules/auth/hooks/useAdminAccess';

import {
  Grid,
  Button,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  Stack,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

// assets
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaceIcon from '@mui/icons-material/Place';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { ROUTES } from '@shared/constants.ts';
import AnimateButton from '@themes/components/extended/AnimateButton';
import { useCreateUserMutation } from '@/modules/users/queries';
import { UserCreateRequest, UserTypesEnum } from '@/modules/users/types.ts';
import { TIMEZONES, MENUPROPS } from '@/modules/users/constants.ts';

const validationSchema = Yup.object().shape({
  name: Yup.string().max(255).required('Name is required'),
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string().max(255).required('Password is required'),
  permissions: Yup.array().of(Yup.string().required('Permission is required')).min(1, 'Permission is required'),
  role: Yup.string().max(255).required('Role is required'),
  timezone: Yup.string().max(255).required('Timezone is required'),
});

/**
 * Represents a create user form component.
 *
 * @component
 * @returns {React.ReactNode} The create user form component.
 */
const CreateUserForm = (): React.ReactNode => {
  const navigate = useNavigate();
  const [selectedPermission, setSelectedPermission] = useState<string>('');
  const hasAccess = useAdminAccess();

  // change password input type
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const userInitialValues: UserCreateRequest = {
    name: '',
    email: '',
    password: '',
    permissions: hasAccess ? [] : [UserTypesEnum.USER],
    role: '',
    timezone: TIMEZONES[13],
  };

  const { errors, touched, values, isSubmitting, handleSubmit, setErrors, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues: { ...userInitialValues },
      validationSchema,
      onSubmit: async (values: UserCreateRequest) => {
        createUser(values as UserCreateRequest);
      },
    });

  const { mutate: createUser } = useCreateUserMutation({ setErrors });

  const handlePermissionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: typeof Formik.prototype.setFieldValue
  ) => {
    switch (event.target.value) {
      case UserTypesEnum.ADMIN:
        setFieldValue('permissions', [UserTypesEnum.USER, UserTypesEnum.POWER_USER, UserTypesEnum.ADMIN]);
        break;
      case UserTypesEnum.POWER_USER:
        setFieldValue('permissions', [UserTypesEnum.USER, UserTypesEnum.POWER_USER]);
        break;
      case UserTypesEnum.USER:
        setFieldValue('permissions', [UserTypesEnum.USER]);
        break;
      default:
        setFieldValue('permissions', []);
    }
    setSelectedPermission(event.target.value as string);
  };

  return (
    <>
      <Grid item xs={12}>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
            <TextField
              id="full-name"
              label="Full Name"
              type="text"
              value={values.name}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            {touched.name && errors.name && (
              <FormHelperText error id="standard-weight-helper-text-name">
                {errors.name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ mt: 2, cursor: 'pointer' }}>
            <TextField
              id="email"
              type="email"
              value={values.email}
              name="email"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            {touched.email && errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email">
                {errors.email}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ mt: 2 }}>
            <TextField
              id="password"
              type={isPasswordVisible ? 'text' : 'password'}
              value={values.password}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      aria-label="toggle password visibility"
                      edge="end"
                      size="small"
                    >
                      {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Password"
            />
            {touched.password && errors.password && (
              <FormHelperText error id="standard-weight-helper-text-password">
                {errors.password}
              </FormHelperText>
            )}
          </FormControl>
          {hasAccess && (
            <FormControl fullWidth error={Boolean(touched.permissions && errors.permissions)} sx={{ mt: 2 }}>
              <InputLabel id="permissions-label" htmlFor={'permissions'}>
                Permission
              </InputLabel>
              <Select
                labelId="permissions-label"
                id="permissions"
                value={selectedPermission}
                label="Permission"
                name="permissions"
                onBlur={handleBlur}
                onChange={(event) => handlePermissionChange(event as ChangeEvent<HTMLInputElement>, setFieldValue)}
                startAdornment={<ManageAccountsIcon />}
              >
                <MenuItem value={'Admin'}>Admin</MenuItem>
                <MenuItem value={'Power User'}>Power User</MenuItem>
                <MenuItem value={'User'}>User</MenuItem>
              </Select>
              {touched.permissions && errors.permissions && (
                <FormHelperText error id="standard-weight-helper-text-role">
                  {errors.permissions}
                </FormHelperText>
              )}
            </FormControl>
          )}

          <FormControl fullWidth error={Boolean(touched.role && errors.role)} sx={{ mt: 2 }}>
            <InputLabel htmlFor="role" id="role-label">
              Role
            </InputLabel>
            <Select
              labelId="role-label"
              id="role"
              value={values.role}
              label="Role"
              name="role"
              onBlur={handleBlur}
              onChange={handleChange}
              startAdornment={<GroupsIcon />}
            >
              <MenuItem value={'CEO'}>CEO</MenuItem>
              <MenuItem value={'Developer'}>Developer</MenuItem>
              <MenuItem value={'Designer'}>Designer</MenuItem>
              <MenuItem value={'Manager'}>Manager</MenuItem>
            </Select>
            {touched.role && errors.role && (
              <FormHelperText error id="standard-weight-helper-text-role">
                {errors.role}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth error={Boolean(touched.timezone && errors.timezone)} sx={{ mt: 2 }}>
            <InputLabel htmlFor="timezone" id="timezone-label">
              Timezone
            </InputLabel>
            <Select
              labelId="timezone-label"
              id="timezone"
              name="timezone"
              value={values.timezone}
              onChange={handleChange}
              label="Timezone"
              MenuProps={MENUPROPS}
              startAdornment={<PlaceIcon />}
            >
              {TIMEZONES.map((timezone) => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2}>
              <AnimateButton>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  onSubmit={(e: any) => handleSubmit(e)}
                >
                  {'Create'}
                </Button>
              </AnimateButton>
              <AnimateButton>
                <Button
                  disabled={isSubmitting}
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={() => navigate(ROUTES.USERS.LIST)}
                >
                  Cancel
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default CreateUserForm;
