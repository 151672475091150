import { FC } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import DownloadIcon from '@/assets/images/icons/download.png';

export const WorkspaceTableToolbar: FC = () => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <Tooltip title="Export as CSV">
        <IconButton onClick={() => apiRef.current.exportDataAsCsv()} color="primary">
          <img src={DownloadIcon} alt="download" className="ico-download" width="26" />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  );
};
