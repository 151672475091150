import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { TableColorScheme } from '@modules/query/types';
import { formatRgbaToString } from '@/shared/helpers/colorFormatters';

// Custom styled DataGrid component
export const DataGridStyled = styled(DataGrid, { shouldForwardProp: (prop) => prop !== 'colorScheme' })<{
  colorScheme: TableColorScheme;
}>(({ colorScheme }) => {
  return {
    '& .MuiDataGrid-columnHeaders, & .xanalytica-theme--header': {
      backgroundColor: formatRgbaToString(colorScheme.header),
      color: '#333',
      fontSize: '0.80rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      width: '100%',
      transition: 'width ease 0.3s',

      '&:hover': {
        width: 'max-content !important',
        maxWidth: 'max-content !important',
      },
    },
    '& .MuiDataGrid-columnHeader': {
      padding: '8px 14px',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: `1px solid ${formatRgbaToString(colorScheme.header)}`,
      color: '#333',
      fontSize: '0.75rem',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: formatRgbaToString(colorScheme.primaryRow), // Light background for alternate rows
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: formatRgbaToString(colorScheme.secondaryRow), // Light background for alternate rows
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: formatRgbaToString(colorScheme.header, 0.5),
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: '#fff',
      color: '#333',
      padding: 'unset',
      minHeight: 'unset',
    },
    '& .MuiTablePagination-root': {
      overflow: 'hidden',
    },
    '& .MuiTablePagination-toolbar': {
      padding: 'unset',
    },
    '& .MuiTablePagination-actions': {
      padding: '0 8px', // Padding around pagination controls
    },
    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { padding: '8px 14px' },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { padding: '10px 14px' },
    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { padding: '12px 14px' },
  };
});
