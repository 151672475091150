import { RgbaColor } from 'react-colorful';
import { ApexOptions } from 'apexcharts';
import { CellFormat } from '@/shared/helpers/formatCellValue.ts';

export enum SingleTypes {
  string = 'string',
  number = 'number',
}

export enum ModelTypesEnum {
  OPENAI = 'openai',
  CLAUDE = 'claude',
  LLAMA = 'llama',
}

export interface ExecuteQueryRequest {
  query: string;
  model: ModelTypesEnum;
}

/**
 * Visualization Types. The same types provided to the AI models to format the response.
 * backend/app/core/services/openai_service.py
 */
export enum VisualizationTypes {
  NUMBER = 'number',
  STRING = 'string',
  CHART = 'chart',
  TABLE = 'table',
  DATE = 'date',
}

export type ChartType =
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  // | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap';

export type ChartVisualizationType = {
  type: ChartType;
  categoryKey: string;
  seriesKeys: string[];
};

export type SingleValueVisualizationType = {
  type: VisualizationTypes.NUMBER | VisualizationTypes.STRING | VisualizationTypes.DATE;
  value: Record<string, string | number | null>[];
  // TODO: add more correct types
  single_result_format?: CellFormat | null;
};

export type VisualizationValue = ChartVisualizationType | SingleValueVisualizationType;

export interface Visualization {
  type: VisualizationTypes;
  value: VisualizationValue;
  single_result_format?: CellFormat;
}

export interface StoredChartVisualization {
  chart: ApexOptions['chart'];
  series: ApexOptions['series'];
  categories: ApexXAxis['categories'];
  yAxisColumnName?: string;
  xAxisColumnName?: string;
}

export type StoredTableVisualization = QueryRawData;

export type StoredSingleValueVisualization = string | number;

export type StoredVisualization = StoredChartVisualization | StoredTableVisualization | StoredSingleValueVisualization;

export type QueryRawDataItem = Record<string, string | number | undefined | null>;
export type QueryRawData = QueryRawDataItem[];

export interface ExecuteQueryResponse {
  query: string;
  visualization: Visualization;
  raw_data: QueryRawData;
}

/**
 * Color scheme for the table.
 */
export interface TableColorScheme {
  header: RgbaColor;
  primaryRow: RgbaColor;
  secondaryRow: RgbaColor;
}

export enum ChartPalette {
  Palette1 = 'palette1',
  Palette2 = 'palette2',
  Palette3 = 'palette3',
  Palette4 = 'palette4',
  Palette5 = 'palette5',
  Palette6 = 'palette6',
  Palette7 = 'palette7',
  Palette8 = 'palette8',
  Palette9 = 'palette9',
  Palette10 = 'palette10',
}

/**
 * Color scheme for the chart.
 */
export interface ChartColorScheme {
  palette?: ChartPalette;
}

export type ColorScheme = TableColorScheme | ChartColorScheme;
