import { DataSource, DataSourceStatusEnum } from '@modules/datasources/types.ts';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';
import { useNavigate, generatePath } from 'react-router-dom';
import { ROUTES } from '@shared/constants.ts';

// material-ui
import { useTheme } from '@mui/material/styles';
import { TableCell, TableRow, Chip, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';

export interface DataSourceListItemProps {
  dataSource: DataSource;
  onDeleteDataSource: (dataSource: DataSource) => void;
}
export const DataSourceListItem = ({ dataSource, onDeleteDataSource }: DataSourceListItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hasAccess = usePowerUserAccess();

  const dataSourceUri = generatePath(ROUTES.DATA_SOURCES.DATA_SOURCE, { id: dataSource.id });
  const dataSourceEditUri = generatePath(ROUTES.DATA_SOURCES.EDIT, { id: dataSource.id });

  let statusColor;

  // Set the status color based on the data source's status.
  switch (dataSource.status) {
    case DataSourceStatusEnum.ACTIVE:
      statusColor = theme.palette.success;
      break;
    case DataSourceStatusEnum.DISABLED:
      statusColor = theme.palette.error;
      break;
    default:
      statusColor = theme.palette.warning;
      break;
  }

  return (
    <TableRow onClick={() => navigate(dataSourceUri, { state: { viewMode: true } })} sx={{ cursor: 'pointer' }} hover>
      <TableCell sx={{ pl: 3 }}>{dataSource.label}</TableCell>
      <TableCell sx={{ pl: 3 }}>{dataSource.type}</TableCell>
      <TableCell sx={{ pl: 3 }}>{dataSource.category}</TableCell>
      <TableCell sx={{ pl: 3 }}>
        <Chip
          label={dataSource.status}
          size="small"
          sx={{
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : statusColor.light + 60,
            color: statusColor.dark,
          }}
        />
      </TableCell>
      <TableCell>
        <Tooltip placement="top" title="Edit">
          <IconButton
            disabled={!hasAccess}
            color="primary"
            aria-label="edit"
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              navigate(dataSourceEditUri, { state: { viewMode: false } });
            }}
          >
            <EditIcon sx={{ fontSize: '1.1rem' }} />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <IconButton
            disabled={!hasAccess}
            color="primary"
            sx={{
              color: theme.palette.orange.dark,
              borderColor: theme.palette.orange.main,
              '&:hover ': { background: theme.palette.orange.light },
            }}
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              onDeleteDataSource(dataSource);
            }}
          >
            <BlockTwoToneIcon sx={{ fontSize: '1.1rem' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
