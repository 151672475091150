import React, { useMemo, useState, ChangeEvent, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  Button,
  InputLabel,
  FormHelperText,
  InputAdornment,
  FormControl,
  Stack,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import Loader from '@/themes/components/Loader';

// assets
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaceIcon from '@mui/icons-material/Place';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import AnimateButton from '@themes/components/extended/AnimateButton';
import { useUpdateUserMutation, useUserQuery } from '@/modules/users/queries.ts';
import { UserUpdateRequest, UserTypesEnum, ACL } from '@/modules/users/types.ts';
import { TIMEZONES, MENUPROPS } from '@/modules/users/constants.ts';
import { useAdminAccess } from '@/modules/auth/hooks/useAdminAccess';
import { useCurrentUser } from '@modules/auth/hooks/useCurrentUser.ts';

const validationSchema = Yup.object().shape({
  name: Yup.string().max(255),
  email: Yup.string().email('Must be a valid email').max(255),
  permissions: Yup.array().of(Yup.string()),
  role: Yup.string().max(255),
  timezone: Yup.string().max(255),
});

/**
 * Extracts the user permissions in order to set Formik's initial values.
 *
 * @param {React.Dispatch<SetStateAction<string>>} setSelectedPermission - The selected permission state.
 * @param {ACL[]} acls - The ACLs.
 * @returns {UserTypesEnum[]} The extracted permissions.
 */
const extractPermissions = (
  setSelectedPermission: React.Dispatch<SetStateAction<string>>,
  acls?: ACL[]
): UserTypesEnum[] => {
  if (!acls) {
    return [];
  }
  if (acls.find((acl) => acl.permission.name === UserTypesEnum.ADMIN)) {
    setSelectedPermission(UserTypesEnum.ADMIN);
    return [UserTypesEnum.USER, UserTypesEnum.POWER_USER, UserTypesEnum.ADMIN];
  }
  if (acls.find((acl) => acl.permission.name === UserTypesEnum.POWER_USER)) {
    setSelectedPermission(UserTypesEnum.POWER_USER);
    return [UserTypesEnum.USER, UserTypesEnum.POWER_USER];
  }
  setSelectedPermission(UserTypesEnum.USER);
  return [UserTypesEnum.USER];
};

/**
 * Represents a create user form component.
 *
 * @component
 * @returns {React.ReactNode} The create user form component.
 */
const EditUserForm = ({ viewOnly }: { viewOnly?: boolean }): React.ReactNode => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [selectedPermission, setSelectedPermission] = useState<string>('');
  const { id: userId } = useParams();
  const { mutate: updateUser } = useUpdateUserMutation();
  const { data: user, isLoading: isUserLoading } = useUserQuery(userId ?? '');
  const isAdmin = useAdminAccess();
  const hasAccess = useMemo(() => isAdmin && userId !== String(currentUser?.id), [userId, currentUser, isAdmin]);

  /**
   * Handles the submit action for edit user form.
   *
   * @async
   * @param {UserCreateRequest} values - The user creation values.
   */
  const handleSubmit = async (values: UserUpdateRequest) => {
    if (!user) {
      return;
    }
    try {
      await updateUser({ userId: user.id, payload: values });
      navigate(-1);
    } catch (error) {
      console.error('error', error);
    }
    return;
  };

  const userInitialValues: UserUpdateRequest = useMemo(
    () => ({
      name: user?.name ?? '',
      email: user?.email ?? '',
      permissions: extractPermissions(setSelectedPermission, user?.acls),
      role: user?.role ?? '',
      timezone: user?.timezone ?? '',
    }),
    [user]
  );

  if (isUserLoading) {
    return <Loader />;
  }

  const handlePermissionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: typeof Formik.prototype.setFieldValue
  ) => {
    switch (event.target.value) {
      case UserTypesEnum.ADMIN:
        setFieldValue('permissions', [UserTypesEnum.USER, UserTypesEnum.POWER_USER, UserTypesEnum.ADMIN]);
        break;
      case UserTypesEnum.POWER_USER:
        setFieldValue('permissions', [UserTypesEnum.USER, UserTypesEnum.POWER_USER]);
        break;
      case UserTypesEnum.USER:
        setFieldValue('permissions', [UserTypesEnum.USER]);
        break;
      default:
        setFieldValue('permissions', []);
    }
    setSelectedPermission(event.target.value as string);
  };

  return (
    <>
      <Grid item xs={12}>
        <Formik
          initialValues={{ ...userInitialValues }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit} aria-disabled={viewOnly}>
              <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                <TextField
                  disabled={viewOnly}
                  id="full-name"
                  label="Full Name"
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {touched.name && errors.name && (
                  <FormHelperText error id="standard-weight-helper-text-name">
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ mt: 2, cursor: 'pointer' }}>
                <TextField
                  disabled
                  id="email"
                  type="email"
                  value={values.email}
                  name="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ cursor: 'not-allowed' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-email">
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={Boolean(touched.permissions && errors.permissions)} sx={{ mt: 2 }}>
                <InputLabel htmlFor="permissions" id={'permissions-label'}>
                  Permission
                </InputLabel>
                <Select
                  disabled={!hasAccess || viewOnly}
                  labelId="permissions-label"
                  id="permissions"
                  value={selectedPermission}
                  label="Permission"
                  name="permissions"
                  onBlur={handleBlur}
                  onChange={(event) => handlePermissionChange(event as ChangeEvent<HTMLInputElement>, setFieldValue)}
                  startAdornment={<ManageAccountsIcon />}
                >
                  <MenuItem value={'Admin'}>Admin</MenuItem>
                  <MenuItem value={'Power User'}>Power User</MenuItem>
                  <MenuItem value={'User'}>User</MenuItem>
                </Select>
                {touched.role && errors.role && (
                  <FormHelperText error id="standard-weight-helper-text-role">
                    {errors.role}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={Boolean(touched.role && errors.role)} sx={{ mt: 2 }}>
                <InputLabel htmlFor="role" id={'role-label'}>
                  Role
                </InputLabel>
                <Select
                  disabled
                  labelId="role-label"
                  id="role"
                  value={values.role}
                  label="Role"
                  name="role"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={<GroupsIcon />}
                  sx={{ cursor: 'not-allowed' }}
                >
                  <MenuItem value={'CEO'}>CEO</MenuItem>
                  <MenuItem value={'Developer'}>Developer</MenuItem>
                  <MenuItem value={'Designer'}>Designer</MenuItem>
                  <MenuItem value={'Manager'}>Manager</MenuItem>
                </Select>
                {touched.role && errors.role && (
                  <FormHelperText error id="standard-weight-helper-text-role">
                    {errors.role}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={Boolean(touched.timezone && errors.timezone)} sx={{ mt: 2 }}>
                <InputLabel id="timezone-label">Timezone</InputLabel>
                <Select
                  disabled={viewOnly}
                  labelId="timezone-label"
                  id="timezone"
                  name="timezone"
                  value={values.timezone}
                  onChange={handleChange}
                  label="Timezone"
                  MenuProps={MENUPROPS}
                  startAdornment={<PlaceIcon />}
                >
                  {TIMEZONES.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!viewOnly && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Stack direction="row" spacing={2}>
                    <AnimateButton>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        onSubmit={(e: any) => handleSubmit(e)}
                        color={'success'}
                        sx={{ color: 'white' }}
                      >
                        {'Save'}
                      </Button>
                    </AnimateButton>
                    <AnimateButton>
                      <Button
                        disabled={isSubmitting}
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </AnimateButton>
                  </Stack>
                </Grid>
              )}
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default EditUserForm;
