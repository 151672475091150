import React from 'react';
import { Button, Box, Paper, Typography } from '@mui/material';

import AnimateButton from '@themes/components/extended/AnimateButton.tsx';

import google from '@assets/images/icons/google.svg';

export const GoogleIcon = ({ height = 16 }: { height?: number }) => {
  return <Box component="img" src={google} alt={'google'} sx={{ height, width: 'auto' }} />;
};

interface GoogleLoginProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Represents a google login button component.
 *
 * @component
 * @returns {React.ReactNode} The google login button component.
 */
export const GoogleOAuth = ({ onClick }: GoogleLoginProps): React.ReactNode => {
  return (
    <Paper elevation={6} sx={{ width: '400px' }}>
      <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h3">Connect to Google BigQuery</Typography>
        <Typography sx={{ marginTop: '12px' }}>
          In order to connect to Google BigQuery, you need to sign in to Google and authorize access.
        </Typography>
        <Typography sx={{ marginTop: '12px' }}>
          This will allow xAnalytica to view your data across Google services and to view and manage your Google
          BigQuery data.
        </Typography>
        <AnimateButton>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            startIcon={<GoogleIcon />}
            style={{ border: '1px solid #dadce0', color: '#3c4043', marginTop: '12px' }}
            onClick={onClick}
          >
            Sign in with Google
          </Button>
        </AnimateButton>
      </Box>
    </Paper>
  );
};
