import { Card, CardContent, Typography } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import { FC } from 'react';

interface WorkspaceItemCardProps {
  /**
   * The query which is displayed in the card header
   */
  query?: string;
  /**
   * Visualization represented of the workspace item generated from the query
   */
  children?: React.ReactNode;
}

/**
 * Displays the content of a workspace item in a card.
 * Takes a query which is displayed in the card header and children which is the content of the card.
 */
export const WorkspaceItemCard: FC<WorkspaceItemCardProps> = ({ query, children }) => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 0.5 }}>
      {query && (
        <CardHeader
          title={<Typography sx={{ fontSize: 14, color: 'text.secondary' }}>{query}</Typography>}
          sx={{
            padding: 0,
            paddingBottom: 0,
          }}
        />
      )}
      <CardContent
        sx={{
          flexGrow: 1,
          padding: 0,
          overflow: 'hidden', // Prevent scrollbars from appearing
          '&:last-child': { paddingBottom: 0 },
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
