// Helper, that returns the font size for the query text based on the length of the query text and max available width
export const fontSizeHelper = (text: string, maxWidth: number) => {
  const baseFontSize = 32; // Base size in pixels
  const minFontSize = 16; // Minimum font size
  const maxFontSize = 64; // Maximum font size

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${baseFontSize}px sans-serif`;
    const textWidth = context.measureText(text).width;

    // Dynamically calculate font size based on maxWidth
    const fontSize = Math.min(maxFontSize, Math.max(minFontSize, (maxWidth / textWidth) * baseFontSize));
    return `${fontSize}px`;
  }
  return `${minFontSize}px`;
};
