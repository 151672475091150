import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '@shared/constants';

// project imports
import { useDeleteWorkspaceMutation, useGetWorkspaceQuery } from '@/modules/workspaces/queries';
import { CURRENT_WORKSPACE_ID_KEY } from '@/modules/workspaces/constants';
import { useIsWorkspaceOwner } from '@/modules/workspaces/hooks/useIsWorkspaceOwner.ts';
import { useUserQuery } from '@/modules/users/queries';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

// project imports
import useConfig from '@themes/hooks/useConfig';
import { useGetMenuMaster } from '@themes/api/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';

// types
import { MenuOrientation, ThemeMode } from '@themes/types/config';
import { LinkTarget, NavItemType } from '@themes/types';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

interface NavItemProps {
  item: NavItemType;
  level: number;
  isParents?: boolean;
  setSelectedID?: () => void;
}

const NavItem = ({ item, level, isParents = false }: NavItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [workspaceId, setWorkspaceId] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);
  // TODO: Might break if other then workspaces would be added to nav
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetWorkspaceQuery(Number(item.id));
  const { data: user } = useUserQuery(workspace?.user_id ? String(workspace?.user_id) : '');
  const isOwner = useIsWorkspaceOwner(workspace);
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const ref = useRef<HTMLSpanElement>(null);
  const { mode, menuOrientation, borderRadius } = useConfig();

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downMD;
  const [isSelected, setIsSelected] = useState(workspaceId === Number(item.id));
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { mutateAsync: deleteWorkspaceAsync } = useDeleteWorkspaceMutation();

  useEffect(() => {
    if (workspaceId === Number(item.id)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [workspaceId, item.id]);

  const Icon = item?.icon!;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size={'24px'} style={{ ...(isHorizontal && isParents && { fontSize: 20, stroke: '1.5' }) }} />
  ) : (
    <FiberManualRecordIcon
      sx={{ width: isSelected ? 8 : 6, height: isSelected ? 8 : 6 }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const itemHandler = () => {
    if (item.id) {
      setWorkspaceId(Number(item.id));
      pathname !== ROUTES.DASHBOARD && navigate(ROUTES.DASHBOARD);
    }
  };

  const iconSelectedColor = mode === ThemeMode.DARK && drawerOpen ? 'text.primary' : 'secondary.main';

  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteWorkspace = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    await deleteWorkspaceAsync(Number(item.id));
    handleClose();
  };

  if (isWorkspaceLoading) {
    return null;
  }

  return (
    <>
      {!isHorizontal ? (
        <ListItemButton
          disabled={item.disabled}
          disableRipple={!drawerOpen}
          sx={{
            zIndex: 1201,
            borderRadius: `${borderRadius}px`,
            mb: 0,
            pl: 1.25,
            py: 0,
            '&:hover': {
              bgcolor: 'transparent',
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent',
              },
              bgcolor: 'transparent',
            },
            ...(drawerOpen &&
              level === 1 &&
              mode !== ThemeMode.DARK && {
                '&:hover': {
                  bgcolor: 'secondary.light',
                },
                '&.Mui-selected': {
                  bgcolor: 'secondary.light',
                  color: iconSelectedColor,
                  '&:hover': {
                    color: iconSelectedColor,
                    bgcolor: 'secondary.light',
                  },
                },
              }),
          }}
          selected={isSelected}
          onClick={() => itemHandler()}
        >
          <Tooltip title={item.title} placement="right" disableHoverListener={drawerOpen}>
            <ButtonBase aria-label="theme-icon" sx={{ borderRadius: `${borderRadius}px` }} disableRipple={drawerOpen}>
              <ListItemIcon
                sx={{
                  minWidth: level === 1 ? 36 : 18,
                  color: isSelected ? iconSelectedColor : 'text.primary',

                  borderRadius: `${borderRadius}px`,
                  width: 46,
                  height: 46,
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    bgcolor: mode === ThemeMode.DARK ? alpha(theme.palette.secondary.main, 0.25) : 'secondary.light',
                  },
                  ...(isSelected && {
                    bgcolor: mode === ThemeMode.DARK ? alpha(theme.palette.secondary.main, 0.25) : 'secondary.light',
                    '&:hover': {
                      bgcolor: mode === ThemeMode.DARK ? alpha(theme.palette.secondary.main, 0.3) : 'secondary.light',
                    },
                  }),
                }}
              >
                {itemIcon}
              </ListItemIcon>
            </ButtonBase>
          </Tooltip>

          {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <Box sx={{ position: 'relative' }}>
              <ListItemText
                primary={
                  <Typography
                    ref={ref}
                    noWrap
                    overflow="hidden"
                    textOverflow="ellipsis"
                    variant={isSelected ? 'h5' : 'body1'}
                    color="inherit"
                    width={102}
                  >
                    {item.title}
                    {isOwner && (
                      <ButtonBase
                        sx={{ borderRadius: '12px', position: 'absolute', right: '-80%' }}
                        onMouseDown={handleClick}
                        aria-label='"more options"'
                      >
                        <Avatar
                          variant="rounded"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.smallAvatar,
                            background:
                              theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color:
                              theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.dark,
                            zIndex: 1,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                              background: theme.palette.secondary.main,
                              color: theme.palette.secondary.light,
                            },
                          }}
                          aria-controls="menu-post"
                          aria-haspopup="true"
                        >
                          <MoreVertTwoToneIcon fontSize="inherit" />
                        </Avatar>
                      </ButtonBase>
                    )}
                    <Menu
                      id="menu-post"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={handleDeleteWorkspace}>Delete</MenuItem>
                    </Menu>
                  </Typography>
                }
                secondary={
                  !isOwner && (
                    <Tooltip title={`Shared by ${user?.name}`} placement="right">
                      <Chip
                        label={'shared'}
                        size="small"
                        sx={{
                          background: mode === ThemeMode.DARK ? 'text.primary' : 'secondary.main',
                          color: mode === ThemeMode.DARK ? 'text.primary' : 'secondary.main',
                        }}
                      />
                    </Tooltip>
                  )
                }
              />
            </Box>
          )}

          {drawerOpen && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          component={Link}
          to={item.url!}
          target={itemTarget}
          disabled={true}
          sx={{
            borderRadius: isParents ? `${borderRadius}px` : 0,
            mb: isParents ? 0 : 0.5,
            alignItems: 'flex-start',
            backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
            py: 0,
            pl: 2,
            mr: isParents ? 1 : 0,
          }}
          selected={isSelected}
          onClick={() => itemHandler()}
        >
          <ListItemIcon
            sx={{
              my: 5,
              minWidth: !item?.icon ? 18 : 36,
            }}
          >
            {itemIcon}
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography variant={isSelected ? 'h5' : 'body1'} color="inherit">
                {item.title}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )
            }
          />

          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;
