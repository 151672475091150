import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared/constants.ts';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, Avatar } from '@mui/material';

// project imports
import { User, UserStatusEnum } from '@/modules/users/types.ts';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import UserDefaultImage from '@assets/images/users/user-round.svg';
import { useCurrentUser } from '@modules/auth/hooks/useCurrentUser.ts';

interface UserItemProps {
  user: User;
  onDeleteUser: (user: User) => void;
}

export const UserListItem = ({ user, onDeleteUser }: UserItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hasAccess = usePowerUserAccess();
  const currentUser = useCurrentUser();
  const isSelf = currentUser?.id === user.id;

  const userUri = generatePath(ROUTES.USERS.USER, { id: user.id });
  const editUri = generatePath(ROUTES.USERS.EDIT, { id: user.id });

  return (
    <TableRow onClick={() => navigate(userUri)} sx={{ cursor: 'pointer' }} hover>
      <TableCell sx={{ pl: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar alt={`${user.name}'s image`} src={UserDefaultImage} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography align="left" variant="subtitle1" component="div">
              {user.name}{' '}
              {user.status !== UserStatusEnum.DEACTIVATED && (
                <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />
              )}
            </Typography>

            <Typography align="left" variant="subtitle2" noWrap>
              {user.email}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell>
        <Chip
          label={user.status}
          size="small"
          sx={{
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
            color: theme.palette.success.dark,
          }}
        />
      </TableCell>
      <TableCell align="center" sx={{ pr: 3 }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Tooltip placement="top" title="Edit">
            <IconButton
              disabled={isSelf ? false : !hasAccess}
              color="primary"
              aria-label="edit"
              size="large"
              onClick={(e) => {
                e.stopPropagation();
                navigate(editUri);
              }}
            >
              <EditIcon sx={{ fontSize: '1.1rem' }} />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <IconButton
              disabled={!hasAccess}
              color="primary"
              sx={{
                color: theme.palette.orange.dark,
                borderColor: theme.palette.orange.main,
                '&:hover ': { background: theme.palette.orange.light },
              }}
              size="large"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteUser(user);
              }}
            >
              <BlockTwoToneIcon sx={{ fontSize: '1.1rem' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
