import { QueryRawData } from '@modules/query/types.ts';
import { isNumber } from '@shared/helpers/formatCellValue.ts';
import moment from 'moment';

const yearRegex = /year/gi;
const monthRegex = /month/gi;
const digitYearRegex = /^\d{4}$/;
const digitFullDateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
const digitMonthRegex = /^([1-9]|1[0-2])$/;

/**
 * Custom hook to format raw data into a consistent date format.
 *
 * @returns {Object} An object containing the formatRawData function.
 */
export const useRawDataFormat = (): { formatRawData: (rawData: QueryRawData, categoryKey: string) => QueryRawData } => {
  /**
   * Formats raw data into a consistent date format.
   *
   * @param {QueryRawData} rawData - The raw data to be formatted.
   * @param {string} categoryKey - The key representing the category (e.g., date).
   * @returns {QueryRawData} The formatted data.
   */
  const formatRawData = (rawData: QueryRawData, categoryKey: string): QueryRawData => {
    let isYear = false;
    let isMonth = false;
    let yearKey = '';
    let monthKey = '';
    let result = sortDataByKey(rawData, categoryKey);

    rawData.forEach((data) => {
      if (digitFullDateRegex.test(String(data[categoryKey]))) {
        // already in correct format
        return;
      }
      for (const key in data) {
        if (yearRegex.test(key) && digitYearRegex.test(String(data[key]))) {
          isYear = true;
          yearKey = key;
        } else if (monthRegex.test(key) && digitMonthRegex.test(String(data[key]))) {
          isMonth = true;
          monthKey = key;
        }
      }
    });

    if (isYear && isMonth) {
      // convert to full date yyyy-mm-dd
      result = mergeToYearMonthDate(rawData, categoryKey, yearKey, monthKey);
    } else if (isMonth) {
      // convert to mm-dd, with default dd.
      result = convertToMonthDay(rawData, categoryKey, monthKey);
    } else if (
      rawData?.length &&
      !isNumber(rawData[0][categoryKey]) &&
      moment(rawData[0][categoryKey], true).isValid()
    ) {
      result = convertToDefaultDate(rawData, categoryKey);
    }

    return result;
  };

  return { formatRawData };
};

// Sort data by key
function sortDataByKey(data: QueryRawData, key: string) {
  if (!data) {
    return [];
  }

  return data.slice().sort((a, b) => {
    if (a[key] != null && b[key] != null && a[key] < b[key]) return -1;
    if (a[key] != null && b[key] != null && a[key] > b[key]) return 1;
    return 0;
  });
}

// Merge year and month to a single date
function mergeToYearMonthDate(rawData: QueryRawData, categoryKey: string, yearKey: string, monthKey: string) {
  return rawData.map((data) => {
    const { [yearKey]: year, [monthKey]: month, ...rest } = data;
    return {
      //   [categoryKey]: formatCellValue(`${year}-${month}`, CellFormat.DateTime),
      [categoryKey]: moment(`${year}-${month}`).format('MMM-YYYY'),
      ...rest,
    };
  });
}

// Convert month to mm-dd
function convertToMonthDay(rawData: QueryRawData, categoryKey: string, monthKey: string) {
  return rawData.map((data) => {
    const { [monthKey]: month, ...rest } = data;
    return {
      [categoryKey]: moment(month, 'MM').format('MMM-YYYY'),
      ...rest,
    };
  });
}

// Convert to default date format
function convertToDefaultDate(rawData: QueryRawData, categoryKey: string) {
  return rawData.map((data) => {
    return {
      ...data,
      [categoryKey]: data[categoryKey] ? moment(data[categoryKey]).format('MMM-YYYY') : data[categoryKey],
    };
  });
}
