import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';

import {
  GET_WORKSPACE_LIST_QUERY_KEY,
  useGetWorkspaceQuery,
  useUpdateWorkspaceMutation,
} from '@/modules/workspaces/queries';
import { Workspace, WorkspaceItemData } from '@/modules/workspaces/types';
import { VisualizationTypes } from '@/modules/query/types';
import { useLocalStorage } from 'usehooks-ts';
import { CURRENT_WORKSPACE_ID_KEY } from '@/modules/workspaces/constants';
import toast from 'react-hot-toast';

import { AskQuestionModal } from '@/modules/query/components/AskQuestionModal';
import { useIsWorkspaceOwner } from '@/modules/workspaces/hooks/useIsWorkspaceOwner.ts';
import { Box, Button, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import Loader from '@/themes/components/Loader.tsx';

import { queryClient } from '@/api/queryClient';
import { WorkspaceItem } from '@/modules/workspaces/components/WorkspaceItem.tsx';
import * as _ from 'lodash';

// TODO: move to shared.
const GRID_COLUMNS = 3;

export const WorkspaceGrid = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [workspaceId, setWorkspaceId] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [boxWidth, setBoxWidth] = useState<number>(0);
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetWorkspaceQuery(Number(workspaceId));
  const isOwner = useIsWorkspaceOwner(workspace);
  const { mutate: deleteWorkspaceItem } = useUpdateWorkspaceMutation({
    loading: 'Deleting workspace item',
    success: 'Item deleted successfully',
  });
  const { mutate: updateWorkspace } = useUpdateWorkspaceMutation({
    loading: 'Updating workspace',
    success: 'Workspace updated successfully',
  });

  const [charts, setCharts] = useState<WorkspaceItemData[]>([]);

  // Modal state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Initialization
  useEffect(() => {
    if (typeof workspace !== 'undefined' && !isWorkspaceLoading && workspace?.content !== null) {
      const charts = workspace.content.charts as WorkspaceItemData[];
      if (charts.length > 0) {
        setCharts(charts);
      } else {
        // If the workspace is empty, set the default items
        setCharts([]);
      }
    } else if (typeof workspace === 'undefined' && !isWorkspaceLoading) {
      const userWorkspaces = queryClient.getQueryData<{ items: Workspace[] }>([GET_WORKSPACE_LIST_QUERY_KEY]);
      const defaultWorkspaceId = userWorkspaces?.items[0].id;
      setWorkspaceId(defaultWorkspaceId ?? 0);
      setCharts([]);
    } else {
      setCharts([]);
    }
  }, [workspace, isWorkspaceLoading, workspaceId]);

  // Tracking parent box width changes in order to update the grid layout
  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        setBoxWidth(entries[0].contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (boxRef.current) {
      setBoxWidth(boxRef.current.getBoundingClientRect().width);
      resizeObserver.observe(boxRef.current);
    }

    // Cleanup function
    return () => {
      if (boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, [boxRef.current, workspaceId]);

  const handleItemRemove = (chartId: string) => {
    if (typeof workspace !== 'undefined' && workspace.content !== null) {
      const newCharts = charts.filter((chart) => chart.i !== chartId);

      deleteWorkspaceItem({ workspaceId: workspace.id, payload: { content: { charts: newCharts } } });

      !newCharts.length && setIsEdit(false);
    } else {
      toast.error('Could not remove chart');
      return;
    }
  };

  const currentChartsLayout = useMemo(() => {
    return charts.map((chart) => {
      return {
        i: chart.i,
        x: chart.x,
        y: chart.y,
        w: chart.w,
        h: chart.h,
      };
    });
  }, [charts]);

  // Debounce the updateWorkspace function to limit calls
  const debouncedUpdateWorkspace = _.debounce(updateWorkspace, 1500);

  // Handle the layout change. Saves workspace content.
  const handleChange = (layout: Layout[]) => {
    // Filter out the grid button.
    const filteredLayout = layout.filter((el) => el.i !== '+');

    // If there are no charts, return.
    if (filteredLayout.length === 0) {
      return;
    }
    // Map the layout to the necessary format.
    const mappedLayout = filteredLayout.map((el) => {
      return {
        i: el.i,
        x: el.x,
        y: el.y,
        w: el.w,
        h: el.h,
      };
    });

    if (typeof workspace === 'undefined' || workspace?.content === null) {
      toast.error('Could not update workspace');
      return;
    }

    if (!_.isEqual(mappedLayout, currentChartsLayout)) {
      const newCharts = filteredLayout.map((el) => {
        const chart = charts.find((chart) => chart.i === el.i);
        return {
          ...chart,
          ...el,
        };
      });

      // Call the debounced function with necessary parameters
      debouncedUpdateWorkspace({ workspaceId: workspace.id, payload: { content: { charts: newCharts } } });
    }
  };

  // Check if the user can delete the chart
  const canDelete = isOwner && isEdit;

  // Check if the grid button is available
  const isGridButtonAvailable = isOwner && !isEdit;

  // Check if the edit button is available
  const showEditButton = isOwner && charts.length > 0;

  const createElement = useCallback(
    (el: WorkspaceItemData) => {
      const isChart = el.visualizationType === VisualizationTypes.CHART || el.visualizationType === undefined;
      return (
        <Box
          key={el.i}
          data-grid={{
            x: el.x,
            y: el.y,
            w: el.w,
            h: el.h,
            minH: isChart ? 2 : 1,
            isDraggable: isEdit,
            isResizable: isEdit,
          }}
        >
          <WorkspaceItem isEdit={isEdit} data={el} canDelete={canDelete} handleItemRemove={handleItemRemove} />
        </Box>
      );
    },
    [charts, workspaceId, isEdit]
  );

  // Calculate the position of the grid button.
  const gridButtonPosX = useMemo(() => {
    if (charts.length === 0) {
      return { x: 0 };
    }
    const filteredCharts = charts.filter((chart) => chart.i !== '+');
    const lastGridItem = filteredCharts.reduce((last, current) => {
      // Check if the current object has a greater value for y, x, or w.
      if (
        current.y > last.y ||
        (current.y === last.y && current.x > last.x) ||
        (current.y === last.y && current.x === last.x && current.w > last.w)
      ) {
        return current;
      }
      return last;
    }, charts[0]);

    // Grid item has x position and his width. The grid button should be placed after the last grid item.
    // That's why we add the width to the x position. That's how we know if there is space for the grid button.
    return { x: lastGridItem.x + lastGridItem.w };
  }, [charts]);

  if (isWorkspaceLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box ref={boxRef}>
        {showEditButton && (
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isEdit} onChange={() => setIsEdit(!isEdit)} />}
              label={isEdit ? 'Edit mode' : 'View mode'}
            />
          </FormGroup>
        )}
        {boxWidth > 0 && (
          <GridLayout
            className="layout"
            cols={GRID_COLUMNS}
            rowHeight={300}
            width={boxWidth}
            onLayoutChange={handleChange}
            draggableCancel=".CancelDragOnDelete"
          >
            {!!charts && charts.map(createElement)}

            {/* Grid Button */}
            {isGridButtonAvailable && (
              <Box
                key="+"
                display="flex"
                data-grid={{
                  x: gridButtonPosX.x % GRID_COLUMNS,
                  y: Infinity,
                  w: 1,
                  h: 2,
                  isDraggable: false,
                  isResizable: false,
                }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Button
                  variant="text"
                  color="primary"
                  aria-label="add new chart"
                  sx={{
                    border: '1px dashed',
                    borderColor: 'primary',
                    width: '80%',
                    height: '80%',
                  }}
                  onClick={handleOpen}
                >
                  <AddIcon />
                  <Typography>Ask a question and pin results here</Typography>
                </Button>
              </Box>
            )}
          </GridLayout>
        )}
      </Box>
      {open && <AskQuestionModal isOpen={open} onClose={handleClose} />}
    </>
  );
};
