import { FC } from 'react';
import { DataGridStyled } from '@/modules/query/components/styled/table/DataGridStyled';

import { DataGridProps, GridColumnHeaderParams } from '@mui/x-data-grid';
import { TableColorScheme } from '@modules/query/types.ts';
import { DEFAULT_TABLE_COLOR_SCHEME } from '@shared/constants.ts';

interface BaseTableProps
  extends Omit<DataGridProps, 'initialState' | 'pageSizeOptions' | 'disableRowSelectionOnClick' | 'color'> {
  colorScheme?: TableColorScheme;
  headerComponent?: FC<{ params: GridColumnHeaderParams }>;
}

export const BaseTable: FC<BaseTableProps> = ({ headerComponent, colorScheme, ...props }) => {
  return (
    <DataGridStyled
      {...props}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => 60}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      colorScheme={colorScheme ?? DEFAULT_TABLE_COLOR_SCHEME}
    />
  );
};
