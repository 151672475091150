// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// project imports
import { UserStatusEnum, User } from '@/modules/users/types.ts';
import { UserListItem } from '@/modules/users/components/UserListItem';
import ConfirmationModal from '@/shared/components/ConfirmationModal';
import { useState } from 'react';
import { useDeleteUserMutation } from '@modules/users/queries.ts';

const UserList = ({ users }: { users: User[] }) => {
  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  const { mutate: deleteUser } = useDeleteUserMutation();

  const onDeleteUser = async () => {
    try {
      if (!userToDelete) return;
      await deleteUser(userToDelete.id);
    } finally {
      setUserToDelete(null);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>User Profile</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center" sx={{ pr: 3 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users
                .filter((user) => user.status !== UserStatusEnum.DEACTIVATED)
                .map((user, index) => (
                  <UserListItem onDeleteUser={(user) => setUserToDelete(user)} key={index} user={user} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        open={!!userToDelete}
        text={`Are you sure to delete the user?`}
        onClose={() => {
          setUserToDelete(null);
        }}
        onConfirm={onDeleteUser}
      />
    </>
  );
};

export default UserList;
