import { useState, useEffect } from 'react';
import { useWorkspacesMenu } from '@/themes/menu/workspaces';
import { NavItemType } from '@themes/types';

// ==============================|| MENU ITEMS ||============================== //

interface MenuItems {
  items: NavItemType[];
}

export const useMenuItems = () => {
  const [menuItems, setMenuItems] = useState<MenuItems>({ items: [] });
  const workspaces = useWorkspacesMenu();

  useEffect(() => {
    setMenuItems({ items: [...workspaces] });
  }, [workspaces]);

  return menuItems;
};
