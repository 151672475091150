import moment from 'moment';

export interface FieldsToFormat {
  field: string;
  type: 'number' | 'date' | 'string';
}

export type ItemFormatMap = { [key: string]: CellFormat };

export enum CellFormat {
  Number = 'number',
  Currency = 'currency',
  Percent = 'percent',
  Scientific = 'scientific',
  Accounting = 'accounting',
  Financial = 'financial',
  Date = 'date',
  DateTime = 'dateTime',
  ShortDate = 'shortDate',
  ShortDateTime = 'shortDateTime',
  MonthDay = 'monthDay',
  ShortMonthDay = 'shortMonthDay',
  YearMonthDay = 'yearMonthDay',
  MonthYear = 'monthYear',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

export const NumberFormats = [
  CellFormat.Number,
  CellFormat.Currency,
  CellFormat.Percent,
  CellFormat.Scientific,
  CellFormat.Accounting,
  CellFormat.Financial,
  CellFormat.Hour,
  CellFormat.Minute,
  CellFormat.Second,
];

export const DateFormats = [
  CellFormat.Date,
  CellFormat.DateTime,
  CellFormat.ShortDate,
  CellFormat.ShortDateTime,
  CellFormat.MonthDay,
  CellFormat.ShortMonthDay,
  CellFormat.YearMonthDay,
  CellFormat.MonthYear,
];

export const isNumber = (value: any) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

const numberFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });

function formatNumber(value: number | string): string {
  return numberFormatter.format(Number(value));
}

export const isDate = (value: string) => {
  // Check if the value is a string. Numbers mighht be treated as timestamps.
  if (typeof value !== 'string') return false;

  // Create a Date object and validate it's a real date.
  const date = new Date(value);
  return date instanceof Date && !isNaN(date.getTime()) && value.includes('-');
};

export function formatCellValue(value: any, formatType: CellFormat): string {
  if (value === null || value === undefined) {
    return '';
  }

  const isNumeric = isNumber(value);

  switch (formatType) {
    case CellFormat.Number:
      return isNumeric ? new Intl.NumberFormat().format(value) : value.toString();

    case CellFormat.Currency:
      return isNumeric
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(value)
        : value.toString();

    case CellFormat.Percent:
      return isNumeric ? `${(parseFloat(value) * 100).toFixed(2)}%` : value.toString();

    case CellFormat.Scientific:
      return isNumeric ? parseFloat(value).toExponential(2) : value.toString();

    case CellFormat.Date:
      return Date.parse(value)
        ? new Date(value).toLocaleDateString('en-AU', { year: 'numeric', month: 'long', day: '2-digit' })
        : value.toString(); // Example: 12/08/2024

    case CellFormat.MonthDay:
      return Date.parse(value)
        ? new Date(value).toLocaleDateString('en-AU', { month: 'long', day: 'numeric' })
        : value.toString(); // Example: August 12

    case CellFormat.ShortMonthDay:
      return Date.parse(value) ? moment(value).format('MMM-DD') : value.toString(); // Example: Aug-12

    case CellFormat.ShortDate:
      return Date.parse(value)
        ? new Date(value).toLocaleDateString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })
        : value.toString(); // Example: 12 Aug 2024

    case CellFormat.DateTime:
      return Date.parse(value)
        ? new Date(value).toLocaleString('en-AU', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
        : value.toString(); // Example: Monday, 12 August 2024, 10:30 AM

    case CellFormat.ShortDateTime:
      return Date.parse(value)
        ? new Date(value).toLocaleString('en-AU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
        : value.toString(); // Example: 12 Aug 2024, 10:30 AM

    case CellFormat.YearMonthDay:
      return Date.parse(value)
        ? new Date(value)
            .toLocaleDateString('en-AU', { year: 'numeric', month: '2-digit', day: '2-digit' })
            .split('/')
            .reverse()
            .join('-')
        : value.toString(); // Example: 2024-08-12 (Year-Month-Day)

    case CellFormat.MonthYear:
      return Date.parse(value)
        ? new Date(value).toLocaleDateString('en-AU', { year: 'numeric', month: 'long' })
        : value.toString();

    case CellFormat.Accounting:
      return isNumeric
        ? parseFloat(value) < 0
          ? `($${Math.abs(parseFloat(value)).toFixed(2)})`
          : `$${parseFloat(value).toFixed(2)}`
        : value.toString();

    case CellFormat.Financial:
      return isNumeric
        ? parseFloat(value) < 0
          ? `(${new Intl.NumberFormat().format(Math.abs(parseFloat(value)))})`
          : new Intl.NumberFormat().format(value)
        : value.toString();

    case CellFormat.Hour:
      return `${formatNumber(value)}hrs`;

    case CellFormat.Minute:
      return `${formatNumber(value)}mins`;

    case CellFormat.Second:
      return `${formatNumber(value)}secs`;

    default:
      return value.toString(); // Fallback to plain text if no matching format
  }
}
