import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { queryClient } from '@/api/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppRoutes } from '@/routes.tsx';

import ThemeCustomization from '@themes/index';
import { ConfigProvider } from '@themes/contexts/ConfigContext';
import Locales from '@themes/components/Locales.tsx';
import { CURRENT_USER_QUERY_KEY } from '@/modules/auth/queries.ts';
import { GET_WORKSPACE_LIST_QUERY_KEY } from '@/modules/workspaces/queries.ts';

import { PersistQueryClientOptions, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from 'react-hot-toast';

import { REFRESH_TOKEN_LIFETIME } from '@shared/constants';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ErrorBoundary from '@/shared/components';
import * as Sentry from '@sentry/react';

const { VITE_GOOGLE_OAUTH_ID } = import.meta.env;

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const persistableQueries = {
  [CURRENT_USER_QUERY_KEY]: true,
  [GET_WORKSPACE_LIST_QUERY_KEY]: true,
};

const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  persister,
  maxAge: REFRESH_TOKEN_LIFETIME,
  dehydrateOptions: {
    shouldDehydrateMutation: () => false,
    shouldDehydrateQuery: (query) =>
      (query.queryKey[0] as string) in persistableQueries && query.state.status === 'success',
  },
};

const App = () => {
  const [isHydrated, setIsHydrated] = React.useState(false);

  const handleHydrationSuccess = React.useCallback(() => {
    setIsHydrated(true);
    // eslint-disable-next-line no-console
    console.log('Query cache dehydrated ✅');
  }, []);
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions} onSuccess={handleHydrationSuccess}>
      {!isHydrated ? (
        <div className="flex h-[100dvh] w-full items-center justify-center">Loading</div>
      ) : (
        <GoogleOAuthProvider clientId={VITE_GOOGLE_OAUTH_ID}>
          <ConfigProvider>
            <ThemeCustomization>
              <Locales>
                <BrowserRouter>
                  <ErrorBoundary>
                    <AppRoutes />
                  </ErrorBoundary>
                </BrowserRouter>
              </Locales>
            </ThemeCustomization>
          </ConfigProvider>
        </GoogleOAuthProvider>
      )}
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
