import { httpClient } from '@/api/httpClient.ts';
import { CONNECTOR_ROUTES, DATA_SOURCE_ROUTES } from '@/api/routes.ts';
import { AxiosResponse } from 'axios';
import {
  GetDataSourcesResponse,
  GetDataSourcesDetailsResponse,
  CreateDataSourceRequest,
  FinalizeDataSourceRequest,
  CreateDataSourceResponse,
  GetDatasetsResponse,
  Connector,
  ConnectorsFilters,
  UpdateConnectorRequest,
  UpdateConnectorResponse,
} from '@/modules/datasources/types.ts';
import { generatePath } from 'react-router-dom';

/**
 * Returns a list of data sources.
 *
 * @async
 * @returns {Promise<AxiosResponse<GetDataSourcesResponse[], any>>} The list of users.
 */
export const getDataSources = async (
  userId?: number,
  tenantId?: number
): Promise<AxiosResponse<GetDataSourcesResponse[]>> => {
  return httpClient.get<GetDataSourcesResponse[]>(DATA_SOURCE_ROUTES.DATA_SOURCES, {
    params: { user_id: userId, tenant_id: tenantId },
  });
};

/**
 * Returns a list of data sources.
 *
 * @async
 * @returns {Promise<AxiosResponse<GetDataSourcesDetailsResponse, any>>} The list of users.
 */
export const getDataSourceDetails = async (id: number): Promise<AxiosResponse<GetDataSourcesDetailsResponse>> => {
  const path = generatePath(DATA_SOURCE_ROUTES.DATA_SOURCE, { id });
  return httpClient.get<GetDataSourcesDetailsResponse>(path);
};

/**
 * Creates a new data source.
 *
 * @async
 * @param {CreateDataSourceRequest} data - The data source to create.
 */
export const createDataSource = async (
  data: CreateDataSourceRequest
): Promise<AxiosResponse<CreateDataSourceResponse>> => {
  return httpClient.post<CreateDataSourceResponse>(DATA_SOURCE_ROUTES.DATA_SOURCES, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};

/**
 * Deletes a data source by ID.
 */
export const deleteDataSource = async (id: number): Promise<AxiosResponse<any>> => {
  return httpClient.delete(`${DATA_SOURCE_ROUTES.DATA_SOURCES}/${id}`);
};

/**
 * Updates a data source by ID.
 */
export const finalizeDataSource = async ({ id, ...data }: FinalizeDataSourceRequest): Promise<AxiosResponse<any>> => {
  return httpClient.put(
    `${DATA_SOURCE_ROUTES.DATA_SOURCES}/${id}`,
    { ...data, status: 'Active' },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

/**
 * Returns a list of bigQuery datasets
 *
 * @async
 * @returns {Promise<AxiosResponse<GetDatasetsResponse[]>>} The list of datasets.
 */
export const getBigQueryDatasets = async (
  datasourceId: number,
  projectId: string
): Promise<AxiosResponse<GetDatasetsResponse[]>> => {
  const path = generatePath(DATA_SOURCE_ROUTES.BIG_QUERY_DATASETS, { id: datasourceId });
  return httpClient.get<GetDatasetsResponse[]>(path, { params: { project_id: projectId } });
};

/**
 * Fetching connectors by filters
 * @async
 * @param params connectors filters
 */
export const getConnectors = async (params: ConnectorsFilters) => {
  return httpClient.get<Array<Connector>>(CONNECTOR_ROUTES.CONNECTORS, { params });
};

/**
 * Deletes a connector by ID.
 */
export const deleteConnector = async (id: number): Promise<AxiosResponse<unknown>> => {
  const path = generatePath(CONNECTOR_ROUTES.CONNECTOR, { id });
  return httpClient.delete(path);
};

/**
 * Updates existing connector.
 *
 * @async
 * @param {number} id. Connector ID.
 * @param {UpdateConnectorRequest} data. Payload for connector update.
 */
export const updateConnector = async (
  id: number,
  data: UpdateConnectorRequest
): Promise<AxiosResponse<UpdateConnectorResponse>> => {
  const path = generatePath(CONNECTOR_ROUTES.CONNECTOR, { id });
  return httpClient.put<UpdateConnectorResponse>(path, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};
