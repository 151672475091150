/**
 * @interface
 * @description Represents a user object.
 */
export interface User {
  id: number;
  name: string;
  email: string;
  timezone: string;
  role: UserRolesEnum | string;
  tenant_id: number;
  status: UserStatusEnum;
  acls: ACL[];
}

export interface ACL {
  id: number;
  permission: Permission;
}

export interface Permission {
  id: number;
  name: string;
}

export interface UserResponse extends User {}

export interface UserCreateResponse extends User {}

export type UsersPaginateResponse = {
  items: User[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export interface UserCreateRequest {
  name: string;
  email: string;
  password: string;
  timezone: string;
  permissions: UserTypesEnum[];
  role: UserRolesEnum | string;
}

export interface UserUpdateRequest {
  name?: string;
  timezone?: string;
  permissions?: UserTypesEnum[];
  role?: UserRolesEnum | string;
  email?: string;
}

export interface UserUpdateResponse {
  name: string;
  role: UserRolesEnum | string;
  timezone: string;
}

export type UsersFilters = {
  page: number;
  size: number;
  search?: string;
};

export enum UserProfileActionsEnum {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum UserRolesEnum {
  CEO = 'CEO',
  MANAGER = 'Manager',
  DEVELOPER = 'Developer',
  DESIGNER = 'Designer',
}

export enum UserStatusEnum {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DEACTIVATED = 'Deactivated',
}

export enum UserTypesEnum {
  USER = 'User',
  ADMIN = 'Admin',
  POWER_USER = 'Power User',
}
