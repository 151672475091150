import { useMemo } from 'react';
import { useLocation, useParams, useNavigate, generatePath } from 'react-router-dom';
import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout.tsx';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';
import { ROUTES } from '@shared/constants.ts';
import { useDatasourceQuery } from '@/modules/datasources/queries';

// project imports
import MainCard from '@/themes/components/cards/MainCard';

// material-ui
import { Typography, Button, Grid, Stack } from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '@/themes/components/Loader';
import BigQueryDataSource from '@modules/datasources/components/BigQueryDataSource.tsx';
import { capitalize } from 'lodash';

export const DataSourcePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const hasAccess = usePowerUserAccess();
  const viewMode = state ? state.viewMode : true;
  const dataSourceEditUri = generatePath(ROUTES.DATA_SOURCES.EDIT, { id });

  const { data: dataSource, isLoading: isDataSourceLoading } = useDatasourceQuery(Number(id));

  const DataSourceComponent = useMemo(() => {
    if (!dataSource) return null;
    switch (dataSource.type) {
      case 'Google BigQuery':
        return <BigQueryDataSource dataSource={dataSource} />;
      default:
        return null;
    }
  }, [dataSource]);

  if (isDataSourceLoading) {
    return <Loader />;
  }

  return (
    <AuthorizedPageLayout title={`${capitalize(dataSource?.label)} Data Source`}>
      <MainCard
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '24px',
        }}
        title={
          <Grid container justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography variant="h3">Configure your {dataSource?.label} data source</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              {viewMode && (
                <Button
                  disabled={!hasAccess}
                  onClick={() => navigate(dataSourceEditUri, { state: { viewMode: false } })}
                >
                  <EditIcon aria-label="Edit Details" />
                </Button>
              )}
              <Button
                size="large"
                aria-label="navigation icon"
                startIcon={<NavigateNextRoundedIcon sx={{ transform: 'rotate(180deg)' }} />}
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            </Stack>
          </Grid>
        }
      >
        {DataSourceComponent}
      </MainCard>
    </AuthorizedPageLayout>
  );
};
