import React, { SyntheticEvent, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';

import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout';

/**
 * Represents the settings page component.
 *
 * @function
 * @returns {React.ReactNode} The rendered settings page component.
 */
const SettingsPage = (): React.ReactNode => {
  const [value, setValue] = useState<string>('1');

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <AuthorizedPageLayout title={'Settings'}>
        <Box>
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleChange} textColor="secondary" indicatorColor="secondary" variant="fullWidth">
                <Tab label="Add User" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">Add User Form</TabPanel>
            <TabPanel value="2"></TabPanel>
          </TabContext>
        </Box>
      </AuthorizedPageLayout>
    </>
  );
};

export default SettingsPage;
