import { Link } from 'react-router-dom';
import { ROUTES } from '@shared/constants.ts';
import { Box, Button, Stack } from '@mui/material';

const ErrorPage = () => {
  return (
    <Stack sx={{ alignItems: 'center', justifyContent: 'center', flex: 1, height: '100vh', paddingY: 3 }}>
      <Stack sx={{ flexDirection: 'column', alignItems: 'center', padding: 3 }}>
        <Box component={'h3'} sx={{ textAlign: 'center', fontSize: 20 }}>
          Sorry, something is wrong. Please try to refresh the page.
        </Box>
        <Box sx={{ padding: 2.5 }}>
          <Button
            sx={{ maxWidth: 'max-content', padding: 0, fontWeight: 'bold' }}
            type="submit"
            size="large"
            variant="contained"
            color="secondary"
          >
            <Link
              to={ROUTES.DASHBOARD}
              style={{ color: 'inherit', textDecoration: 'none', padding: '8px 22px' }}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = '/';
              }}
            >
              Go Home
            </Link>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
export default ErrorPage;
