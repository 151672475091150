import { Layout } from 'react-grid-layout';

interface ClosestPosition {
  x: number;
  y: number;
}

/**
 * Calculate the closest position to the starting coordinates that fits the new item
 *
 * @param layout - The current layout
 * @param columns - The number of columns in the grid
 * @param startX - The starting x coordinate
 * @param startY - The starting y coordinate
 * @param itemWidth - The width of the new item
 * @param itemHeight - The height of the new item
 */
const calculateClosestPosition = (
  layout: Layout[],
  columns: number,
  startX: number,
  startY: number,
  itemWidth: number,
  itemHeight: number
) => {
  const gridRows = layout.reduce((max, item) => Math.max(max, item.y + item.h), 0);
  // Create a grid to track occupied positions. gridRow + 2 provided to for safety in case if whole row is occupied.
  const grid = Array(gridRows + 2)
    .fill(null)
    .map(() => Array(columns).fill(false));

  // Mark existing items in the grid
  layout.forEach((item) => {
    for (let dx = 0; dx < item.w; dx++) {
      for (let dy = 0; dy < item.h; dy++) {
        const x = item.x + dx;
        const y = item.y + dy;
        if (y < grid.length && x < columns) {
          grid[y][x] = true;
        }
      }
    }
  });

  // Helper function to check if a spot can fit the new item
  function canPlace(x, y) {
    for (let dx = 0; dx < itemWidth; dx++) {
      for (let dy = 0; dy < itemHeight; dy++) {
        if (x + dx >= columns || y + dy >= grid.length || grid[y + dy][x + dx]) {
          return false;
        }
      }
    }
    return true;
  }

  // Start searching from the initial coordinates, expand outward
  let closestPosition: ClosestPosition | null = null;
  let minDistance = Infinity;

  for (let y = 0; y < grid.length; y++) {
    for (let x = 0; x < columns; x++) {
      if (canPlace(x, y)) {
        // Calculate Manhattan distance from the starting position
        const distance = Math.abs(startX - x) + Math.abs(startY - y);
        if (distance < minDistance) {
          minDistance = distance;
          closestPosition = { x, y };
        }
      }
    }
  }

  return closestPosition;
};

export const useClosestPosition = () => {
  return calculateClosestPosition;
};
