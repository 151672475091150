import { useFormik } from 'formik';
import _ from 'lodash';
import { GetDataSourcesDetailsResponse } from '../types';
import { useBigQueryDatasetsQuery, useFinalizeDataSourceMutation } from '@modules/datasources/queries.ts';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import AnimateButton from '@themes/components/extended/AnimateButton.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';
import * as yup from 'yup';

const validationSchema = yup.object({
  config: yup.object({
    project_id: yup.string().required('Please select project'),
    dataset_id: yup.string().required('Please select dataset'),
  }),
});

const initialValues = {
  id: 0,
  label: '',
  config: {
    project_id: '',
    dataset_id: '',
  },
};

type Props = {
  dataSource: GetDataSourcesDetailsResponse;
};

const BigQueryDataSource = ({ dataSource }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasAccess = usePowerUserAccess();
  const viewMode = state ? state.viewMode : true;

  const formik = useFormik({
    initialValues: dataSource
      ? {
          id: dataSource.id,
          label: dataSource.label,
          config: {
            project_id: dataSource.config.project_id,
            dataset_id: dataSource.config.dataset_id,
          },
        }
      : _.cloneDeep(initialValues),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema,
    onSubmit: async (values) => {
      await finalizeDataSource({ ...values, datasource_type: 'Google BigQuery' });
    },
    enableReinitialize: true,
  });

  const { mutateAsync: finalizeDataSource, isPending: isFinalizeDataSourcePending } = useFinalizeDataSourceMutation({
    setErrors: formik.setErrors,
  });

  // Fetch the datasets for the BigQuery.
  const { data: datasets, isLoading: isDatasetsLoading } = useBigQueryDatasetsQuery(
    formik.values.id,
    formik.values.config.project_id,
    !!dataSource && !!formik.values.id
  );

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
        <TextField
          disabled={viewMode || !hasAccess}
          name="label"
          sx={{ marginBottom: '12px' }}
          value={formik.values.label}
          onChange={formik.handleChange}
          label="Data Source Label"
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
          <FormControl fullWidth sx={{ marginRight: '12px' }}>
            <InputLabel htmlFor="project-label">Project</InputLabel>
            <Select
              disabled={viewMode || !hasAccess}
              id="project-label"
              name="config.project_id"
              fullWidth
              label="Project"
              value={formik.values.config.project_id}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('config.dataset_id', '');
              }}
            >
              {dataSource?.projects?.map(({ id }) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="dataset-label">Dataset</InputLabel>
            <Select
              disabled={viewMode || isDatasetsLoading || !hasAccess}
              id="dataset-label"
              name={'config.dataset_id'}
              onChange={formik.handleChange}
              fullWidth
              label="Dataset"
              value={isDatasetsLoading ? '' : formik.values.config.dataset_id}
            >
              {datasets?.map(({ id }) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              )) || <MenuItem disabled>Select project first</MenuItem>}
            </Select>
          </FormControl>
        </Box>
        {!viewMode && (
          <Stack direction="row" spacing={1}>
            <Button
              disabled={isFinalizeDataSourcePending || !formik.values.config.dataset_id || datasets?.length === 0}
              sx={{ maxWidth: 'max-content' }}
              type="submit"
              variant="contained"
              onClick={formik.submitForm}
            >
              TEST & CONTINUE
            </Button>
            <AnimateButton>
              <Button
                disabled={isFinalizeDataSourcePending}
                type="button"
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </AnimateButton>
          </Stack>
        )}
      </Box>
    </form>
  );
};

export default BigQueryDataSource;
