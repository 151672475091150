import { httpClient } from '@/api/httpClient.ts';
import { V1_ROUTES } from '@/api/routes.ts';
import { AxiosResponse } from 'axios';
import { ExecuteQueryRequest, ExecuteQueryResponse } from '@/modules/query/types.ts';

/**
 * Creates a new data source.
 *
 * @async
 * @param {CreateDataSourceRequest} data - The data source to create.
 */
export const executeQuery = async (
  datasourceId: number,
  data: ExecuteQueryRequest
): Promise<AxiosResponse<ExecuteQueryResponse>> => {
  return httpClient.post<ExecuteQueryResponse>(V1_ROUTES.QUERY, data, {
    headers: { 'Content-Type': 'application/json' },
    params: { datasource_id: datasourceId },
  });
};
