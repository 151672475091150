import { useState, useEffect } from 'react';
// project imports
import { useGetWorkspaceListQuery } from '@/modules/workspaces/queries.ts';
// third-party

// assets
import { IconTableShare } from '@tabler/icons-react';

// type
import { NavItemType } from '@themes/types';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const useWorkspacesMenu = (): NavItemType[] => {
  const [workspaces, setWorkspaces] = useState<NavItemType[]>([]);
  const { data: workspaceResponse } = useGetWorkspaceListQuery(1, 50);

  useEffect(() => {
    if (workspaceResponse) {
      const updatedWorkspaces =
        workspaceResponse.items?.map((workspace) => ({
          id: String(workspace.id),
          title: workspace.name,
          type: 'group',
          url: `/${workspace.id}`,
          icon: IconTableShare,
          target: false,
        })) || [];
      setWorkspaces(updatedWorkspaces);
    }
  }, [workspaceResponse]);

  if (!workspaces) return [];

  return workspaces;
};
