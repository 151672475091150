import { useCallback, useMemo, useState } from 'react';
import {
  ChartVisualizationType,
  ColorScheme,
  ExecuteQueryResponse,
  SingleValueVisualizationType,
  StoredVisualization,
  VisualizationTypes,
} from '@/modules/query/types';
import { useGetWorkspaceQuery, useUpdateWorkspaceMutation } from '@/modules/workspaces/queries';
import { useLocalStorage } from 'usehooks-ts';
import { CURRENT_WORKSPACE_ID_KEY } from '@/modules/workspaces/constants';
import { useIsWorkspaceOwner } from '@/modules/workspaces/hooks/useIsWorkspaceOwner.ts';
import { Box, Button, Typography } from '@mui/material';
import { WorkspaceItemData } from '@/modules/workspaces/types';
import { useClosestPosition } from '../hooks/useClosestPosition';
import BaseModal from '@shared/components/BaseModal.tsx';
import { QueryForm } from '@modules/query/components/QueryForm.tsx';
import { ChartVisualization } from '@modules/query/components/ChartVisualization.tsx';
import { SingleValueVisualization } from '@modules/query/components/SingleValueVisualization.tsx';
import { ItemFormatMap } from '@shared/helpers/formatCellValue.ts';
import { useRawDataFormat } from '@modules/query/hooks/useRawDataFormat.ts';
import { isEmpty } from '@shared/helpers/isEmpty.ts';
import { NO_RESULTS_MESSAGE } from '@shared/constants.ts';
import Alert from '@shared/components/Alert.tsx';
import { isArrayOfObjectsEmpty } from '@shared/helpers/isArrayOfObjectsEmpty.ts';

export interface AskQuestionModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const AskQuestionModal = ({ isOpen, onClose }: AskQuestionModalProps) => {
  const calculateClosestPosition = useClosestPosition();
  const { formatRawData } = useRawDataFormat();

  // State to check if the SQL query is visible
  const [isQueryVisible, setIsQueryVisible] = useState<boolean>(false);

  const { mutate: updateWorkspace } = useUpdateWorkspaceMutation();
  const [workspaceId] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetWorkspaceQuery(Number(workspaceId));
  const isOwner = useIsWorkspaceOwner(workspace);

  const [queryResponse, setQueryResponse] = useState<ExecuteQueryResponse>();
  const [question, setQuestion] = useState<string>();

  const visualization = queryResponse?.visualization;
  const rawData = useMemo(() => {
    if (!queryResponse?.raw_data || !visualization || typeof visualization.value !== 'object') {
      return [];
    }
    return formatRawData(queryResponse.raw_data, (visualization?.value as ChartVisualizationType)?.categoryKey ?? '');
  }, [queryResponse, visualization, formatRawData]);

  const isResultEmpty = useMemo(() => {
    if (visualization?.type === VisualizationTypes.CHART) {
      return isEmpty(rawData);
    } else if (visualization?.type === VisualizationTypes.NUMBER || visualization?.type === VisualizationTypes.STRING) {
      return isArrayOfObjectsEmpty(visualization?.value as unknown as SingleValueVisualizationType['value']);
    }
    return false;
  }, [visualization, rawData]);

  // Update the workspace on chart save
  const saveVisualization = useCallback(
    (
      data: StoredVisualization,
      visualizationType: VisualizationTypes,
      colorScheme: ColorScheme | null = null,
      formatOptions: ItemFormatMap | null = null
    ) => {
      // TODO: why we need this?
      // Show animation on icon button click
      // handleIconButtonClick();

      // Process the chart save
      if (!workspace) {
        return;
      }
      // TODO: data structure should be changed since there is support for different visualization types. Can't rename
      // it for now since it will break the existing functionality

      const GRID_COLUMNS = 3;
      const charts = (workspace.content?.charts as WorkspaceItemData[]) ?? ([] as WorkspaceItemData[]);

      // Find the closest position to add the new chart.
      const closestPosition = calculateClosestPosition(charts, GRID_COLUMNS, 0, 0, 1, 2);

      // Search for greatest index.
      const greatestInd = charts.length
        ? charts.reduce((acc, curr) => {
            const match = curr.i.match(/n(\d+)-\d+/);
            const ind = match ? Number.parseInt(match[1] ?? '0') : 0;
            return ind > acc ? ind : acc;
          }, 0)
        : -1;

      const newItem = {
        // The i of the new item should be greater than the last item
        i: `n${greatestInd + 1}-${workspaceId}`,
        x: closestPosition?.x ?? 0,
        y: closestPosition?.y ?? 0,
        w: 1,
        h: [VisualizationTypes.CHART, VisualizationTypes.TABLE].includes(visualizationType) ? 2 : 1,
        chartData: data,
        visualizationType,
        query: question,
        colorScheme,
        formatOptions,
      } as WorkspaceItemData;

      // Pushes older to the bottom, left. Adds new to the top, left
      const newCharts = [{ ...newItem }, ...charts];

      updateWorkspace({ workspaceId: workspace.id, payload: { content: { charts: newCharts } } });

      // Close the modal after playing animations and saving the chart
      setTimeout(onClose, 500);
    },
    [workspace, question, updateWorkspace, calculateClosestPosition, onClose]
  );

  return (
    <BaseModal
      open={isOpen}
      onClose={onClose}
      closeIcon
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      ModalContentProps={{
        sx: {
          width: queryResponse ? '100%' : '70vw',
          height: 'fit-content',
          maxHeight: '100%',
          padding: 0,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          padding: '32px 0',
          height: '100%',
          width: '100%',
        }}
      >
        {!isWorkspaceLoading && (
          <QueryForm
            onQueryExecute={(query, response) => {
              setQuestion(query);
              setQueryResponse(response);
            }}
          />
        )}

        {queryResponse && (
          <Box display={'flex'} justifyContent={'center'} sx={{ pt: isQueryVisible ? '32px' : '12px' }}>
            <Button variant="text" color={'secondary'} onClick={() => setIsQueryVisible(!isQueryVisible)}>
              {isQueryVisible ? 'HIDE THE WORKINGS...' : 'DISPLAY THE WORKINGS...'}
            </Button>
          </Box>
        )}

        {isQueryVisible && (
          <Box sx={{ padding: '15px 32px 0' }}>
            <Typography
              border={'1px solid #ddd'}
              borderRadius={'4px'}
              padding={'20px'}
              whiteSpace={'pre-wrap'}
              sx={{ wordWrap: 'break-word', backgroundColor: '#f5f5f5' }}
            >
              {queryResponse?.query}
            </Typography>
          </Box>
        )}

        {visualization &&
          question &&
          !isResultEmpty &&
          [VisualizationTypes.CHART, VisualizationTypes.TABLE].includes(visualization.type) && (
            <ChartVisualization
              question={question}
              visualization={visualization}
              rawData={rawData}
              isOwner={isOwner}
              onVisualizationSave={saveVisualization}
            />
          )}

        {visualization &&
          question &&
          !isResultEmpty &&
          [VisualizationTypes.NUMBER, VisualizationTypes.STRING, VisualizationTypes.DATE].includes(
            visualization.type
          ) && (
            <SingleValueVisualization
              question={question}
              visualization={visualization as unknown as SingleValueVisualizationType}
              isOwner={isOwner}
              onVisualizationSave={saveVisualization}
            />
          )}

        {question && isResultEmpty && <Alert title={NO_RESULTS_MESSAGE} />}
      </Box>
    </BaseModal>
  );
};
