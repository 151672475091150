import { FC } from 'react';
import { Box, BoxProps, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export interface ModalDrawerProps extends BoxProps {
  /**
   * True if the drawer is open.
   */
  open: boolean;
  /**
   * Callback fired when the drawer is closed.
   */
  onClose: VoidFunction;
  /**
   * Content of the drawer.
   */
  children?: React.ReactNode | React.ReactNode[];
}

const StyledContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 320,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
  zIndex: theme.zIndex.drawer,
}));

/**
 * Drawer component for usage in modals.
 * Since some limitations of the MUI drawer, we need to use a custom component.
 */
const ModalDrawer: FC<ModalDrawerProps> = ({ open, onClose, children, ...props }) => {
  if (!open) {
    return null;
  }

  return (
    <StyledContainer {...props}>
      <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
        <Close />
      </IconButton>
      {children}
    </StyledContainer>
  );
};

export default ModalDrawer;
