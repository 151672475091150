import { List, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ChartColorScheme, ChartPalette } from '@modules/query/types';
import ModalDrawer, { ModalDrawerProps } from '@shared/components/ModalDrawer.tsx';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';

interface ChartStyleDrawerProps extends ModalDrawerProps {
  colorScheme?: ChartColorScheme;
  onColorChange: (scheme: ChartColorScheme) => void;
}

interface ChartPaletteOption {
  name: string;
  value: ChartPalette;
  color: string;
}

const ChartPaletteOptions: ChartPaletteOption[] = [
  {
    name: 'Palette 1',
    value: ChartPalette.Palette1,
    color: '#008FFB',
  },
  {
    name: 'Palette 2',
    value: ChartPalette.Palette2,
    color: '#3f51b5',
  },
  {
    name: 'Palette 3',
    value: ChartPalette.Palette3,
    color: '#33b2df',
  },
  {
    name: 'Palette 4',
    value: ChartPalette.Palette4,
    color: '#4ecdc4',
  },
  {
    name: 'Palette 5',
    value: ChartPalette.Palette5,
    color: '#2b908f',
  },
  {
    name: 'Palette 6',
    value: ChartPalette.Palette6,
    color: '#449DD1',
  },
  {
    name: 'Palette 7',
    value: ChartPalette.Palette7,
    color: '#D7263D',
  },
  {
    name: 'Palette 8',
    value: ChartPalette.Palette8,
    color: '#662E9B',
  },
  {
    name: 'Palette 9',
    value: ChartPalette.Palette9,
    color: '#5C4742',
  },
  {
    name: 'Palette 10',
    value: ChartPalette.Palette10,
    color: '#A300D6',
  },
];

export const ChartStyleDrawer = ({
  onColorChange,
  colorScheme,
  ...drawerProps
}: ChartStyleDrawerProps & ModalDrawerProps) => {
  const currentPaletteOption = colorScheme?.palette ?? ChartPalette.Palette1;

  return (
    <ModalDrawer
      {...drawerProps}
      sx={{
        right: 0,
        zIndex: 9999999,
        ...drawerProps?.sx,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          padding: (theme) => theme.spacing(0.5, 0, 2),
        }}
      >
        Chart Theme
      </Typography>

      <List>
        {ChartPaletteOptions.map((paletteOption) => (
          <ListItemButton
            key={paletteOption.value}
            onClick={() => onColorChange({ palette: paletteOption.value })}
            selected={paletteOption.value === currentPaletteOption}
            sx={{
              borderRadius: '4px',
              marginBottom: 1,
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                backgroundColor: paletteOption.color,
                borderRadius: '50%',
                marginRight: 2,
                '&:hover': {
                  backgroundColor: paletteOption.color,
                },
              }}
            />
            <ListItemText primary={paletteOption.name} />
          </ListItemButton>
        ))}
      </List>
    </ModalDrawer>
  );
};
