import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocalStorage } from 'usehooks-ts';

import {
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  useTheme,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  Typography,
  Stack,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ROUTES } from '@shared/constants.ts';
import AnimateButton from '@themes/components/extended/AnimateButton';
import { GoogleSignIn } from '@/modules/auth/forms/GoogleSignIn';
import { Login } from '@/modules/auth/types.ts';
import { useLogInUserMutation, useCurrentUserQuery } from '@/modules/auth/queries.ts';
import { useGetWorkspaceListQuery } from '@/modules/workspaces/queries.ts';
import toast from 'react-hot-toast';

import { CURRENT_WORKSPACE_ID_KEY } from '@/modules/workspaces/constants';

/**
 * Represents a login form component.
 *
 * @component
 * @returns {React.ReactNode} The login form component.
 */
const LoginForm: React.FC = (): React.ReactNode => {
  const theme = useTheme();
  const navigate = useNavigate();
  // Local storage
  const [, setKeyInLocalStorage] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);

  // change password input type
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const { mutateAsync } = useLogInUserMutation();
  // TODO: Probably it's worth it to store the user data in the local storage
  const { status: currentUserLoadingStatus } = useCurrentUserQuery(isLoggedIn);
  // Load user workspaces
  const { data: workspaces, isFetched: isWorkspacesFetched } = useGetWorkspaceListQuery(1, 50, !isLoggedIn);

  /**
   * Handles the submit action for login form.
   *
   * @async
   * @param {Login} values - The login form values.
   */
  const handleSubmit = async (values: Login) => {
    try {
      await mutateAsync(values);
      if (values) {
        // Set to true to enable the useCurrentUserQuery hook
        setIsLoggedIn(true);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.detail || 'Error while logging in';
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (currentUserLoadingStatus === 'success' && isWorkspacesFetched) {
      if (workspaces && workspaces.items.length > 0) {
        // Set the first workspace as the default workspace
        const workspaceId = workspaces.items[0].id;
        setKeyInLocalStorage(workspaceId);
      }
      navigate(ROUTES.DASHBOARD);
    }
  }, [currentUserLoadingStatus, isWorkspacesFetched]);

  return (
    <>
      <Grid item xs={12}>
        <Stack alignItems="center" justifyContent="center" spacing={1}>
          <Typography color={theme.palette.secondary.main} gutterBottom variant={'h1'}>
            xAnalytica Interface
          </Typography>
          <Typography variant="caption" fontSize="16px" textAlign={'center'}>
            Enter your credentials to continue
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            email: '',
            password: '',
            remember_me: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            password: Yup.string().max(255).required('Password is required'),
          })}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="login-email">Email Address</InputLabel>
                <OutlinedInput
                  id="login-email"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="login-password">Password</InputLabel>
                <OutlinedInput
                  id="login-password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                      >
                        {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                  label="Password"
                />
                {touched.password && errors.password && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="remember_me"
                        color="primary"
                        checked={values.remember_me}
                        onChange={handleChange}
                      />
                    }
                    label="Keep me logged in"
                  />
                </Grid>
                {/*<Grid item>*/}
                {/*  <Typography*/}
                {/*    variant="subtitle1"*/}
                {/*    color="secondary"*/}
                {/*    sx={{ textDecoration: 'none', cursor: 'pointer' }}*/}
                {/*    onClick={() => navigate(ROUTES.AUTH.RESET_PASSWORD)}*/}
                {/*  >*/}
                {/*    Forgot Password?*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}
              </Grid>

              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onSubmit={(e: any) => handleSubmit(e)}
                  >
                    Log In
                  </Button>
                </AnimateButton>
              </Box>
              <Box sx={{ mt: 2 }}>
                <GoogleSignIn />
              </Box>
              {/*<Grid item xs={12} sx={{ marginTop: 2 }}>*/}
              {/*  <Grid item container direction="column" alignItems="center" xs={12}>*/}
              {/*    <Typography variant="subtitle1" color="secondary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>*/}
              {/*      Don&apos;t have an account?*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default LoginForm;
