import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { User } from '@/modules/users/types';
import { ShareWorkspaceRequest } from '@/modules/workspaces/types';
import { useUsersListQuery } from '@/modules/users/queries.ts';
import {
  useGetWorkspaceQuery,
  useGetWorkspaceRecipientsQuery,
  useShareWorkspaceMutation,
  useUnshareWorkspaceMutation,
} from '@/modules/workspaces/queries';
import { CURRENT_WORKSPACE_ID_KEY, DEFAULT_WORKSPACE_NAME } from '@/modules/workspaces/constants';
import { useLocalStorage } from 'usehooks-ts';
import { queryClient } from '@/api/queryClient';
import { CURRENT_USER_QUERY_KEY } from '@/modules/auth/queries';
import toast from 'react-hot-toast';

// material-ui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  createFilterOptions,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from '@themes/components/cards/MainCard';
// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// assets
import UserDefaultImage from '@assets/images/users/user-round.svg';
import CloseButton from '@shared/components/CloseButton.tsx';

// Filter options for the autocomplete component.
const filterOptions = createFilterOptions<User>({
  stringify: (option) => option.name + option.email,
});

export interface ShareWorkspaceModalProps {
  isOpen: boolean;
  handleModalClose: () => void;
}

export const ShareWorkspaceModal = ({ isOpen, handleModalClose }: ShareWorkspaceModalProps) => {
  const theme = useTheme();
  // Define the page and page size.
  const page = 1;
  const pageSize = 100;

  // Get the current user.
  const currentUser = queryClient.getQueryData<User>([CURRENT_USER_QUERY_KEY]);
  // Get the current workspace id.
  const [workspaceId] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);
  // Workspace name to use in the modal title.
  const [workspaceName, setWorkspaceName] = useState('');
  // Initialize workspace data.
  const { data: workspace } = useGetWorkspaceQuery(workspaceId);
  // Get the available list of users for sharing with.
  const { data: users } = useUsersListQuery({ page, size: pageSize });
  // Get the list of users who have access to the workspace.
  const { data: recipients } = useGetWorkspaceRecipientsQuery(workspaceId);

  // Initial (static) state for the users list.
  const [usersList, setUsersList] = useState<User[]>([]);
  // Share workspace request data.
  const [shareWorkspaceRecipients, setShareWorkspaceRecipients] = useState<ShareWorkspaceRequest[] | undefined>([]);
  const { mutateAsync: shareWorkspace, isPending } = useShareWorkspaceMutation();

  // Initialize the state when the users and current user data is available.
  useEffect(() => {
    if (users && currentUser) {
      // Filter out the current user from the users list
      const state = users.items.filter((user) => {
        return user.id !== currentUser.id;
      });
      setUsersList(state);
    }
  }, [users, currentUser]);

  // When the workspace data is available, set the workspace name.
  useEffect(() => {
    if (typeof workspace !== 'undefined' && !!workspace.name) {
      const workspaceName = workspace.name;
      setWorkspaceName(workspaceName);
    } else {
      setWorkspaceName(DEFAULT_WORKSPACE_NAME);
    }
  }, [workspace]);

  // Close handler.
  const onClose = () => {
    handleModalClose();
  };

  // Share workspace handler.
  const handleShareWorkspace = async () => {
    if (shareWorkspaceRecipients) {
      await shareWorkspace({ workspaceId, payload: shareWorkspaceRecipients });
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, 0)',
          minWidth: '40vw',
          maxWidth: '40vw',
          [theme.breakpoints.down('md')]: {
            minWidth: '90vw',
            maxWidth: '90vw',
          },
          [theme.breakpoints.down('lg')]: {
            minWidth: '60vw',
            maxWidth: '60vw',
          },
          [theme.breakpoints.down('xl')]: {
            minWidth: '50vw',
            maxWidth: '50vw',
          },
          borderRadius: '12px',
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <CloseButton onClick={onClose} />
          <MainCard content={false} title={<Typography variant="h4">Share {`"${workspaceName}"`}</Typography>}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '400px',
                maxHeight: '35vh',
                height: '100%',
                justifyContent: 'space-between',
                padding: 3,
                gap: 2,
              }}
            >
              {usersList && <ShareSuggestion users={usersList} setRecipients={setShareWorkspaceRecipients} />}
              <Box sx={{ alignSelf: 'flex-start', flexGrow: 2, width: '100%', overflowY: 'auto' }}>
                <Typography variant="h5">People with access</Typography>
                {currentUser && <ShareSuggestionItem user={currentUser} isMe />}
                {recipients &&
                  recipients.items.map((recipient) => (
                    <ShareSuggestionItem key={recipient.id} user={recipient} workspaceId={workspaceId} />
                  ))}
              </Box>
              <Box>
                <Button
                  disabled={isPending}
                  sx={{ maxWidth: 'max-content' }}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={handleShareWorkspace}
                >
                  Share
                </Button>
              </Box>
            </Box>
          </MainCard>
        </Box>
      </Box>
    </Modal>
  );
};

/**
 * Share suggestion component.
 */
const ShareSuggestion = ({
  users,
  setRecipients,
}: {
  users: User[];
  setRecipients: Dispatch<SetStateAction<ShareWorkspaceRequest[] | undefined>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Autocomplete
      sx={{ width: '100%' }}
      multiple
      id="tags-outlined"
      options={users}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      renderOption={({ key: _, ...rest }, option) => <ShareSuggestionItem key={option.id} user={option} {...rest} />}
      renderInput={(params) => <TextField {...params} placeholder="Search people by name or email" />}
      onChange={(_, value) => setRecipients(value.map((user) => ({ user_id: user.id })))}
      filterOptions={filterOptions}
      onInputChange={(_, value) => {
        setIsOpen(value.length > 0);
      }}
      open={isOpen}
    />
  );
};

/**
 * Share suggestion item component.
 */
const ShareSuggestionItem = ({
  user,
  isMe,
  workspaceId,
  ...options
}: {
  user: User;
  isMe?: boolean;
  workspaceId?: number;
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { mutateAsync: unshareWorkspace } = useUnshareWorkspaceMutation();

  // Click handlers for the menu.
  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // Close handler for the menu.
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUnshareWorkspace = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (typeof workspaceId === 'undefined') {
      toast.error('Workspace id is not defined');
      handleClose();
      return;
    }
    await unshareWorkspace({ workspaceId, payload: { user_id: user.id } });
    handleClose();
  };

  return (
    <ListItemButton {...options}>
      <ListItem disablePadding>
        <ListItemAvatar>
          <Avatar alt={`${user.name}'s image`} src={UserDefaultImage} />
        </ListItemAvatar>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={9}>
            <ListItemText primary={isMe ? user.name + ' (you)' : user.name} secondary={user.email} />
          </Grid>
          <Grid item xs={3} alignSelf="end">
            <ListItemText
              primary={
                isMe ? (
                  <Box display="flex" width="110px" justifyContent="flex-end">
                    <Button variant="text" disabled>
                      Owner
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Button variant="outlined" endIcon={<ArrowDropDownIcon />} onMouseDown={handleClick}>
                      Viewer
                    </Button>
                    <Menu
                      id="share-options-menu"
                      keepMounted
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleUnshareWorkspace}>Remove</MenuItem>
                    </Menu>
                  </>
                )
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    </ListItemButton>
  );
};
