import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// third-party
import * as yup from 'yup';
import { useFormik } from 'formik';

// material-ui
import { Typography, Box, Select, FormControl, InputLabel, Button, MenuItem, TextField, Stack } from '@mui/material';
import Loader from '@/themes/components/Loader.tsx';

// project imports
import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout.tsx';
import {
  CREATED_DATA_SOURCE_MUTATION_KEY,
  useFinalizeDataSourceMutation,
  useBigQueryDatasetsQuery,
  useDeleteConnectorMutation,
} from '@/modules/datasources/queries';
import { queryClient } from '@/api/queryClient';
import { Connector, CreateDataSourceResponse } from '@/modules/datasources/types';
import MainCard from '@/themes/components/cards/MainCard';
import { ROUTES, BIGQUERY_CONNECT_TITLE } from '@shared/constants.ts';
import BigQueryConnect from '@modules/datasources/components/BigQueryConnect.tsx';
import toast from 'react-hot-toast';
import ConfirmationModal from '@/shared/components/ConfirmationModal';

const validationSchema = yup.object({
  config: yup.object({
    project_id: yup.string().required('Please select project'),
    dataset_id: yup.string().required('Please select dataset'),
  }),
});

const initialValues = {
  id: 0,
  label: '',
  config: {
    project_id: '',
    dataset_id: '',
  },
};

/**
 * Page for connecting a data source.
 * @returns The DataCourseConnectPage component.
 */
const DataSourceConnectPage = () => {
  const navigate = useNavigate();
  const [connectorToDelete, setConnectorToDelete] = useState<Connector | null>(null);
  const [connectorToUpdate, setConnectorToUpdate] = useState<number | null>(null);
  const [updateCallback, setUpdateCallback] = useState<VoidFunction | null>(null);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const createdDataSource = queryClient.getQueryData<CreateDataSourceResponse>([CREATED_DATA_SOURCE_MUTATION_KEY]);

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema,
    onSubmit: async (values) => {
      await finalizeDataSource({ ...values, datasource_type: 'Google BigQuery' });
    },
  });

  const { mutateAsync: deleteConnector } = useDeleteConnectorMutation();

  const { mutateAsync: finalizeDataSource, isPending: isFinalizeDataSourcePending } = useFinalizeDataSourceMutation({
    setErrors: formik.setErrors,
  });

  const onDeleteConnector = async () => {
    try {
      if (!connectorToDelete) return;
      await deleteConnector(connectorToDelete.id);
      toast.success('Connector removed successfully');
    } finally {
      setConnectorToDelete(null);
    }
  };

  const updateConnector = () => {
    if (!connectorToUpdate) return;
    if (!updateCallback) return;
    updateCallback();
  };

  // Set the data source ID after it is created.
  useEffect(() => {
    if (createdDataSource) {
      formik.setFieldValue('id', createdDataSource.id);
    }
  }, [createdDataSource]);

  // Fetch the datasets for the selected project.
  const { data: datasets, isFetching: isDatasetLoading } = useBigQueryDatasetsQuery(
    formik.values.id,
    formik.values.config.project_id,
    !!createdDataSource && !!formik.values.id
  );

  // Remove the query when the component is unmounted.
  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: [CREATED_DATA_SOURCE_MUTATION_KEY] });
    };
  }, []);

  if (!createdDataSource) {
    return (
      <AuthorizedPageLayout title={BIGQUERY_CONNECT_TITLE}>
        <>
          <BigQueryConnect
            onDeleteConnector={(connector) => setConnectorToDelete(connector)}
            onUpdateConnector={(connectorId, onRefreshConnectorAuthConfig) => {
              setConnectorToUpdate(connectorId);
              setUpdateCallback(() => onRefreshConnectorAuthConfig);
              setShowUpdateModal(true);
            }}
            selectedConnectorId={connectorToUpdate}
          />
          <>
            <ConfirmationModal
              open={!!connectorToDelete}
              text={
                'When you delete a connector, all data sources associated with it ' +
                'will also be deleted. Do you want to remove the connector?'
              }
              onClose={() => {
                setConnectorToDelete(null);
              }}
              onConfirm={onDeleteConnector}
            />
          </>
          <ConfirmationModal
            open={!!connectorToUpdate && showUpdateModal}
            text={
              'Looks like the authentication for this connector has expired. ' +
              'Please re-authenticate to continue using this connector.'
            }
            onClose={() => {
              setConnectorToUpdate(null);
              setUpdateCallback(null);
            }}
            onConfirm={() => {
              updateConnector();
              setShowUpdateModal(false);
            }}
          />
        </>
      </AuthorizedPageLayout>
    );
  }

  return (
    <AuthorizedPageLayout title={BIGQUERY_CONNECT_TITLE}>
      <MainCard
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '24px',
        }}
      >
        {isDatasetLoading && <Loader />}
        <Typography variant="h3">Configure your BigQuery Source</Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
            <TextField
              name="label"
              sx={{ marginBottom: '12px' }}
              value={formik.values.label}
              onChange={formik.handleChange}
              label="Data Source Label"
            />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
              <FormControl fullWidth sx={{ marginRight: '12px' }}>
                <InputLabel htmlFor="project-label">Project</InputLabel>
                <Select
                  id="project-label"
                  name="config.project_id"
                  fullWidth
                  label="Project"
                  value={formik.values.config.project_id}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue('config.dataset_id', '');
                  }}
                >
                  {createdDataSource.projects.map(({ id }) => (
                    <MenuItem key={id} value={id}>
                      {id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="dataset-label">Dataset</InputLabel>
                <Select
                  id="dataset-label"
                  name="config.dataset_id"
                  disabled={isDatasetLoading}
                  onChange={formik.handleChange}
                  fullWidth
                  label="dataset"
                >
                  {datasets?.map(({ id }) => (
                    <MenuItem key={id} value={id}>
                      {id}
                    </MenuItem>
                  )) || <MenuItem disabled>Select project first</MenuItem>}
                </Select>
              </FormControl>
            </Box>
            <Stack direction="row" spacing={3}>
              <Button
                disabled={isFinalizeDataSourcePending || !formik.values.config.dataset_id || datasets?.length === 0}
                sx={{ maxWidth: 'max-content' }}
                type="submit"
                variant="contained"
                onClick={formik.submitForm}
              >
                TEST & CONTINUE
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={() => navigate(ROUTES.DATA_SOURCES.TYPE)}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </MainCard>
    </AuthorizedPageLayout>
  );
};

export default DataSourceConnectPage;
