import { SingleValueVisualizationType } from '@modules/query/types.ts';
import { find, keys } from 'lodash';
import { CellFormat, formatCellValue, isDate, isNumber } from '@shared/helpers/formatCellValue.ts';
import moment from 'moment';

export const getSingleVisualizationFormat = (
  value: SingleValueVisualizationType['value'],
  numberFormat: CellFormat,
  dateFormat: CellFormat
) => {
  let hasDate = false;
  let hasNumber = false;
  let result = '';

  const formattedVisualizationValue = value.map((item) => {
    const itemMonthKeyMatch = hasPartialKey(item, 'month');
    const itemYearKeyMatch = hasPartialKey(item, 'year');
    const hasMonth =
      !!itemMonthKeyMatch && (item[itemMonthKeyMatch] as number) >= 1 && (item[itemMonthKeyMatch] as number) <= 12;
    const hasYear = !!itemYearKeyMatch && isNumber(item[itemYearKeyMatch]);

    for (const key in item) {
      if (typeof item[key] === 'undefined' || item[key] === null) {
        continue;
      }

      const monthKeyMatch = getPartialMatch(key, 'month');
      const yearKeyMatch = getPartialMatch(key, 'year');
      // Process 'month' and 'year' as a single date column if both are present
      if (hasMonth && hasYear && (!!monthKeyMatch || !!yearKeyMatch)) {
        if (key === monthKeyMatch) {
          const month = parseInt((item[monthKeyMatch] as number)?.toString(), 10);
          const year = parseInt((item[yearKeyMatch] as number)?.toString(), 10);
          const date = moment()
            .year(year)
            .month(month - 1); // Create moment date
          result += formatCellValue(date.toDate(), dateFormat) + '\n';
          hasDate = true;
        }
        continue; // Skip further processing of 'month' and 'year' individually
      }

      // Convert 'month' to string if 'year' is not present
      if (key === monthKeyMatch && hasMonth && !hasYear) {
        const monthName = moment()
          .month((item[key] as number) - 1)
          .format('MMMM'); // Convert month to name
        result += monthName + '\n';
        continue;
      }

      // Check if value is a date string
      if (typeof item[key] === 'string' && isDate(item[key])) {
        result += formatCellValue(item[key], dateFormat) + '\n';
        hasDate = true;
        continue;
      }

      // Check if value is a number
      if (isNumber(item[key])) {
        result += formatCellValue(item[key], numberFormat) + '\n';
        hasNumber = true;
        continue;
      }

      // Default case: Append the value as is
      result += item[key] + '\n';
    }

    return result.trim(); // Trim extra space at the end
  });

  return {
    value: formattedVisualizationValue.join('\n').trim(),
    hasDate,
    hasNumber,
  };
};

const hasPartialKey = (obj, partialKey) => {
  return find(keys(obj), (key) => key.toLowerCase().includes(partialKey.toLowerCase()));
};

const getPartialMatch = (str, partial) => {
  const lowerStr = str.toLowerCase();
  const lowerPartial = partial.toLowerCase();

  return lowerStr.includes(lowerPartial) ? str : null;
};
