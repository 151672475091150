import { WorkspaceItemData } from '@/modules/workspaces/types';
import { Box, IconButton, keyframes, Paper, Tooltip } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ChartColorScheme, TableColorScheme, VisualizationTypes } from '@/modules/query/types.ts';
import { ChartItemContent } from '@/modules/workspaces/components/ChartitemContent.tsx';
import { SingleItemContent } from '@/modules/workspaces/components/SingleItemContent.tsx';
import { TableItemContent } from '@/modules/workspaces/components/TableItemContent.tsx';
import { WORKSPACE_ITEM_PADDING } from '@/modules/workspaces/constants.ts';

interface WorkspaceItemProps {
  style?: React.CSSProperties;
  isEdit: boolean;
  data: WorkspaceItemData;
  canDelete?: boolean;
  handleItemRemove: (chartId: string) => void;
}

export const WorkspaceItem = ({ style, isEdit, data, canDelete, handleItemRemove }: WorkspaceItemProps) => {
  const existingTransform = style?.transform || '';
  const shake = keyframes`
    0% {
      transform: ${existingTransform} rotate(0deg);
    }
    25% {
      transform: ${existingTransform} rotate(-1deg);
    }
    75% {
      transform: ${existingTransform} rotate(1deg);
    }
    100% {
      transform: ${existingTransform} rotate(0deg);
    }
  `;

  return (
    <Paper
      style={{ ...style }}
      sx={{
        width: '100%',
        height: '100%',
        padding: WORKSPACE_ITEM_PADDING,
        ...(isEdit && {
          cursor: 'move',
          animation: `${shake} 0.35s infinite`,
          position: 'relative',
          boxShadow: '-4px 4px 15px rgba(0, 0, 0, 0.5)',
        }),
        '& :hover': {
          animation: 'none',
        },
      }}
    >
      <Box alignSelf="end" sx={{ position: 'absolute', top: -10, right: -10, zIndex: 9999 }}>
        {canDelete && (
          <Tooltip
            className="hoverIcon CancelDragOnDelete"
            title="Delete"
            sx={{
              width: 30,
              height: 30,
              backgroundColor: 'white',
              opacity: 1,
              '&:hover': {
                backgroundColor: 'white',
                opacity: 1,
                boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.2)',
                transition: '0.3s ease all',
              },
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleItemRemove(data.i);
              }}
            >
              <HighlightOffIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {typeof data.chartData === 'object' && 'chart' in data.chartData && (
        <ChartItemContent
          chartData={data.chartData}
          query={data.query}
          colorScheme={data?.colorScheme as ChartColorScheme}
          formatOptions={data?.formatOptions}
        />
      )}

      {(data.visualizationType === VisualizationTypes.STRING ||
        data.visualizationType === VisualizationTypes.NUMBER) && <SingleItemContent el={data} />}

      {data.visualizationType === VisualizationTypes.TABLE && (
        <TableItemContent
          rawData={data.chartData}
          query={data.query}
          colorScheme={data?.colorScheme as TableColorScheme}
        />
      )}
    </Paper>
  );
};
