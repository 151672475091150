// TODO: Change to dict
/**
 * Constants for user feature
 */
export const TIMEZONES = [
  'UTC-12:00 (Baker Island Time)',
  'UTC-11:00 (Niue Time, Samoa Standard Time)',
  'UTC-10:00 (Hawaii-Aleutian Standard Time, Cook Island Time)',
  'UTC-09:00 (Alaska Standard Time, Gambier Island Time)',
  'UTC-08:00 (Pacific Standard Time, Clipperton Island Time)',
  'UTC-07:00 (Mountain Standard Time)',
  'UTC-06:00 (Central Standard Time)',
  'UTC-05:00 (Eastern Standard Time, Western Caribbean Time)',
  'UTC-04:00 (Atlantic Standard Time, Eastern Caribbean Time)',
  'UTC-03:30 (Newfoundland Standard Time)',
  'UTC-03:00 (Amazon Standard Time, Central Greenland Time)',
  'UTC-02:00 (Fernando de Noronha Time, South Georgia Time)',
  'UTC-01:00 (Azores Standard Time, Cape Verde Time)',
  'UTC+00:00 (Greenwich Mean Time, Western European Time)',
  'UTC+01:00 (Central European Time, West Africa Time)',
  'UTC+02:00 (Eastern European Time, Central Africa Time)',
  'UTC+03:00 (Moscow Standard Time, East Africa Time)',
  'UTC+03:30 (Iran Standard Time)',
  'UTC+04:00 (Gulf Standard Time, Samara Standard Time)',
  'UTC+04:30 (Afghanistan Time)',
  'UTC+05:00 (Pakistan Standard Time, Yekaterinburg Standard Time)',
  'UTC+05:30 (Indian Standard Time, Sri Lanka Time)',
  'UTC+05:45 (Nepal Time)',
  'UTC+06:00 (Bangladesh Standard Time, Bhutan Time)',
  'UTC+06:30 (Cocos Islands Time, Myanmar Time)',
  'UTC+07:00 (Indochina Time, Novosibirsk Standard Time)',
  'UTC+08:00 (China Standard Time, Singapore Time)',
  'UTC+08:45 (Southeastern Western Australia Standard Time)',
  'UTC+09:00 (Japan Standard Time, Korea Standard Time)',
  'UTC+09:30 (Australian Central Standard Time)',
  'UTC+10:00 (Australian Eastern Standard Time, Vladivostok Standard Time)',
  'UTC+10:30 (Lord Howe Standard Time)',
  'UTC+11:00 (Solomon Island Time, Vladivostok Standard Time)',
  'UTC+11:30 (Norfolk Island Time)',
  'UTC+12:00 (Fiji Time, Kamchatka Standard Time)',
  'UTC+12:45 (Chatham Islands Time)',
  'UTC+13:00 (Tonga Time, Phoenix Islands Time)',
  'UTC+14:00 (Line Islands Time)',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MENUPROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};