import { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ColorPalette } from '@shared/components/ColorPalette';
import CircleIcon from '@mui/icons-material/Circle';
import { RgbaColor } from 'react-colorful';
import { formatRgbaToString } from '@shared/helpers/colorFormatters';

interface ColorSelectProps {
  selectedColor: RgbaColor;
  onChange: (color: RgbaColor) => void;
}

export const ColorSelect: FC<ColorSelectProps> = ({ selectedColor, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        // onChange={handleChange}
        onClick={() => setIsOpen(!isOpen)}
        startIcon={
          <CircleIcon
            sx={{
              color: formatRgbaToString(selectedColor),
              width: 27,
              height: 27,
              padding: 0,
              margin: 0,
              outline: 'none',
              border: '1px solid #ccc',
              borderRadius: '50%',
            }}
          />
        }
        endIcon={
          <ArrowDropDownIcon
            sx={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
              color: 'black',
            }}
          />
        }
        sx={{
          width: '100%',
          height: 50,
          border: '1px solid #ccc',
          backgroundColor: 'white',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            padding: 0,
          },
        }}
      />
      {isOpen && (
        <ColorPalette
          onChange={(color) => {
            onChange(color);
            setIsOpen(false);
          }}
        />
      )}
    </Box>
  );
};
