import { queryClient } from '@/api/queryClient';
import { CURRENT_USER_QUERY_KEY } from '@/modules/auth/queries';
import { User, UserTypesEnum } from '@/modules/users/types';
export const useAdminAccess = () => {
  const currentUser = queryClient.getQueryData<User>([CURRENT_USER_QUERY_KEY]);

  const hasAccess = currentUser?.acls.find((acl) => acl.permission.name === UserTypesEnum.ADMIN) !== undefined;

  return hasAccess;
};
