import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DataSourceStatusEnum } from '@modules/datasources/types.ts';
import { useFormik } from 'formik';
import { ExecuteQueryResponse, ModelTypesEnum } from '@modules/query/types.ts';
import * as yup from 'yup';
import { useExecuteQueryMutation } from '@modules/query/queries.ts';
import { FC, useEffect } from 'react';
import { useGetDataSourcesQuery } from '@modules/datasources/queries.ts';
import { queryClient } from '@api/queryClient.ts';
import { User } from '@modules/users/types.ts';
import { CURRENT_USER_QUERY_KEY } from '@modules/auth/queries.ts';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface StyledFormProps {
  isQueryExecuted: boolean;
}
const StyledForm = styled('form', { shouldForwardProp: (prop) => prop !== 'isQueryExecuted' })<StyledFormProps>(
  ({ isQueryExecuted }) => ({
    borderRadius: '12px',
    display: 'flex',
    gap: '24px',
    width: '100%',
    padding: '32px',
    paddingTop: '8px',
    paddingBottom: isQueryExecuted ? '44px' : '12px',
    borderBottom: isQueryExecuted ? '1px solid rgb(237, 231, 246)' : 'none',
  })
);

const validationSchema = yup.object({
  question: yup.string().required('Please select project'),
});

const initialValues = {
  question: '',
  datasourceId: 0,
};

interface QueryFormProps {
  onQueryExecute: (question: string, response: ExecuteQueryResponse) => void;
}

export const QueryForm: FC<QueryFormProps> = ({ onQueryExecute }) => {
  const currentUser = queryClient.getQueryData<User>([CURRENT_USER_QUERY_KEY]);
  const { data: dataSources, isFetching } = useGetDataSourcesQuery(currentUser?.id, currentUser?.tenant_id);

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const response = await executeQueryAsync({
        datasourceId: values.datasourceId,
        data: { query: values.question, model: ModelTypesEnum.OPENAI },
      });
      onQueryExecute(values.question, response);
      if (response?.query && !response?.visualization) {
        toast.error('Unable to suggest visualization for generated results');
      }
    },
  });

  const { data: queryResponse, mutateAsync: executeQueryAsync } = useExecuteQueryMutation({
    setErrors: formik.setErrors,
  });

  // Initialization
  useEffect(() => {
    if (dataSources && dataSources?.length > 0) {
      formik.setFieldValue(
        'datasourceId',
        dataSources.filter(({ status }) => status === DataSourceStatusEnum.ACTIVE)[0].id
      );
    }
  }, [dataSources, isFetching]);

  return (
    <StyledForm isQueryExecuted={!!queryResponse} onSubmit={(e) => e.preventDefault()}>
      {/* Container for the inputs */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: '24px' }}>
        {dataSources?.filter(({ status }) => status === DataSourceStatusEnum.ACTIVE).length === 1 ? null : (
          <FormControl fullWidth>
            <InputLabel htmlFor="datasource-label">Select Datasource</InputLabel>
            <Select
              id="datasource-label"
              name="datasourceId"
              disabled={isFetching}
              value={formik.values.datasourceId}
              onChange={formik.handleChange}
              label="Select Datasource"
            >
              {dataSources
                ?.filter(({ status }) => status === DataSourceStatusEnum.ACTIVE)
                .map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <TextField
          disabled={isFetching}
          name="question"
          value={formik.values.question}
          onChange={formik.handleChange}
          label="Ask a question"
          multiline
          maxRows={4}
          fullWidth
        />
      </Box>

      {/* Container for the button */}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <Button
          disabled={isFetching || !formik.isValid || formik.values.question?.length === 0}
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          onClick={async () => {
            await formik.submitForm();
          }}
        >
          Proceed
        </Button>
      </Box>
    </StyledForm>
  );
};
