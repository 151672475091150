import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { ROUTES } from '@shared/constants.ts';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';
import { queryClient } from '@/api/queryClient';
import { CURRENT_USER_QUERY_KEY } from '@/modules/auth/queries';

// material-ui
import { Avatar, Button, Grid, Typography } from '@mui/material';

// project imports
import SubCard from '@/themes/components/cards/SubCard';
import AnimateButton from '@themes/components/extended/AnimateButton.tsx';
import { gridSpacing } from '@themes/store/constant';

import CreateUserForm from '@/modules/users/components/forms/CreateUserForm.tsx';
import EditUserForm from '@/modules/users/components/forms/EditUserForm.tsx';

import { UserProfileActionsEnum, User as UserType } from '@modules/users/types.ts';
import { useUserQuery } from '@/modules/users/queries.ts';

// assets
import User from '@assets/images/users/user-round.svg';
import EditIcon from '@mui/icons-material/Edit';

interface UserProfileTabProps {
  mode: UserProfileActionsEnum;
}

const UserProfileTab = ({ mode }: UserProfileTabProps) => {
  const navigate = useNavigate();
  const hasAccess = usePowerUserAccess();
  const currentUser = queryClient.getQueryData<UserType>([CURRENT_USER_QUERY_KEY]);
  const { id: userId } = mode !== UserProfileActionsEnum.CREATE ? useParams() : { id: '' };
  const isSelf = currentUser?.id === Number(userId);
  const canEdit = isSelf || hasAccess;
  const editUri = generatePath(ROUTES.USERS.EDIT, { id: userId });

  let user: UserType | undefined = undefined;

  if (mode !== UserProfileActionsEnum.CREATE) {
    const { data: userQueryData } = useUserQuery(userId ?? '');
    if (userQueryData) {
      user = userQueryData;
    }
  }

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={6} md={4}>
        <SubCard title="Profile Picture" contentSX={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Avatar alt="User 1" src={User} sx={{ width: 100, height: 100, margin: '0 auto' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="center">
                {user?.name}
              </Typography>
            </Grid>
            {mode === UserProfileActionsEnum.EDIT && (
              <Grid item xs={12}>
                <AnimateButton>
                  <Button variant="contained" size="small" disabled>
                    Upload Avatar
                  </Button>
                </AnimateButton>
              </Grid>
            )}
          </Grid>
        </SubCard>
      </Grid>

      <Grid item xs={8}>
        {mode === UserProfileActionsEnum.EDIT && canEdit && (
          <SubCard title="Edit Details">
            <EditUserForm />
          </SubCard>
        )}
        {mode === UserProfileActionsEnum.CREATE && hasAccess && (
          <SubCard title="User Details">
            <CreateUserForm />
          </SubCard>
        )}
        {mode === UserProfileActionsEnum.VIEW && (
          <SubCard
            title="User Details"
            secondary={
              <Button disabled={isSelf ? false : !hasAccess} onClick={() => navigate(editUri)}>
                <EditIcon aria-label="Edit Details" />
              </Button>
            }
          >
            <EditUserForm viewOnly />
          </SubCard>
        )}
      </Grid>
    </Grid>
  );
};

export default UserProfileTab;
