import { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WORKSPACE_ITEM_PADDING } from '@/modules/workspaces/constants.ts';

interface ChartPreviewModalProps {
  open: boolean;
  onClose: () => void;
  styleDrawer: JSX.Element;
  children: JSX.Element;
}

export const ChartPreviewModal: FC<ChartPreviewModalProps> = ({ open, onClose, children, styleDrawer }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        style: {
          height: '80vh',
          padding: WORKSPACE_ITEM_PADDING,
        },
      }}
    >
      {styleDrawer}
      <DialogTitle sx={{ padding: 0 }}>
        Chart Preview
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ height: '100%', padding: 0 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
