import { ChartType } from '@/modules/query/types';
import toast from 'react-hot-toast';

// Compatibility map based on data structure and visualization purpose
export const ChartCompatibilityMap: Record<ChartType, ChartType[]> = {
  // bar: ['line', 'area', 'scatter'],
  // line: ['bar', 'area', 'scatter'],
  // area: ['bar', 'line', 'scatter'],
  bar: ['line', 'area'],
  line: ['bar', 'area'],
  area: ['bar', 'line'],
  // scatter: ['bar', 'line', 'area'],
  pie: ['donut', 'radialBar', 'polarArea'],
  donut: ['pie', 'radialBar', 'polarArea'],
  radialBar: ['pie', 'donut', 'polarArea'],
  polarArea: ['pie', 'donut', 'radialBar'],

  // Specialized chart types
  heatmap: [],
  treemap: [],
  radar: [],
  bubble: [],
  candlestick: [],
  boxPlot: [],
  rangeBar: [],
  rangeArea: [],
};

// Function to suggest alternative charts
export function suggestAlternativeChart(chartType: ChartType): ChartType | undefined {
  const alternatives = ChartCompatibilityMap[chartType];

  if (!alternatives || alternatives.length === 0) {
    toast.error('No alternative charts found.');
    return undefined;
  }

  // Randomly select two unique chart types from alternatives
  return alternatives[Math.floor(Math.random() * alternatives.length)];
}
