import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout.tsx';
import { WorkspaceGrid } from '@/modules/workspaces/components/WorkspaceGrid';

// The default items for the grid. It initiates a single card with the add button

/**
 * The HomePage component.
 *
 * @returns The HomePage component.
 */
export const HomePage = () => {
  return (
    <AuthorizedPageLayout showHeaderEndAdornment>
      <WorkspaceGrid />
    </AuthorizedPageLayout>
  );
};

export default HomePage;
