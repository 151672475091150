import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  Connector,
  ConnectorsFilters,
  CreateDataSourceRequest,
  FinalizeDataSourceRequest,
  GetDatasetsResponse,
  GetDataSourcesDetailsResponse,
  UpdateConnectorRequest,
  UpdateConnectorResponse,
} from './types';
import {
  createDataSource,
  finalizeDataSource,
  getDataSources,
  deleteDataSource,
  getDataSourceDetails,
  getBigQueryDatasets,
  getConnectors,
  deleteConnector,
  updateConnector,
} from './api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared/constants';
import { queryClient } from '@/api/queryClient';
import { withErrorHandling } from '@/shared/helpers/withErrorHandling';
import { FormikErrors, FormikValues } from 'formik';

export const CREATED_DATA_SOURCE_MUTATION_KEY = 'xanalityca/created-data-source';
export const GET_DATA_SOURCES_QUERY_KEY = 'xanalityca/data-sources';
export const GET_DATA_SOURCE_QUERY_KEY = 'xanalityca/data-source';
export const GET_DATASETS_QUERY_KEY = 'xanalityca/datasets';
export const GET_CONNECTORS_QUERY_KEY = 'xanalityca/connectors';
/**
 * Custom hook for creating a new data source.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also sets the query data and navigates to the data sources connect page on success.
 * @returns The mutation object.
 */
export const useCreateDatasourceMutation = () => {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (payload: CreateDataSourceRequest) => {
      const response = await withErrorHandling(createDataSource(payload), {
        loading: 'Creating data source...',
        success: 'Data source created successfully.',
      });
      return response.data;
    },
    onSuccess: (response) => {
      queryClient.setQueryData([CREATED_DATA_SOURCE_MUTATION_KEY], response);
      navigate(ROUTES.DATA_SOURCES.CONNECT);
    },
  });

  return mutation;
};

/**
 * Custom hook for fetching the list of data sources.
 * It uses the `useQuery` hook from `react-query` to handle the query logic.
 * @param userId - Optional user ID to filter the data sources.
 * @param tenantId - Optional tenant ID to filter the data sources.
 * @returns The query object.
 */
export const useGetDataSourcesQuery = (userId?: number, tenantId?: number) => {
  return useQuery({
    queryKey: [GET_DATA_SOURCES_QUERY_KEY],
    queryFn: async () => {
      const response = await getDataSources(userId, tenantId);
      return response.data;
    },
  });
};

/**
 * Custom hook for finalizing a data source.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also navigates to the data sources list page on success.
 * @returns The mutation object.
 */
export const useFinalizeDataSourceMutation = ({
  setErrors,
}: {
  setErrors: (errors: FormikErrors<FormikValues>) => void;
}) => {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (payload: FinalizeDataSourceRequest) => {
      const response = await withErrorHandling(finalizeDataSource(payload), {
        loading: 'Finalizing data source...',
        success: 'Done. You will be redirected to the data sources list.',
        setErrors,
      });
      return response.data;
    },
    onSuccess: (response) => {
      if (response && response.id) {
        queryClient.setQueryData([GET_DATA_SOURCE_QUERY_KEY, response.id], (data: GetDataSourcesDetailsResponse) => ({
          ...data,
          ...response,
        }));
      }
      setTimeout(() => navigate(ROUTES.DATA_SOURCES.LIST), 1000);
    },
  });

  return mutation;
};

/**
 * Custom hook for deleting a datasource.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the datasources list query on success.
 * @returns The mutation object.
 */
export const useDeleteDatasourceMutation = () => {
  const mutation = useMutation<number, Error, number>({
    mutationFn: async (id) => {
      const response = await deleteDataSource(id);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_DATA_SOURCES_QUERY_KEY] });
    },
  });

  return mutation;
};

/**
 * Custom hook for fetching a datasource.
 * It uses the `useQuery` hook from `react-query` to handle the query logic.
 * @param id - The datasource ID.
 * @param disabled - The flag to disable the query.
 * @returns The query object.
 */
export const useDatasourceQuery = (id: number, disabled = false): UseQueryResult<GetDataSourcesDetailsResponse> => {
  const query = useQuery<GetDataSourcesDetailsResponse>({
    queryKey: [GET_DATA_SOURCE_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getDataSourceDetails(id);
      return response.data;
    },
    enabled: !disabled,
  });

  return query;
};

/**
 * Custom useQuery hook to fetch big-query datasets based on projectId
 * @param dataSourceId - The datasource ID.
 * @param projectId - The project ID.
 * @param enabled - if true means that useQuery must make an API call, otherwise - mustn't.
 */
export const useBigQueryDatasetsQuery = (
  dataSourceId: number,
  projectId: string,
  enabled = true
): UseQueryResult<Array<GetDatasetsResponse>> =>
  useQuery<Array<GetDatasetsResponse>>({
    queryKey: [GET_DATASETS_QUERY_KEY, dataSourceId, projectId],
    queryFn: async () => {
      const response = await getBigQueryDatasets(dataSourceId, projectId);
      return response.data;
    },
    enabled,
  });

/**
 * Custom useQuery hook to fetch connectors by filters
 * @param filters connectors filters
 */
export const useConnectors = (filters: ConnectorsFilters): UseQueryResult<Array<Connector>> => {
  return useQuery<Array<Connector>>({
    queryKey: [GET_CONNECTORS_QUERY_KEY, filters],
    queryFn: async () => {
      const response = await getConnectors(filters);
      return response.data;
    },
  });
};

/**
 * Custom hook for deleting a connector.
 */
export const useDeleteConnectorMutation = () => {
  const mutation = useMutation<unknown, Error, number>({
    mutationFn: async (id) => {
      const response = await deleteConnector(id);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_DATA_SOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [GET_CONNECTORS_QUERY_KEY] });
    },
  });

  return mutation;
};

/**
 * Custom hook for updating connector.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * @returns The mutation object.
 */
export const useUpdateConnectorMutation = () => {
  const mutation = useMutation<
    UpdateConnectorResponse,
    Error,
    { connectorId: number; payload: UpdateConnectorRequest }
  >({
    mutationFn: async ({ connectorId, payload }) => {
      const response = await withErrorHandling(updateConnector(connectorId, payload), {
        loading: 'Updating connector...',
        success: 'Connector updated successfully.',
      });
      return response.data;
    },
  });

  return mutation;
};
