import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';
import { ROUTES } from '@shared/constants.ts';
import { debounce } from 'lodash';

// material-ui
import { Button, Grid, InputAdornment, OutlinedInput, Pagination, Stack, Typography } from '@mui/material';

// project imports
import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout.tsx';
import UserList from '@/modules/users/components/UserList';
import MainCard from '@themes/components/cards/MainCard';
import AnimateButton from '@themes/components/extended/AnimateButton';
import { useUsersListQuery } from '@/modules/users/queries.ts';
import Loader from '@/themes/components/Loader';

// assets
import { IconSearch } from '@tabler/icons-react';
import { UsersFilters } from '@modules/users/types.ts';

/**
 * Users page component, a React functional component.
 *
 * @returns {React.ReactNode} The rendered component JSX.
 */
const UsersPage: React.FC = () => {
  const navigate = useNavigate();
  const defaultPageSize = 50;
  const [filters, setFilters] = useState<UsersFilters>({ page: 1, size: defaultPageSize });
  const hasAccess = usePowerUserAccess();
  const { data, isLoading: isUserListLoading } = useUsersListQuery(filters);

  const searchDebounce = useMemo(() => {
    return debounce((value: string) => {
      setFilters((state) => ({ ...state, search: value, page: 1, size: defaultPageSize }));
    }, 300);
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    searchDebounce(e.currentTarget.value);
  };

  if (isUserListLoading) {
    return <Loader />;
  }

  return (
    <AuthorizedPageLayout title={'Users'}>
      <MainCard
        title={
          <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h3">List</Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1}>
                <OutlinedInput
                  id="input-search-list-style1"
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start">
                      <IconSearch stroke={1.5} size="16px" />
                    </InputAdornment>
                  }
                  onChange={handleSearch}
                  size="small"
                />
                {hasAccess && (
                  <AnimateButton>
                    <Button
                      color="secondary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={() => navigate(ROUTES.USERS.CREATE)}
                    >
                      Add User
                    </Button>
                  </AnimateButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        }
        content={false}
      >
        {data?.items && (
          <>
            <UserList users={data.items} />
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Pagination
                    hidden={filters.size >= data?.total}
                    page={filters.page}
                    count={Math.ceil(data?.total / filters.size)}
                    color="primary"
                    onChange={(_, page) => setFilters((state) => ({ ...state, page }))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </AuthorizedPageLayout>
  );
};

export default UsersPage;
