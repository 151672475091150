import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { capitalize } from '@/shared/helpers/capitalize';
import { AUTH_ROUTES } from '@api/routes.ts';
import { ROUTES, LOCAL_STORAGE } from '@/shared/constants';

export interface ApiRequest<T> {
  data: T;
}

export interface ApiResponse<T> {
  data: T;
  status: number;
  message: string;
}

const { VITE_APP_API_URL } = import.meta.env;
const DEFAULT_AUTH_TYPE = 'Bearer';

// Create an axios instance with default headers for API key authentication
const httpClient = axios.create({
  baseURL: VITE_APP_API_URL,
});

// Request interceptor
httpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    const tokenType = localStorage.getItem(LOCAL_STORAGE.TOKEN_TYPE);
    const authType = capitalize(tokenType ?? DEFAULT_AUTH_TYPE);

    if (token) {
      config.headers.Authorization = `${authType} ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface FailedRequests {
  resolve: (value: AxiosResponse) => void;
  reject: (value: AxiosError) => void;
  config: AxiosRequestConfig;
  error: AxiosError;
}

let failedRequests: FailedRequests[] = [];
let isTokenRefreshing = false;

httpClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const status = error.response?.status;
    const originalRequestConfig = error.config!;

    if (status !== 401 || error?.config?.url === AUTH_ROUTES.AUTH.LOGIN) {
      return Promise.reject(error);
    }

    if (isTokenRefreshing) {
      return new Promise((resolve, reject) => {
        failedRequests.push({
          resolve,
          reject,
          config: originalRequestConfig,
          error: error,
        });
      });
    }

    isTokenRefreshing = true;

    try {
      const response = await axios.post(VITE_APP_API_URL + AUTH_ROUTES.AUTH.REFRESH, {
        refresh_token: localStorage.getItem('refreshToken') ?? '',
      });
      const { access_token: accessToken = null, refresh_token: refreshToken = null } = response.data ?? {};

      if (!accessToken || !refreshToken) {
        localStorage.clear();
        window.location.href = `.${ROUTES.AUTH.LOGIN}`;
      }

      localStorage.setItem('token', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      failedRequests.forEach(({ resolve, reject, config }) => {
        httpClient(config)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    } catch (_error: unknown) {
      console.error(_error);
      failedRequests.forEach(({ reject, error }) => reject(error));
      localStorage.clear();
      window.location.href = `.${ROUTES.AUTH.LOGIN}`;
      return Promise.reject(error);
    } finally {
      failedRequests = [];
      isTokenRefreshing = false;
    }

    return httpClient(originalRequestConfig);
  }
);

export { httpClient };
