import React, { useEffect, useRef, useState } from 'react';
import { Box, BoxProps } from '@mui/material';

interface ResizeObserverComponentProps extends Omit<BoxProps, 'children'> {
  children: (width: number, height: number) => React.ReactNode;
}

const ResizeObserverComponent: React.FC<ResizeObserverComponentProps> = ({ children, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry.contentRect) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Box ref={containerRef} {...props} sx={{ width: '100%', height: '100%', ...props?.sx }}>
      {children(dimensions.width, dimensions.height)}
    </Box>
  );
};

export default ResizeObserverComponent;
