import {
  ChartColorScheme,
  ChartType,
  ChartVisualizationType,
  ColorScheme,
  QueryRawData,
  QueryRawDataItem,
  StoredChartVisualization,
  StoredTableVisualization,
  TableColorScheme,
  Visualization,
  VisualizationTypes,
} from '@modules/query/types.ts';
import { Box, Grid } from '@mui/material';
import { ModalTable } from '@modules/query/components/ModalTable.tsx';
import { ModalChart } from '@modules/query/components/ModalChart.tsx';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { suggestAlternativeChart } from '@modules/query/helpers/ChartAlternativesSuggestion.ts';
import { DataFormatOptionsDrawer } from '@shared/components/DataFormatOptionsDrawer.tsx';
import { CellFormat, FieldsToFormat, formatCellValue, ItemFormatMap } from '@shared/helpers/formatCellValue.ts';
import Chip from '@mui/material/Chip';
import { DEFAULT_TABLE_COLOR_SCHEME } from '@/shared/constants';
import { TableStyleDrawer } from '@modules/query/components/TableStyleDrawer.tsx';
import { ChartStyleDrawer } from '@modules/query/components/ChartStyleDrawer.tsx';
import { ChartPreviewModal } from '@modules/query/components/ChartPreviewModal.tsx';
import _ from 'lodash';
import Alert from '@shared/components/Alert.tsx';

interface ChartVisualizationProps {
  question: string;
  visualization: Visualization;
  rawData?: QueryRawData;
  isOwner?: boolean;

  onVisualizationSave: (
    data: StoredChartVisualization | StoredTableVisualization,
    visualizationType: VisualizationTypes,
    colorScheme?: ColorScheme,
    formatOptions?: ItemFormatMap
  ) => void;
}

export const MAX_CHART_DATA_TO_SHOW = 200;
export const MAX_CHART_DATA_MESSAGE = 'Chart generation is not possible due to the large number of results returned.';

export const ChartVisualization: FC<ChartVisualizationProps> = ({
  visualization,
  rawData,
  question,
  isOwner = false,
  onVisualizationSave,
}) => {
  const visualizationType = visualization.type;
  const visualizationValue = visualization.value as ChartVisualizationType;
  const chartType = visualizationValue?.type;
  const [chartVisualization, setChartVisualization] = useState<ChartVisualizationType | null>(visualizationValue);

  const [data, setData] = useState<QueryRawData>(rawData || []);

  // State to keep the order of data after sorting.
  const [dataSortingOrder, setDataSortingOrder] = useState<number[]>();
  // Data used for chart rendering and on table visualization save.
  // This approach is used to keep the original data unchanged when sorting is applied.
  const visualisedData = useMemo(() => {
    if (dataSortingOrder) {
      return dataSortingOrder.map((index) => data[index]);
    }
    return data;
  }, [data, dataSortingOrder]);

  const [selectedChartToStyle, setSelectedChartToStyle] = useState<ChartType>();
  const [isTableStyleDrawerOpen, setIsTableStyleDrawerOpen] = useState(false);
  const [tableColorScheme, setTableColorScheme] = useState<TableColorScheme>(DEFAULT_TABLE_COLOR_SCHEME);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewData, setPreviewData] = useState<ChartVisualizationType | null>(null);
  const [selectedChartType, setSelectedChartType] = useState<ChartType | null>(null);
  const [selectedTableColumnToFormat, setSelectedTableColumnToFormat] = useState<FieldsToFormat>();
  const [dataFormats, setDataFormats] = useState<ItemFormatMap>();
  // Hold applied color schema for each chart using their type as keys
  const [chartsColorSchemes, setChartsColorSchemes] = useState<Record<ChartType, ChartColorScheme>>();
  // State to check if the icon button is clicked
  const [isIconButtonClicked, setIsIconButtonClicked] = useState<boolean>(false);

  const canChartBeRendered = data?.length <= MAX_CHART_DATA_TO_SHOW;

  const suggestedChartType = useMemo(() => {
    if (visualizationType === VisualizationTypes.CHART && chartType) {
      return suggestAlternativeChart(chartType as ChartType);
    }
    return undefined;
  }, [visualizationType, chartType]);

  // TODO: use children or separate component instead
  const chartStyleDrawerMemoized = useMemo(() => {
    return (
      <ChartStyleDrawer
        open={!!selectedChartToStyle}
        onClose={() => setSelectedChartToStyle(undefined)}
        colorScheme={
          chartsColorSchemes?.[selectedChartToStyle as ChartType] ?? undefined // TODO: DEFAULT_CHART_COLOR_SCHEME?
        }
        onColorChange={(scheme) => {
          setChartsColorSchemes({ ...chartsColorSchemes, [selectedChartToStyle as ChartType]: scheme } as Record<
            ChartType,
            ChartColorScheme
          >);
        }}
      />
    );
  }, [selectedChartToStyle, chartsColorSchemes]);

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
    setPreviewData(null);
    setSelectedChartType(null);
  };

  const handleChartClick = (previewData: ChartVisualizationType, chartType: ChartType) => {
    setIsPreviewOpen(true);
    setPreviewData(previewData);
    setSelectedChartType(chartType);
  };

  // Handle icon button click to play animation on every click
  const handleIconButtonClick = () => {
    setIsIconButtonClicked(false);
    setTimeout(() => setIsIconButtonClicked(true), 0);
  };

  const handleTableVisualizationSave = useCallback(() => {
    handleIconButtonClick();

    let data = visualisedData;

    // Map data according to formatters before saving
    if (dataFormats) {
      data = data.map((item) => {
        return Object.entries(item).reduce((acc, [key, value]) => {
          // Check if there's a format for this key
          if (dataFormats[key]) {
            acc[key] = formatCellValue(value, dataFormats[key]); // Apply formatting
          } else {
            acc[key] = value; // Keep the original value if no format
          }
          return acc;
        }, {} as QueryRawDataItem);
      });
    }

    onVisualizationSave(data, VisualizationTypes.TABLE, tableColorScheme);
  }, [onVisualizationSave, dataFormats, visualisedData, tableColorScheme]);

  const handleCellFormatSelect = useCallback(
    (format: CellFormat) => {
      if (!selectedTableColumnToFormat) {
        return;
      }

      setDataFormats((prev) => ({
        ...prev,
        [selectedTableColumnToFormat.field]: format,
      }));

      setSelectedTableColumnToFormat(undefined);
    },
    [selectedTableColumnToFormat, setDataFormats, setSelectedTableColumnToFormat]
  );

  useEffect(() => {
    if (rawData) {
      setData(rawData);
      setDataSortingOrder([]);
    }
  }, [rawData]);

  useEffect(() => {
    if (visualizationValue) {
      setChartVisualization(visualizationValue);
    }
  }, [visualizationValue]);

  const onDataChange = useCallback(
    (newData: QueryRawData) => {
      if (!chartVisualization) {
        return;
      }

      const newKeys = Object.keys(newData[0]);
      const oldKeys = Object.keys(data[0]);
      const { categoryKey, seriesKeys } = chartVisualization;

      // Detect added or removed keys
      const removedKeys = _.difference(oldKeys, newKeys);
      const addedKeys = _.difference(newKeys, oldKeys);

      let updatedCategoryKey = categoryKey;
      let updatedSeriesKeys = [...seriesKeys];

      // 1. Check if the updated keys affect the current categoryKey
      if (removedKeys.includes(categoryKey) && addedKeys.length > 0) {
        updatedCategoryKey = addedKeys[0]; // Set the new category key to the first added key
      }

      // 2. Check if the updated keys affect any of the seriesKeys
      const seriesKeysToRemove = seriesKeys.filter((key) => removedKeys.includes(key));
      if (seriesKeysToRemove.length > 0) {
        updatedSeriesKeys = seriesKeys.filter((key) => !seriesKeysToRemove.includes(key));
        updatedSeriesKeys = _.uniq([...updatedSeriesKeys, ...addedKeys]);
      }

      // Update visualization if any changes are detected in categoryKey or seriesKeys
      if (updatedCategoryKey !== categoryKey || !_.isEqual(updatedSeriesKeys, seriesKeys)) {
        setChartVisualization(
          (prev) =>
            ({
              ...prev,
              categoryKey: updatedCategoryKey,
              seriesKeys: updatedSeriesKeys,
            }) as ChartVisualizationType
        );
      }

      // Update the data state with the new data
      setData(newData);
    },
    [chartVisualization, data]
  );

  if (!chartVisualization) {
    return null;
  }

  return (
    <>
      <TableStyleDrawer
        open={isTableStyleDrawerOpen}
        onClose={() => setIsTableStyleDrawerOpen(false)}
        colorScheme={tableColorScheme}
        onColorChange={setTableColorScheme}
      />
      <DataFormatOptionsDrawer
        open={!!selectedTableColumnToFormat}
        // TODO: change selected table cells to format signature to get correct type
        dataType={selectedTableColumnToFormat?.type ?? 'string'}
        onClose={() => setSelectedTableColumnToFormat(undefined)}
        onFormatSelect={handleCellFormatSelect}
      />

      {previewData && selectedChartType && chartVisualization && (
        <ChartPreviewModal open={isPreviewOpen} onClose={handlePreviewClose} styleDrawer={chartStyleDrawerMemoized}>
          <ModalChart
            isOwner={isOwner}
            isIconButtonClicked={isIconButtonClicked}
            onVisualizationSave={(data) => {
              handleIconButtonClick();
              onVisualizationSave(
                data,
                VisualizationTypes.CHART,
                chartsColorSchemes?.[selectedChartType] ?? undefined,
                dataFormats
              );
            }}
            question={question}
            chartType={selectedChartType}
            categoryKey={chartVisualization.categoryKey}
            seriesKeys={chartVisualization.seriesKeys}
            data={visualisedData}
            colorScheme={chartsColorSchemes?.[selectedChartType]}
            onSelectChartToStyle={setSelectedChartToStyle}
            dataFormatters={dataFormats}
            preview
          />
        </ChartPreviewModal>
      )}

      <Box
        sx={{
          display: 'flex',
          padding: '0 32px',
          gap: '24px',
          overflowX: 'hidden',
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <Box sx={{ maxHeight: '100%', width: '50%' }}>
          {rawData && (
            <ModalTable
              onDrawerOpen={() => setIsTableStyleDrawerOpen(true)}
              data={data}
              onDataChange={onDataChange}
              isOwner={isOwner}
              isIconButtonClicked={isIconButtonClicked}
              onVisualizationSave={handleTableVisualizationSave}
              colorScheme={tableColorScheme}
              columnsFormatters={dataFormats}
              selectDataToFormat={setSelectedTableColumnToFormat}
              onSortChange={(newSortingOrder: number[]) => {
                if (!_.isEqual(newSortingOrder, dataSortingOrder)) {
                  setDataSortingOrder(newSortingOrder);
                }
              }}
            />
          )}
        </Box>

        {canChartBeRendered ? (
          <Grid container sx={{ width: '50%' }}>
            <Grid item xs={12}>
              <ModalChart
                isOwner={isOwner}
                question={question}
                data={visualisedData}
                categoryKey={chartVisualization.categoryKey}
                seriesKeys={chartVisualization.seriesKeys}
                chartType={chartType}
                colorScheme={chartsColorSchemes?.[chartType]}
                onPreview={() => handleChartClick(chartVisualization, chartType)}
                onSelectChartToStyle={setSelectedChartToStyle}
                onVisualizationSave={(data) => {
                  handleIconButtonClick();
                  onVisualizationSave(
                    data,
                    VisualizationTypes.CHART,
                    chartsColorSchemes?.[chartType] ?? undefined,
                    dataFormats
                  );
                }}
                dataFormatters={dataFormats}
              >
                <Chip label="LLM Recommended" color="primary" size="small" />
              </ModalChart>
            </Grid>
            {suggestedChartType && chartVisualization && (
              <Grid item xs={12}>
                <ModalChart
                  isOwner={isOwner}
                  question={question}
                  data={visualisedData}
                  categoryKey={chartVisualization.categoryKey}
                  seriesKeys={chartVisualization.seriesKeys}
                  chartType={suggestedChartType}
                  colorScheme={chartsColorSchemes?.[suggestedChartType]}
                  onPreview={() => handleChartClick(chartVisualization, suggestedChartType)}
                  onSelectChartToStyle={setSelectedChartToStyle}
                  onVisualizationSave={(data) => {
                    handleIconButtonClick();
                    onVisualizationSave(
                      data,
                      VisualizationTypes.CHART,
                      chartsColorSchemes?.[suggestedChartType] ?? undefined,
                      dataFormats
                    );
                  }}
                  dataFormatters={dataFormats}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <Alert title={MAX_CHART_DATA_MESSAGE} />
        )}
      </Box>
    </>
  );
};
