import { FC, useCallback, useEffect, useState } from 'react';
import {
  SingleValueVisualizationType,
  StoredSingleValueVisualization,
  VisualizationTypes,
} from '@modules/query/types.ts';
import { Box, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { PinButton } from '@modules/query/components/PinButton.tsx';
import { fontSizeHelper } from '@modules/query/helpers';
import { CellFormat, DateFormats, NumberFormats } from '@/shared/helpers/formatCellValue.ts';
import { DataFormatOptionsDrawer, FormatDrawerType } from '@shared/components/DataFormatOptionsDrawer.tsx';
import { Tune } from '@mui/icons-material';
import { getSingleVisualizationFormat } from '@modules/query/helpers/getSingleVisualizationFormat.ts';
import ResizeObserverComponent from '@shared/components/ResizeObserverComponent.tsx';

interface SingleValueVisualizationProps {
  /**
   * Asked question associated with the visualization
   */
  question: string;
  /**
   * Visualization data associated with the question
   */
  visualization: SingleValueVisualizationType;
  /**
   * True, if user is the owner of the workspace
   */
  isOwner?: boolean;

  /**
   * Callback to handle visualization save
   */
  onVisualizationSave: (data: StoredSingleValueVisualization, visualizationType: VisualizationTypes) => void;
}

/**
 * Process and display single value visualization.
 */
export const SingleValueVisualization: FC<SingleValueVisualizationProps> = ({
  visualization,
  isOwner = false,
  question,
  onVisualizationSave,
}) => {
  const visualizationType = visualization.type;
  const initialFormat = visualization.single_result_format as CellFormat;

  const [dateVisualizationFormat, setDateVisualizationFormat] = useState<CellFormat>(
    DateFormats.includes(initialFormat) ? initialFormat : CellFormat.Date
  );
  const [numberVisualizationFormat, setNumberVisualizationFormat] = useState<CellFormat>(
    NumberFormats.includes(initialFormat) ? initialFormat : CellFormat.Number
  );
  const [isFormatOptionsDrawerOpen, setIsFormatOptionsDrawerOpen] = useState(false);
  // State to check if the icon button is clicked
  const [isIconButtonClicked, setIsIconButtonClicked] = useState(false);

  const [visualizationValue, setVisualizationValue] = useState<string>('');
  const [formatOptionsDrawerType, setFormatOptionsDrawerType] = useState<FormatDrawerType>('all');

  const lines = visualizationValue.split('\n');
  const largestLine = lines.reduce((a, b) => (a.length > b.length ? a : b), '');

  useEffect(() => {
    const { value, hasNumber, hasDate } = getSingleVisualizationFormat(
      visualization.value,
      numberVisualizationFormat,
      dateVisualizationFormat
    );

    // Set drawerType based on the data type
    setFormatOptionsDrawerType(hasDate && hasNumber ? 'all' : hasDate ? 'date' : hasNumber ? 'number' : 'string');
    setVisualizationValue(value);
  }, [visualization.value, dateVisualizationFormat, numberVisualizationFormat]);

  // Handle icon button click to play animation on every click
  const handleIconButtonClick = useCallback(() => {
    setIsIconButtonClicked(false);
    setTimeout(() => setIsIconButtonClicked(true), 0);
  }, [setIsIconButtonClicked]);

  const handleFormatChange = useCallback(
    (format: CellFormat) => {
      if (DateFormats.includes(format)) {
        setDateVisualizationFormat(format);
      }

      if (NumberFormats.includes(format)) {
        setNumberVisualizationFormat(format);
      }
    },
    [setDateVisualizationFormat, setNumberVisualizationFormat]
  );

  return (
    <>
      <DataFormatOptionsDrawer
        open={isFormatOptionsDrawerOpen}
        onClose={() => setIsFormatOptionsDrawerOpen(false)}
        dataType={formatOptionsDrawerType}
        onFormatSelect={handleFormatChange}
      />
      <Box
        sx={{
          position: 'relative',
          width: '30%',
          margin: 'auto',
          padding: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          mb: 3,
        }}
      >
        <Box sx={{ position: 'absolute', top: '20px', right: '-5px', cursor: 'pointer', zIndex: 999 }}>
          <Tooltip title="Customization">
            <IconButton onClick={() => setIsFormatOptionsDrawerOpen(true)} color="primary">
              <Tune sx={{ color: 'gray' }} />
            </IconButton>
          </Tooltip>
          <PinButton
            isOwner={isOwner}
            handleChartSave={() => {
              handleIconButtonClick();
              onVisualizationSave(visualizationValue, visualizationType);
            }}
            isIconButtonClicked={isIconButtonClicked}
          />
        </Box>
        <Card sx={{ width: '100%', position: 'relative' }}>
          <Typography sx={{ fontSize: 14, width: '80%' }} color="text.secondary" gutterBottom>
            {question}
          </Typography>
          <CardContent
            sx={{
              aspectRatio: '1/1',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              justifyItems: 'center',
              flexDirection: 'column',
            }}
          >
            <ResizeObserverComponent>
              {(width) => (
                <Box
                  sx={{
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '50%',
                    width: '100%',
                  }}
                >
                  {lines.map((line, index) => (
                    <Typography
                      key={index}
                      variant="h1"
                      sx={{ fontSize: fontSizeHelper(largestLine, width) }}
                      align="center"
                      whiteSpace="nowrap"
                    >
                      {line}
                    </Typography>
                  ))}
                </Box>
              )}
            </ResizeObserverComponent>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
