import { useMutation } from '@tanstack/react-query';
import { ExecuteQueryRequest, ExecuteQueryResponse } from '@/modules/query/types.ts';
import { executeQuery } from '@/modules/query/api.ts';
import { withErrorHandling } from '@/shared/helpers/withErrorHandling';
import { FormikErrors, FormikValues } from 'formik';

const EXECUTE_QUERY_MUTATION_KEY = 'xanalityca/execute-query';

export interface ExecuteQueryPayload {
  datasourceId: number;
  data: ExecuteQueryRequest;
}

export const useExecuteQueryMutation = ({ setErrors }: { setErrors: (errors: FormikErrors<FormikValues>) => void }) => {
  const mutation = useMutation<ExecuteQueryResponse, Error, ExecuteQueryPayload>({
    mutationKey: [EXECUTE_QUERY_MUTATION_KEY],
    mutationFn: async ({ datasourceId, data }) => {
      const response = await withErrorHandling(executeQuery(datasourceId, data), {
        loading: 'Executing query...',
        success: 'Query executed successfully',
        setErrors,
        // Set the notification duration to 0 to keep the loading notification open indefinitely
        // Closes the notification on success/error
        notificationDuration: 0,
      });
      return response.data;
    },
  });

  return mutation;
};
