import { FC } from 'react';
import { QueryRawData, TableColorScheme } from '@/modules/query/types.ts';
import { WorkspaceTableToolbar } from '@/modules/query/components/table/toolbar/WorkspaceTableToolbar';
import { BaseTable } from '@/shared/components/BaseTable';
import { getColumns, getRows } from '@/modules/query/helpers/tableHelper.tsx';
import { DEFAULT_TABLE_COLOR_SCHEME } from '@shared/constants.ts';

interface WorkspaceTableProps {
  rawData: QueryRawData;
  colorScheme?: TableColorScheme;
}

export const WorkspaceTable: FC<WorkspaceTableProps> = ({ rawData, colorScheme = DEFAULT_TABLE_COLOR_SCHEME }) => {
  const columns = getColumns(rawData);
  const rows = getRows(rawData);
  return (
    <BaseTable
      columns={columns}
      rows={rows}
      slots={{
        toolbar: () => <WorkspaceTableToolbar />,
      }}
      colorScheme={colorScheme}
      isCellEditable={() => false}
    />
  );
};
