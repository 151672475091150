import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useGoogleLogin, CodeResponse } from '@react-oauth/google';

const scope =
  'https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/devstorage.read_only https://www.googleapis.com/auth/cloud-platform.read-only';

interface QueryWithGoogleLoginOptions {
  queryFunction: (tokenResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => void;
}

/**
 * Hook to query with Google login.
 * Wraps provided query function with Google login.
 */
export const useQueryWithGoogleLogin = ({ queryFunction }: QueryWithGoogleLoginOptions) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryWithGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const requiredScopes = scope.split(' ');
      const grantedScopes = tokenResponse.scope.split(' ');
      const isAllScopesGranted = requiredScopes.every((scope) => grantedScopes.includes(scope));

      if (!isAllScopesGranted) {
        toast.error('Please authorize all permissions to use this application');
        setIsLoading(false);
        return;
      }

      try {
        await queryFunction(tokenResponse);
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      toast.error(error.error_description?.toString() || 'Failed to login');
      setIsLoading(false);
    },
    onNonOAuthError(nonOAuthError) {
      toast.error(nonOAuthError.toString());
      setIsLoading(false);
    },
    flow: 'auth-code',
    scope,
  });

  return { queryWithGoogleLogin, isLoading };
};
