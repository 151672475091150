import { useState } from 'react';
import { GoogleOAuth } from '@/modules/auth/forms/GoogleOAuth';
import { Box, Stack, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Loader from '@themes/components/Loader.tsx';
import {
  useConnectors,
  useCreateDatasourceMutation,
  useUpdateConnectorMutation,
} from '@modules/datasources/queries.ts';
import { toast } from 'react-hot-toast';
import MainCard from '@themes/components/cards/MainCard.tsx';
import ConnectorCard from '@modules/datasources/components/ConnectorCard.tsx';
import { Connector } from '@modules/datasources/types.ts';
import { useQueryWithGoogleLogin } from '@modules/datasources/hooks/useQueryWithGoogleLogin';

const bigQueryType = 'Google BigQuery';

type BigQueryConnectProps = {
  onDeleteConnector: (connector: Connector) => void;
  onUpdateConnector: (connectorId: number | null, onRefreshConnectorAuthConfig: VoidFunction | null) => void;
  selectedConnectorId: number | null;
};

const BigQueryConnect = ({ onDeleteConnector, onUpdateConnector, selectedConnectorId }: BigQueryConnectProps) => {
  const [showLoader, setShowLoader] = useState(false);

  const { data: connectors, isLoading: isConnectorsLoading } = useConnectors({ type: bigQueryType });
  const { mutateAsync: createDataSource, isPending } = useCreateDatasourceMutation();
  const { mutateAsync: updateConnector } = useUpdateConnectorMutation();

  const { queryWithGoogleLogin: createWithGoogleLogin, isLoading: isCreateLoading } = useQueryWithGoogleLogin({
    queryFunction: async (tokenResponse) => {
      createDataSource({
        code: tokenResponse.code,
        type: bigQueryType,
      });
    },
  });

  // Function to update existed connector.
  const { queryWithGoogleLogin: refreshConnector, isLoading: isRefreshLoading } = useQueryWithGoogleLogin({
    queryFunction: async (tokenResponse) => {
      if (selectedConnectorId) {
        updateConnector({
          connectorId: selectedConnectorId,
          payload: {
            code: tokenResponse.code,
          },
        });
      }
    },
  });

  const onConnectExistedConnection = async (connectorId: number) => {
    try {
      await createDataSource({ type: bigQueryType, connector_id: connectorId });
    } catch {
      try {
        onUpdateConnector(connectorId, refreshConnector);
      } catch {
        setShowLoader(false);
        toast.error('Failed to create/update data source');
      }
    }
  };

  const isLoading = isCreateLoading || isRefreshLoading || showLoader;

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
      {isLoading || isConnectorsLoading ? (
        <Loader />
      ) : (
        <Stack gap={2} width={'100%'}>
          {!!connectors && (
            <>
              <MainCard
                title={
                  <Grid>
                    <Typography variant="h3">Active Sessions</Typography>
                  </Grid>
                }
                content={false}
              />
              <Grid container spacing={2}>
                {connectors.map((el) => (
                  <Grid key={el.id} minWidth={320}>
                    <ConnectorCard
                      type={bigQueryType}
                      connector={el}
                      onConnect={onConnectExistedConnection}
                      onDelete={onDeleteConnector}
                      isDisabled={isPending}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider
                component="div"
                role="presentation"
                sx={{
                  margin: '30px 0',
                  '&::before, &::after': { borderColor: 'secondary.main', borderBottomWidth: '2px' },
                }}
              >
                <Typography variant="h3">Or</Typography>
              </Divider>
            </>
          )}
          <Stack flexDirection={'row'} justifyContent={'center'} alignItems={connectors ? 'flex-start' : 'center'}>
            <GoogleOAuth
              onClick={() => {
                setShowLoader(true);
                createWithGoogleLogin();
              }}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default BigQueryConnect;
