import { FC } from 'react';

import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(Tooltip)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 30,
  height: 30,
  backgroundColor: 'white',
  zIndex: 99,
  opacity: 1,
  '&:hover': {
    backgroundColor: 'white',
    opacity: 1,
    boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.2)',
    transition: '0.3s ease all',
  },
}));

const CloseButton: FC<IconButtonProps> = (props) => {
  return (
    <StyledTooltip className="hoverIcon" title="Close">
      <IconButton {...props}>
        <HighlightOffIcon color="error" />
      </IconButton>
    </StyledTooltip>
  );
};

export default CloseButton;
