import { FC } from 'react';
import { QueryRawData } from '@/modules/query/types.ts';
import { GridColumnHeaderParams, GridComparatorFn } from '@mui/x-data-grid';
import { EditableHeaderProps } from '@modules/query/components/table/header/EditableHeader.tsx';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { FieldsToFormat, formatCellValue, isDate, isNumber, ItemFormatMap } from '@shared/helpers/formatCellValue.ts';

const dateComparator: GridComparatorFn<string> = (v1, v2) => {
  const date1 = new Date(v1);
  const date2 = new Date(v2);
  return date1.getTime() - date2.getTime();
};

export function getEditableColumns(
  rawData: QueryRawData,
  EditableHeader: FC<EditableHeaderProps>,
  onTableColumnNameChange: (currentName: string, newName: string) => void,
  selectTableCellsFormat: (data: FieldsToFormat) => void,
  columnsFormatters?: ItemFormatMap
): GridColDef[] {
  const columns = rawData.length ? Object.keys(rawData[0]) : [];
  const isNumericColumn = (column: string) => {
    return rawData.every((row) => {
      const value = row[column];
      return !value || isNumber(value);
    });
  };

  const isDateColumn = (column: string) => {
    return rawData.every((row) => {
      const value = row[column];
      return !value || isDate(String(value));
    });
  };

  return columns.map((key, index) => ({
    field: key,
    headerName: key,
    headerClassName: 'xanalytica-theme--header',
    flex: 1,
    editable: true,
    // type: isNumericColumn(key) ? 'number' : undefined,
    align: 'left',
    headerAlign: 'left',
    renderHeader: (params: GridColumnHeaderParams) => {
      const isNumeric = isNumericColumn(key);
      const isDate = isDateColumn(key);
      return (
        <EditableHeader
          params={params}
          isNumeric={isNumeric}
          isDate={isDate}
          onColumnChange={onTableColumnNameChange}
          onFormatIconClick={() => {
            const column = params.field;

            selectTableCellsFormat({
              type: isNumeric ? 'number' : isDate ? 'date' : 'string',
              field: column,
            });
          }}
        />
      );
    },
    renderCell: (params) => {
      const column = params.field;
      if (columnsFormatters?.[column]) {
        return formatCellValue(params.value, columnsFormatters[column]);
      }

      return params.value;
    },
    minWidth: 150,
    ...(isDateColumn(key) && { sortComparator: dateComparator }),
    // Disable resizing for last column
    resizable: index !== columns.length - 1,
  }));
}

export function getColumns(rawData: QueryRawData): GridColDef[] {
  return rawData.length
    ? Object.keys(rawData[0]).map((key) => ({
        field: key,
        headerName: key,
        headerClassName: 'xanalytica-theme--header',
        flex: 1,
        editable: true,
      }))
    : [];
}

export function getRows(rawData: QueryRawData) {
  return rawData.map((row, index) => ({ id: index, ...row }));
}

export function renameKeyInObject(
  obj: Record<string, unknown>,
  oldKey: string,
  newKey: string
): Record<string, unknown> {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (key === oldKey) {
      newObj[newKey] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function getCellIdentifier(row: number, column: string) {
  return `${row}-${column}`;
}
