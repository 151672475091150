import React, { FC } from 'react';
import { Box, BoxProps, Modal, ModalProps } from '@mui/material';
import { styled } from '@mui/system';
import CloseButton from '@shared/components/CloseButton.tsx';

const ModalContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: '60vw',
  maxWidth: '100vw',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(5),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '32px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  outline: 'none',
  overflow: 'auto',
  maxHeight: '100%',
  boxShadow: 'none',

  '&::-webkit-scrollbar': {
    width: '8px',
    display: 'block',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track-piece:start': {
    marginTop: '12px',
  },
  '&::-webkit-scrollbar-track-piece:end': {
    marginBottom: '12px',
  },
}));

export type BaseModalProps = Omit<ModalProps, 'children'> & {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  closeIcon?: boolean;
  ModalContentProps?: BoxProps;
};

const BaseModal: FC<BaseModalProps> = ({
  open,
  onClose,
  closeIcon = false,
  ModalContentProps = {},
  children,
  ...props
}) => {
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onClose) onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      {...props}
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        ...props.sx,
      }}
    >
      <ModalContentWrapper onClick={handleClose}>
        <ModalContent
          {...ModalContentProps}
          sx={{
            ...ModalContentProps.sx,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {closeIcon && <CloseButton onClick={handleClose} />}
          {children}
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  );
};

export default BaseModal;
