import { ChartType, QueryRawData } from '@modules/query/types.ts';
import { isNumber } from '@shared/helpers/formatCellValue.ts';

// TODO: add typing and improve parameters
export const getChartDataFromVisualization = (
  data: QueryRawData,
  type: ChartType,
  categoryKey: string,
  seriesKeys: string[] = []
) => {
  if (data.length === 0 || !categoryKey || seriesKeys.length === 0) {
    return { categories: [], series: [] };
  }

  const categories = getUniqueCategoriesWithNA(data, categoryKey);
  // Determine yAxisColumnName (the column for Y-axis values)
  const yAxisColumnName: string = seriesKeys.length > 1 ? seriesKeys[1] : seriesKeys[0];
  let series: string[] | number[] | { name: string; data: (number | null)[] }[] | any[] = [];

  switch (type) {
    case 'pie': {
      // For pie and donut charts, aggregate each series key's values into a single array
      series = seriesKeys.map((key) => {
        return data.reduce((sum, item) => sum + ((item[key] as number) || 0), 0); // Pie/donut series should be a flat array of totals
      });
      break;
    }
    case 'line':
    case 'area':
    case 'bar': {
      series = prepareBarGroupChartData(data, { categoryKey, seriesKeys, categories });
      break;
    }
    default:
      series = seriesKeys.map((key) => ({
        name: key,
        data: data.map((item) => (item[key] as number | null) || null),
      }));
      break;
  }

  return { categories, series, yAxisColumnName };
};

function getUniqueCategoriesWithNA(data: QueryRawData, categoryKey: string) {
  const seen = new Set();
  const categories = data.map((item) => item[categoryKey] ?? 'N/A');

  return categories.filter((category) => {
    if (category === 'N/A') {
      return true; // Always keep 'N/A'
    }
    if (!seen.has(category)) {
      seen.add(category);
      return true;
    }
    return false; // Skip duplicates
  });
}

const prepareBarGroupChartData = (
  data: QueryRawData,
  visualization: {
    categoryKey: string;
    seriesKeys: string[];
    categories: (string | number)[];
  }
) => {
  const { categoryKey, seriesKeys, categories } = visualization;

  if (seriesKeys.length === 0) {
    throw new Error('No series keys provided');
  }

  if (seriesKeys.length === 1) {
    const seriesName = seriesKeys[0];
    const seriesData = data.map((item) => item[seriesName]);

    return [
      {
        name: seriesName,
        data: seriesData,
      },
    ];
  }

  const uniqueSeriesNames = [...new Set(data.map((item) => item[seriesKeys[0]]))];
  const startingFromIndex = 0;
  const seriesKeysLength = seriesKeys.length;

  return uniqueSeriesNames.map((seriesName) => {
    const seriesData = categories.map((category) => {
      const entry = data.find((item) => item[categoryKey] === category && item[seriesKeys[0]] === seriesName);
      let result = 0;

      for (let i = startingFromIndex; i < seriesKeysLength; i++) {
        if (entry && isNumber(entry[seriesKeys[i]])) {
          result = entry[seriesKeys[i]] as number;
        }
      }
      return result;
    });

    return {
      name: seriesName,
      data: seriesData,
    };
  });
};
