import React from 'react';

import UnauthorizedPageLayout from '@/shared/components/UnauthorizedPageLayout.tsx';
import LoginForm from '@/modules/auth/components/LoginForm';

/**
 * Represents the login page component.
 *
 * @function
 * @returns {React.ReactNode} The rendered login page component.
 */
const LoginPage: React.FC = (): React.ReactNode => {
  return (
    <UnauthorizedPageLayout>
      <LoginForm />
    </UnauthorizedPageLayout>
  );
};

export default LoginPage;
