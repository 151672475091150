import { Paper, Button, Modal, Stack, styled, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';

const ContentWrapper = styled(Paper)(({ theme }) => ({
  width: 'max-content',
  maxWidth: '70vw',
  minWidth: '150px',
  maxHeight: `80vh`,
  background: theme.palette.background.paper,
  overflowY: 'auto',
  borderRadius: '12px',
  padding: 24,
  boxShadow: '24px',
  outline: 'none !important',
}));

type Props = {
  open: boolean;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({ open, text, onConfirm, onClose }: Props) => {
  return (
    <Modal
      closeAfterTransition
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Zoom in={open}>
        <ContentWrapper elevation={6}>
          <Typography variant={'h3'} textAlign={'center'}>
            {text}
          </Typography>
          <Stack direction="row" spacing={3} justifyContent={'center'} mt={2}>
            <Button type="button" variant="contained" color="error" size={'large'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              sx={{ maxWidth: 'max-content' }}
              size={'large'}
              variant="contained"
              type={'submit'}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </Stack>
        </ContentWrapper>
      </Zoom>
    </Modal>
  );
};

export default ConfirmationModal;
