import { httpClient } from '@/api/httpClient.ts';
import { USER_ROUTES } from '@/api/routes.ts';
import { AxiosResponse } from 'axios';
import {
  UsersPaginateResponse,
  UserCreateRequest,
  UserUpdateRequest,
  UserResponse,
  UserUpdateResponse,
  UsersFilters,
} from '@/modules/users/types.ts';

/**
 * Returns a list of users.
 *
 * @async
 * @returns {Promise<AxiosResponse<UsersPaginateResponse, any>>} The list of users.
 */
export const getUsersList = async (params: UsersFilters): Promise<AxiosResponse<UsersPaginateResponse, any>> => {
  return httpClient.get(USER_ROUTES.USERS, { params });
};

/**
 * Returns a user by ID.
 *
 * @async
 * @param {string} id - The ID of the user to return.
 * @returns {Promise<AxiosResponse<UserResponse, any>>} The user.
 */
export const getUser = async (id: string): Promise<AxiosResponse<UserResponse, any>> => {
  return await httpClient.get(`${USER_ROUTES.USERS}/${id}`);
};

/**
 * Creates a new user.
 *
 * @async
 * @param {UserCreateRequest} data - The user data to create.
 * @returns {Promise<AxiosResponse<UserResponse, any>>} The created user.
 */
export const createUser = async (data: UserCreateRequest): Promise<AxiosResponse<UserResponse, any>> => {
  return await httpClient.post(USER_ROUTES.USERS, data, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * Deletes a user by ID.
 *
 * @async
 * @param {string} id - The ID of the user to delete.
 * @returns {Promise<AxiosResponse<any, any>>} The response from the server.
 */
export const deleteUser = async (id: number): Promise<AxiosResponse<any, any>> => {
  return await httpClient.delete(`${USER_ROUTES.USERS}/${id}`);
};

/**
 * Updates a user by ID.
 *
 * @async
 * @param {number} id - The ID of the user to update.
 * @param {UserUpdateRequest} data - The updated user data.
 * @returns {Promise<AxiosResponse<UserUpdateResponse, any>>} The response from the server.
 */
export const updateUser = async (
  id: number,
  data: UserUpdateRequest
): Promise<AxiosResponse<UserUpdateResponse, any>> => {
  return await httpClient.put(`${USER_ROUTES.USERS}/${id}`, data, { headers: { 'Content-Type': 'application/json' } });
};

/**
 * Returns current User.
 *
 * @async
 * @returns {Promise<AxiosResponse<UserResponse, any>>} The user.
 */
export const getCurrentUser = async (): Promise<AxiosResponse<UserResponse, any>> => {
  return await httpClient.get(`${USER_ROUTES.ME}`);
};
