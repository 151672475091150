import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, Toolbar } from '@mui/material';

// project imports
import Header from '@/shared/components/Header.tsx';
import { MenuOrientation } from '@themes/types/config';
import useConfig from '@themes/hooks/useConfig';
import Sidebar from '@themes/layout/MainLayout/Sidebar';
import HorizontalBar from '@themes/layout/MainLayout/HorizontalBar';
import MainContentStyled from '@themes/layout/MainLayout/MainContentStyled';
import { handlerDrawerOpen, useGetMenuMaster } from '@themes/api/menu';
import { AskQuestionModal } from '@/modules/query/components/AskQuestionModal';
import { ShareWorkspaceModal } from '@/modules/workspaces/components/ShareWorkspaceModal';
import Loader from '@themes/components/Loader';
import { useLocalStorage } from 'usehooks-ts';
import { CURRENT_WORKSPACE_ID_KEY } from '@/modules/workspaces/constants';
import { useGetWorkspaceQuery } from '@/modules/workspaces/queries';
import { useIsWorkspaceOwner } from '@/modules/workspaces/hooks/useIsWorkspaceOwner.ts';

/**
 * Represents the structure and layout of a page.
 *
 * @interface
 */
interface IPageLayout {
  children?: React.ReactNode;
  title?: string;
  showHeaderEndAdornment?: boolean;
}

/**
 * Represents the authorized page layout component.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The content to render within the page layout.
 * @returns {React.ReactNode} - The rendered page layout component.
 */
const AuthorizedPageLayout: React.FC<IPageLayout> = ({
  children,
  title,
  showHeaderEndAdornment,
}: IPageLayout): React.ReactNode => {
  const [open, setOpen] = React.useState(false);
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useLocalStorage('drawerOpen', false);
  const theme = useTheme();
  const { menuOrientation, container, borderRadius } = useConfig();
  const { menuMaster, menuMasterLoading } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  const [workspaceId] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);
  const { data: workspace, isFetchedAfterMount } = useGetWorkspaceQuery(workspaceId);
  const isOwner = useIsWorkspaceOwner(workspace);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleShareModalOpen = () => setShareModalOpen(true);
  const handleShareModalClose = () => setShareModalOpen(false);
  const handleDrawerState = () => setIsDrawerOpen(!isDrawerOpen);

  handlerDrawerOpen(isDrawerOpen);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL;
  const menu = isHorizontal ? <HorizontalBar /> : <Sidebar />;

  if (menuMasterLoading && !isFetchedAfterMount) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" color="inherit" elevation={0} sx={{ bgcolor: 'background.default' }}>
        <Toolbar>
          <Header
            title={title}
            showHeaderEndAdornment={showHeaderEndAdornment}
            handleModalOpen={handleOpen}
            handleDrawerState={handleDrawerState}
            handleShareModalOpen={handleShareModalOpen}
            isWorkspaceOwner={isOwner}
          />
        </Toolbar>
      </AppBar>
      {menu}
      <MainContentStyled {...{ borderRadius, menuOrientation, open: drawerOpen, theme }}>
        <Container
          maxWidth={container ? 'lg' : false}
          {...(!container && { sx: { px: { xs: 0 } } })}
          sx={{ height: '100%' }}
        >
          {children}
        </Container>
      </MainContentStyled>
      {open && <AskQuestionModal isOpen={open} onClose={handleClose} />}
      {shareModalOpen && <ShareWorkspaceModal isOpen={shareModalOpen} handleModalClose={handleShareModalClose} />}
    </Box>
  );
};

export default AuthorizedPageLayout;
