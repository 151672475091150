import React from 'react';
import { Button, Box } from '@mui/material';

import AnimateButton from '@themes/components/extended/AnimateButton.tsx';

import google from '@assets/images/icons/google.svg';

const GoogleIcon = () => {
  return <Box component="img" src={google} alt={'google'} sx={{ height: 16, width: 'auto' }} />;
};

/**
 * Represents a google login button component.
 *
 * @component
 * @returns {React.ReactNode} The google login button component.
 */
export const GoogleSignIn = (): React.ReactNode => {
  return (
    <>
      <AnimateButton>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          startIcon={<GoogleIcon />}
          style={{ border: '1px solid #dadce0', color: '#3c4043' }}
        >
          Sign in with Google
        </Button>
      </AnimateButton>
    </>
  );
};
