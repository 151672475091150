import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdateWorkspaceMutation, useGetWorkspaceQuery } from '@/modules/workspaces/queries';
import { CURRENT_WORKSPACE_ID_KEY } from '@/modules/workspaces/constants';
import toast from 'react-hot-toast';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';
import { DEFAULT_WORKSPACE_NAME } from '@/modules/workspaces/constants';
import { useLocalStorage } from 'usehooks-ts';
import { useIsWorkspaceOwner } from '@/modules/workspaces/hooks/useIsWorkspaceOwner.ts';
import { ROUTES } from '@shared/constants';

// material-ui
import { Box, TextField, useTheme, Stack, Tooltip, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from '@/themes/components/Loader.tsx';

// material-ui icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const validationSchema = yup.object().shape({
  workspaceName: yup.string().min(5).max(255).required('Header text is required'),
});

type Props = {
  title?: string;
};

/**
 * The DynamicHeader component.
 *
 * @returns The DynamicHeader component.
 */
export const DynamicHeader = ({ title }: Props) => {
  const location = useLocation();
  const [workspaceId] = useLocalStorage(CURRENT_WORKSPACE_ID_KEY, 0);
  const inputRef = useRef<HTMLInputElement>(null);
  const hasAccess = usePowerUserAccess();
  const [workspaceName, setWorkspaceName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const theme = useTheme();
  const { data: workspace, isFetchedAfterMount } = useGetWorkspaceQuery(workspaceId);
  const isOwner = useIsWorkspaceOwner(workspace);
  const { mutate: updateWorkspace } = useUpdateWorkspaceMutation({
    loading: 'Renaming workspace...',
    success: 'Workspace renamed successfully',
  });

  // Discard button click handler
  const handleDiscard = () => {
    setEditMode(false);
    formik.resetForm();
    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from the text field
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Close the input field on pressing the 'Escape' key
    if (e.key === 'Escape') {
      handleDiscard();
    }

    // Save the input field on pressing the 'Enter' key
    if (e.key === 'Enter') {
      setTimeout(() => {
        formik.handleSubmit();
      }, 0);
    }
  };

  const formik = useFormik({
    initialValues: {
      workspaceName: workspaceName,
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof workspace !== 'undefined' && !!values.workspaceName && !formik.errors.workspaceName) {
        const newWorkspaceName = values.workspaceName.trim();
        // Update the UI first
        setWorkspaceName(newWorkspaceName);
        // Then, send the request
        updateWorkspace({ workspaceId: workspace.id, payload: { name: newWorkspaceName } });
      } else {
        toast.error('Cannot rename workspace');
        return;
      }

      handleDiscard();
    },
  });

  // Initialization
  useEffect(() => {
    if (typeof workspace !== 'undefined' && !!workspace.name) {
      const workspaceName = workspace.name;
      setWorkspaceName(workspaceName);
    } else {
      setWorkspaceName(DEFAULT_WORKSPACE_NAME);
    }
  }, [inputRef.current, workspace]);

  // Allow header edit only for owner, and only in the dashboard (workspace) page
  const canEdit = hasAccess && isOwner && location.pathname === ROUTES.DASHBOARD;
  // Styles for hover state based on access
  const hoverStyles = canEdit ? { cursor: 'text', backgroundColor: theme.palette.grey[100] } : {};

  if (!isFetchedAfterMount && !inputRef.current) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        alignItems: 'center',
        flexWrap: 'nowrap',
        cursor: title ? 'default' : 'text',
        width: '100%',
        pl: 2,
        '&:hover': title ? {} : hoverStyles,
        '&:focus-within': {
          cursor: 'text',
          backgroundColor: theme.palette.grey[100],
          borderRadius: '4px',
          border: `1px solid ${theme.palette.grey[300]}`,
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (hasAccess) {
          setEditMode(true);
        }
      }}
    >
      {title ? (
        <Typography
          component={'h2'}
          sx={{ fontFamily: theme.typography.fontFamily, fontSize: '1.75rem', fontWeight: 'bold' }}
        >
          {title}
        </Typography>
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          <StyledTextField
            disabled={!canEdit}
            id="workspaceName"
            name="workspaceName"
            value={formik.values.workspaceName}
            onChange={formik.handleChange}
            maxRows={1}
            fullWidth
            variant="standard"
            error={Boolean(formik.errors.workspaceName)}
            helperText={formik.errors.workspaceName}
            onKeyDown={handleKeyDown}
            inputProps={{
              onBlur: (e) => {
                e.stopPropagation();
                handleDiscard();
              },
            }}
            inputRef={inputRef}
          />
          {editMode && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ position: 'absolute', right: 0, bottom: -40 }}
            >
              <Tooltip
                title="Save"
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: 'white',
                  zIndex: 99,
                  opacity: 1,
                  boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.2)',
                }}
              >
                <IconButton
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    formik.handleSubmit();
                  }}
                >
                  <CheckCircleOutlineRoundedIcon color="success" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Discard"
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: 'white',
                  zIndex: 99,
                  opacity: 1,
                  boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.2)',
                }}
              >
                <IconButton
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleDiscard();
                  }}
                >
                  <HighlightOffIcon color="error" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </form>
      )}
    </Box>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-root': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.75rem',
    fontWeight: 'bold',
    '&::before': {
      borderBottom: 'none !important',
    },
    '&::after': {
      borderBottom: 'none !important',
    },
    // Styles for disabled state
    '.Mui-disabled': {
      color: 'black',
      WebkitTextFillColor: 'black', // Change text color when disabled
      backgroundColor: 'transparent',
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    '&:hover': {
      cursor: 'text',
    },
  },
  // Helper text styles (for errors)
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: '100%', // Position it below the input field
    left: 0,
    transform: 'translateY(2px)', // Adjust the gap between the input and helper text
    margin: 0,
    padding: 0,
  },
}));
