import { FC } from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { CellFormat } from '@shared/helpers/formatCellValue.ts';
import ModalDrawer, { ModalDrawerProps } from '@shared/components/ModalDrawer.tsx';
import ListItem from '@mui/material/ListItem';

const NumberCellFormats: { label: string; example: string; format: CellFormat }[] = [
  { format: CellFormat.Number, label: 'Number', example: '1,000.12' },
  { format: CellFormat.Percent, label: 'Percent', example: '10.12%' },
  { format: CellFormat.Scientific, label: 'Scientific', example: '1.01E+3' },
  { format: CellFormat.Accounting, label: 'Accounting', example: '$ (1,000.12)' },
  { format: CellFormat.Financial, label: 'Financial', example: '(1,000.12)' },
  { format: CellFormat.Currency, label: 'Currency', example: '$1,000.12' },
  { format: CellFormat.Hour, label: 'Hour', example: '12hrs' },
  { format: CellFormat.Minute, label: 'Minute', example: '12mins' },
  { format: CellFormat.Second, label: 'Second', example: '12secs' },
];

const DateCellFormats: { label: string; example: string; format: CellFormat }[] = [
  { format: CellFormat.Date, label: 'Date', example: 'January 01 2024' },
  { format: CellFormat.DateTime, label: 'Date Time', example: 'Monday 1 January 2024 at 12:00 am' },
  { format: CellFormat.ShortDate, label: 'Short Date', example: 'Jan 1 2024' },
  { format: CellFormat.ShortDateTime, label: 'Short Date Time', example: 'Jan 1 2024 12:00 am' },
  { format: CellFormat.MonthYear, label: 'Month Year', example: 'January 2024' },
  { format: CellFormat.MonthDay, label: 'Month Day', example: 'January 01' },
  { format: CellFormat.YearMonthDay, label: 'Year Month Day', example: '2024-12-31' },
  { format: CellFormat.ShortMonthDay, label: 'Short Month Day', example: 'Jan-01' },
];

const AllFormats: { label: string; example: string; format: CellFormat }[] = [...NumberCellFormats, ...DateCellFormats];

export type FormatDrawerType = 'number' | 'date' | 'string' | 'all';

interface DataFormatOptionsDrawerProps extends ModalDrawerProps {
  dataType: FormatDrawerType;
  onFormatSelect: (format: CellFormat) => void;
}

export const DataFormatOptionsDrawer: FC<DataFormatOptionsDrawerProps> = ({
  dataType,
  onFormatSelect,
  ...drawerProps
}) => {
  let cellFormatters: { label: string; example: string; format: CellFormat }[] = [];

  switch (dataType) {
    case 'number':
      cellFormatters = NumberCellFormats;
      break;
    case 'date':
      cellFormatters = DateCellFormats;
      break;
    case 'all':
      cellFormatters = AllFormats;
      break;
    case 'string':
    default:
      cellFormatters = [];
      break;
  }

  return (
    <ModalDrawer {...drawerProps}>
      <Typography
        variant="h4"
        sx={{
          padding: (theme) => theme.spacing(0.5, 0, 2),
        }}
      >
        Format Options
      </Typography>

      <List
        disablePadding
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        {cellFormatters.map((format) => (
          <ListItemButton
            key={format.format}
            onClick={() => onFormatSelect(format.format)}
            sx={{ borderRadius: '4px', marginBottom: 1 }}
          >
            <ListItemText
              primary={
                <Typography variant="subtitle1" fontWeight="bold">
                  {format.label}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {format.example}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
        {dataType === 'string' && (
          <ListItem>
            <ListItemText>No formats available for selected data type</ListItemText>
          </ListItem>
        )}
      </List>
    </ModalDrawer>
  );
};
