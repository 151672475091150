const AUTH = '/auth';

export const AUTH_ROUTES = {
  AUTH: {
    LOGIN: `${AUTH}/sign-in`,
    REFRESH: `${AUTH}/refresh-token`,
  },
};

export const USER_ROUTES = {
  USERS: '/users',
  USER: '/users/:id',
  ME: '/users/me',
};

export const DATA_SOURCE_ROUTES = {
  DATA_SOURCES: '/datasources',
  DATA_SOURCE: '/datasources/:id',
  BIG_QUERY_DATASETS: '/datasources/:id/datasets/big-query',
};

export const CONNECTOR_ROUTES = {
  CONNECTORS: '/connectors',
  CONNECTOR: '/connectors/:id',
};

export const WORKSPACE_ROUTES = {
  WORKSPACES: '/workspaces',
  WORKSPACE: '/workspaces/:id',
  WORKSPACES_BY_USER: '/workspaces/user/:id',
  SHARE_WORKSPACE: '/workspaces/:id/share',
  UNSHARE_WORKSPACE: '/workspaces/:id/unshare',
  SHARED_WORKSPACE_RECIPIENTS: '/workspaces/:id/recipients',
};

export const V1_ROUTES = {
  QUERY: '/query',
};
