import { httpClient } from '@/api/httpClient.ts';
import { AUTH_ROUTES } from '@/api/routes.ts';
import { Login, LoginResponse } from '@/modules/auth/types.ts';

/**
 * Logs in a user.
 *
 * @async
 * @param {Login} data - The login form values.
 */
export const login = async (data: Login) => {
  return httpClient.post<LoginResponse>(AUTH_ROUTES.AUTH.LOGIN, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};
