/**
 * Checks if the given data is empty.
 *
 * A value is considered empty if:
 * - It is `null` or `undefined`.
 * - It is a string with only whitespace or an empty string.
 * - It is an array with no elements.
 *
 * Other data types (e.g., numbers, objects) are considered not empty by default.
 *
 * @param data - The value to check for emptiness. Can be of any type.
 * @returns `true` if the value is empty, otherwise `false`.
 */
export const isEmpty = (data: unknown): boolean => {
  // Null or undefined is empty
  if (data === null || data === undefined) return true;
  // Empty or whitespace-only string
  if (typeof data === 'string') return data.trim() === '';
  // Empty array
  if (Array.isArray(data)) return data.length === 0;
  // Numbers, objects, and other types are not empty
  return false;
};
