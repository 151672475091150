/**
 * @fileoverview Datasources list of connected page
 */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePowerUserAccess } from '@/modules/auth/hooks/usePowerUserAccess';

// material-ui
import { Button, Grid, InputAdornment, OutlinedInput, Typography, Stack, Pagination } from '@mui/material';

// project imports
import AuthorizedPageLayout from '@/shared/components/AuthorizedPageLayout.tsx';

import MainCard from '@themes/components/cards/MainCard';
import AnimateButton from '@themes/components/extended/AnimateButton';
import { ROUTES } from '@shared/constants.ts';
import DataSourceList from '@/modules/datasources/components/DataSourceList/DataSource';

// assets
import { IconSearch } from '@tabler/icons-react';
/**
 * Users page component, a React functional component.
 *
 * @returns {React.ReactNode} The rendered component JSX.
 */
const DataSourcesListPage: React.FC = () => {
  const hasAccess = usePowerUserAccess();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  return (
    <AuthorizedPageLayout title={'Data Sources'}>
      <MainCard
        title={
          <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h3">Connected data sources</Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1}>
                <OutlinedInput
                  id="input-search-list-style1"
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start">
                      <IconSearch stroke={1.5} size="16px" />
                    </InputAdornment>
                  }
                  size="small"
                />
                {hasAccess && (
                  <AnimateButton>
                    <Button
                      color="secondary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={() => navigate(ROUTES.DATA_SOURCES.TYPE)}
                    >
                      Connect Data Source
                    </Button>
                  </AnimateButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        }
        content={false}
      >
        <DataSourceList />
        <Grid item xs={12} sx={{ p: 3 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Pagination
                page={page}
                // TODO: Add count once connected to the api
                count={0}
                color="primary"
                onChange={(_, page) => setPage(page)}
              />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </AuthorizedPageLayout>
  );
};

export default DataSourcesListPage;
