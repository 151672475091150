import { FC } from 'react';
import { List, ListItemText } from '@mui/material';
import { CustomStylesTable } from '@modules/query/components/table/customization/CustomStylesTable';
import Typography from '@mui/material/Typography';
import { TableColorScheme } from '@modules/query/types';
import { DEFAULT_TABLE_COLOR_SCHEMES } from '@shared/constants';
import { formatRgbaToString } from '@shared/helpers/colorFormatters';
import ModalDrawer, { ModalDrawerProps } from '@shared/components/ModalDrawer.tsx';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';

interface TableStyleDrawerProps extends ModalDrawerProps {
  colorScheme: TableColorScheme;
  onColorChange: (scheme: TableColorScheme) => void;
}

export const TableStyleDrawer: FC<TableStyleDrawerProps> = ({ onColorChange, colorScheme, ...drawerProps }) => {
  return (
    <ModalDrawer {...drawerProps} sx={{ zIndex: 999999 }}>
      <Typography
        variant="h4"
        sx={{
          padding: (theme) => theme.spacing(0.5, 0, 2),
        }}
      >
        Table Theme
      </Typography>

      <List>
        {DEFAULT_TABLE_COLOR_SCHEMES.map((scheme, index) => (
          <ListItemButton
            key={index}
            onClick={() => onColorChange(scheme.color)}
            sx={{
              borderRadius: '4px',
              marginBottom: 1,
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                backgroundColor: formatRgbaToString(scheme.color.header),
                borderRadius: '50%',
                marginRight: 2,
                '&:hover': {
                  backgroundColor: formatRgbaToString(scheme.color.header),
                },
              }}
            />
            <ListItemText primary={scheme.name} />
          </ListItemButton>
        ))}
      </List>
      <CustomStylesTable onColorChange={onColorChange} colorScheme={colorScheme} />
    </ModalDrawer>
  );
};
