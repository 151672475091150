import { FC } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Tune } from '@mui/icons-material';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import DownloadIcon from '@/assets/images/icons/download.png';
import { PinButton } from '@modules/query/components/PinButton.tsx';

interface DrawerProps {
  onOpen: () => void;
  isOwner: boolean;
  isIconButtonClicked: boolean;
  handleChartSave: () => void;
}

export const ModalTableToolbar: FC<DrawerProps> = ({ onOpen, isOwner, isIconButtonClicked, handleChartSave }) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <Tooltip title="Export as CSV">
        <IconButton onClick={() => apiRef.current.exportDataAsCsv()} color="primary">
          <img src={DownloadIcon} alt="download" className="ico-download" width="26" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Customization">
        <IconButton onClick={onOpen} color="primary">
          <Tune sx={{ color: 'gray' }} />
        </IconButton>
      </Tooltip>
      <PinButton isOwner={isOwner} handleChartSave={handleChartSave} isIconButtonClicked={isIconButtonClicked} />
    </GridToolbarContainer>
  );
};
