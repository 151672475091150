import Chart from 'react-apexcharts';
import { ChartColorScheme } from '@/modules/query/types';
import { useCallback, useMemo } from 'react';
import { WorkspaceItemCard } from '@modules/workspaces/components/WorkspaceItemCard.tsx';
import { formatCellValue, ItemFormatMap } from '@shared/helpers/formatCellValue.ts';
import getDefaultChartOptions, { formatNumbersInString } from '@shared/helpers/charts/chartPropsHelper.ts';
import { ChartData } from '@modules/workspaces/types.ts';

interface ChartItemContentProps {
  chartData: ChartData;
  query?: string;
  colorScheme?: ChartColorScheme;
  formatOptions?: ItemFormatMap;
}

export const ChartItemContent = ({ chartData, query, colorScheme, formatOptions }: ChartItemContentProps) => {
  const type = chartData?.chart?.type;
  const series = chartData?.series;
  const categories = chartData?.categories;
  const yAxisColumnName = chartData?.yAxisColumnName;
  const xAxisColumnName = chartData?.xAxisColumnName;

  const yAxisFormatter: ApexDataLabels['formatter'] = useCallback(
    (val) => {
      const formatType = yAxisColumnName ? formatOptions?.[yAxisColumnName] : undefined;
      if (formatType) {
        return formatCellValue(val, formatType);
      }

      return formatNumbersInString(val);
    },
    [formatOptions, yAxisColumnName]
  );

  const xAxisFormatter = useCallback(
    (val) => {
      const categoryFormat = xAxisColumnName ? formatOptions?.[xAxisColumnName] : undefined;
      if (categoryFormat) {
        return formatCellValue(val, categoryFormat);
      }
      return formatNumbersInString(val);
    },
    [formatOptions, xAxisColumnName]
  );

  const options = useMemo(() => {
    const chartOptions = getDefaultChartOptions({
      type: type ?? 'bar',
      colorScheme,
      xAxisFormatter,
      yAxisFormatter,
    });
    return {
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        categories: categories ?? [],
      },
    };
  }, [chartData?.chart?.type, colorScheme, categories, xAxisFormatter, yAxisFormatter]);

  return (
    <WorkspaceItemCard query={query}>
      <Chart type={type} series={series} options={options} width="100%" height="100%" />
    </WorkspaceItemCard>
  );
};
