import { useEffect, useState } from 'react';
import { Workspace } from '@/modules/workspaces/types';
import { useCurrentUser } from '@/modules/auth/hooks/useCurrentUser';

// Check if the user is the owner of the workspace.
export const useIsWorkspaceOwner = (workspace?: Workspace): boolean => {
  const [isWorkspaceOwner, setIsWorkspaceOwner] = useState<boolean>(false);
  const user = useCurrentUser();

  useEffect(() => {
    if (workspace && user) {
      setIsWorkspaceOwner(workspace.user_id === user.id);
    }
  }, [workspace, user]);

  return isWorkspaceOwner;
};
