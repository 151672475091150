import { useState } from 'react';
import { Box, Button, Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import { GoogleIcon } from '@/modules/auth/forms/GoogleOAuth';
import CardActions from '@mui/material/CardActions';
import { Connector } from '@modules/datasources/types.ts';
import * as _ from 'lodash';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment/moment';

type Props = {
  type: string;
  connector: Connector;
  onConnect: (connectorId: number) => void;
  onDelete: (connector: Connector) => void;
  isDisabled?: boolean;
};

const ConnectorCard = ({ type, connector, onConnect, onDelete, isDisabled = false }: Props) => {
  const [hover, setHover] = useState(false);

  const handleOnConnect = () => {
    onConnect(connector.id);
  };

  switch (type) {
    case 'Google BigQuery':
      return (
        <Box sx={{ position: 'relative' }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          {hover && (
            <HighlightOffIcon
              color={'error'}
              onClick={() => onDelete(connector)}
              sx={{ position: 'absolute', top: -8, right: -8, cursor: 'pointer', zIndex: 10 }}
            />
          )}
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, pb: 0 }}>
              <Typography variant="h3" sx={{ cursor: 'default' }}>
                Google OAuth2
              </Typography>
              <GoogleIcon height={24} />
              {connector.config.email && (
                <Typography variant="h4">{_.truncate(connector.config.email, { length: 24 })}</Typography>
              )}
              {connector.config.image && (
                <Stack flex-direction={'row'} justifyContent={'center'}>
                  <img
                    src={connector.config.image}
                    alt="user's profile image"
                    width={60}
                    height={60}
                    style={{ borderRadius: '50%' }}
                  />
                </Stack>
              )}
            </CardContent>
            <CardActions sx={{ py: 2, justifyContent: 'center', position: 'relative' }}>
              <Button
                type="button"
                variant="contained"
                size={'large'}
                color="secondary"
                onClick={handleOnConnect}
                disabled={isDisabled}
              >
                Connect
              </Button>
              <Tooltip title="Created at date" placement="right" sx={{ position: 'relative', right: 0 }}>
                <Box
                  component={'span'}
                  sx={{
                    justifySelf: 'end',
                    position: 'absolute',
                    bottom: 4,
                    right: 10,
                    cursor: 'default',
                    color: (theme) => theme.palette.grey[400],
                  }}
                >
                  {moment(connector.created_at).format('DD-MM-YYYY')}
                </Box>
              </Tooltip>
            </CardActions>
          </Card>
        </Box>
      );
  }
  return null;
};

export default ConnectorCard;
