import { TableColorScheme } from '@modules/query/types';

/**
 * Defines the routes for the application.
 *
 * @type {Object}
 * @property {string} DASHBOARD - The route for the dashboard page.
 * @property {string} QUERIES - The route for the queries page.
 * @property {string} SETTINGS - The route for the settings page.
 * @property {string} USERS - The routes related to users.
 * @property {string} USERS.LIST - The route for the users list page.
 * @property {string} USERS.CREATE - The route for the create user page.
 * @property {Object} AUTH - The routes related to authentication.
 * @property {string} AUTH.LOGIN - The route for the login page.
 * @property {string} AUTH.SIGNUP - The route for the signup page.
 * @property {string} AUTH.NEW_PASSWORD - The route for the new password page.
 * @property {string} AUTH.RESET_PASSWORD - The route for the reset password page.
 * @property {string} AUTH.OTP_CODE - The route for the otp code page.
 */
export const ROUTES = {
  DASHBOARD: '/',
  QUERIES: '/queries',
  SETTINGS: '/settings',
  USERS: {
    LIST: '/users',
    CREATE: '/users/create',
    USER: '/users/:id',
    EDIT: '/users/:id/edit',
  },
  AUTH: {
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
    RESET_PASSWORD: '/auth/reset-password',
    NEW_PASSWORD: '/auth/new-password',
    OTP_CODE: '/auth/otp-code',
  },
  DATA_SOURCES: {
    LIST: '/data-sources/',
    TYPE: '/data-sources/type',
    CONNECT: '/data-sources/connect',
    DATA_SOURCE: '/data-sources/:id',
    EDIT: '/data-sources/:id/edit',
  },
};

export const REFRESH_TOKEN_LIFETIME = 1000 * 60 * 60 * 6; // 6 hours (same as on backend)

export const SUPPORTED_CHART_TYPES = [
  'bar',
  'line',
  'area',
  'boxPlot',
  'rangeBar',
  'rangeArea',
  'heatmap',
  'treemap',
  'donut',
  'pie',
  'radar',
];

export const LOCAL_STORAGE = {
  IS_LOGGED_IN: 'isLoggedIn',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
  TOKEN_TYPE: 'token_type',
};

export const BIGQUERY_CONNECT_TITLE = 'Connect to Google BigQuery';

// TODO: Probably worth it to move it to the theme?
export const DEFAULT_TABLE_COLOR_SCHEME: TableColorScheme = {
  header: { r: 243, g: 229, b: 245, a: 1 },
  primaryRow: { r: 243, g: 229, b: 245, a: 0.8 },
  secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
};

export const DEFAULT_TABLE_COLOR_SCHEMES: Array<{ name: string; color: TableColorScheme }> = [
  {
    name: 'Green Theme',
    color: {
      header: { r: 224, g: 247, b: 233, a: 1 },
      primaryRow: { r: 224, g: 247, b: 233, a: 0.8 },
      secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
    },
  },
  {
    name: 'Blue Theme',
    color: {
      header: { r: 232, g: 245, b: 253, a: 1 },
      primaryRow: { r: 232, g: 245, b: 253, a: 0.8 },
      secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
    },
  },
  {
    name: 'Red Theme',
    color: {
      header: { r: 255, g: 205, b: 210, a: 1 },
      primaryRow: { r: 255, g: 205, b: 210, a: 0.8 },
      secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
    },
  },
  {
    name: 'Yellow Theme',
    color: {
      header: { r: 255, g: 244, b: 229, a: 1 },
      primaryRow: { r: 255, g: 244, b: 229, a: 0.8 },
      secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
    },
  },
  {
    name: 'Purple Theme',
    color: {
      header: { r: 243, g: 229, b: 245, a: 1 },
      primaryRow: { r: 243, g: 229, b: 245, a: 0.8 },
      secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
    },
  },
  {
    name: 'Gray Theme',
    color: {
      header: { r: 245, g: 245, b: 245, a: 1 },
      primaryRow: { r: 245, g: 245, b: 245, a: 0.8 },
      secondaryRow: { r: 255, g: 255, b: 255, a: 1 },
    },
  },
];

export const NO_RESULTS_MESSAGE = 'No results found.';
