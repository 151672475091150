import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getWorkspace,
  getWorkspaceList,
  updateWorkspace,
  createWorkspace,
  deleteWorkspace,
  shareWorkspace,
  getSharedWorkspaceRecipients,
  unshareWorkspace,
} from './api';
import {
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  ShareWorkspaceRequest,
  UnshareWorkspaceRequest,
} from './types';
import { withErrorHandling } from '@/shared/helpers/withErrorHandling';
import { queryClient } from '@/api/queryClient';

export const GET_WORKSPACE_QUERY_KEY = 'xanaytica/workspace';
export const GET_WORKSPACE_BY_USER_ID_QUERY_KEY = 'xanaytica/workspaces/user';
export const GET_WORKSPACE_LIST_QUERY_KEY = 'xanaytica/workspaces';
export const GET_SHARED_WORKSPACE_RECIPIENTS_QUERY_KEY = 'xanaytica/shared-workspace-recipients';

/**
 * Custom hook for retrieving a workspace.
 * @returns The query object.
 */
export const useGetWorkspaceQuery = (workspaceId: number) => {
  return useQuery({
    queryKey: [GET_WORKSPACE_QUERY_KEY, workspaceId],
    queryFn: async () => {
      const response = await getWorkspace(workspaceId);
      return response.data;
    },
    retry: false,
  });
};

/**
 * Custom hook for retrieving a list of workspaces.
 *
 * @param page - The page number.
 * @param pageSize - The page size.
 * @returns The query object.
 */
export const useGetWorkspaceListQuery = (page: number = 1, pageSize: number = 50, disabled = false) => {
  return useQuery({
    gcTime: 1000 * 60 * 60 * 24 * 7, // 7 days
    queryKey: [GET_WORKSPACE_LIST_QUERY_KEY],
    queryFn: async () => {
      const response = await getWorkspaceList(page, pageSize);
      return response.data;
    },
    retry: false,
    enabled: !disabled,
  });
};

/**
 * Custom hook for updating a workspace.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the workspace and workspace list queries on success.
 * @returns The mutation object.
 */
export const useUpdateWorkspaceMutation = ({
  loading = 'Pinning result to the workspace...',
  success = 'Result pinned to workspace.',
}: {
  loading?: string;
  success?: string;
} = {}) => {
  return useMutation<UpdateWorkspaceResponse, Error, { workspaceId: number; payload: UpdateWorkspaceRequest }>({
    mutationFn: async ({ workspaceId, payload }) => {
      const response = await withErrorHandling(updateWorkspace(workspaceId, payload), {
        loading,
        success,
      });
      return response.data;
    },
    onSuccess: (updatedWorkspace) => {
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_QUERY_KEY, updatedWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_LIST_QUERY_KEY] });
    },
  });
};

/**
 * Custom hook for creating a workspace.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the workspace list query on success.
 * @returns The mutation object.
 */
export const useCreateWorkspaceMutation = () => {
  return useMutation<CreateWorkspaceResponse, Error, CreateWorkspaceRequest>({
    mutationFn: async (payload) => {
      const response = await withErrorHandling(createWorkspace(payload), {
        loading: 'Creating workspace...',
        success: 'Workspace created successfully',
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_LIST_QUERY_KEY] });
    },
  });
};

/**
 * Custom hook for deleting a workspace.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the workspace list query on success.
 * @returns The mutation object.
 */
export const useDeleteWorkspaceMutation = () => {
  return useMutation<void, Error, number>({
    mutationFn: async (workspaceId) => {
      await withErrorHandling(deleteWorkspace(workspaceId), {
        loading: 'Deleting workspace...',
        success: 'Workspace deleted successfully',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_WORKSPACE_LIST_QUERY_KEY] });
    },
  });
};

/**
 * Custom hook for sharing a workspace.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * @returns The mutation object.
 */
export const useShareWorkspaceMutation = () => {
  return useMutation<void, Error, { workspaceId: number; payload: ShareWorkspaceRequest[] }>({
    mutationFn: async ({ workspaceId, payload }) => {
      await withErrorHandling(shareWorkspace(workspaceId, payload), {
        loading: 'Sharing workspace...',
        success: 'Workspace shared successfully',
      });
    },
  });
};

/**
 * Custom hook for retrieving a list of recipients workspace shared with.
 * It uses the `useQuery` hook from `react-query` to handle the query logic.
 * @returns The query object.
 */
export const useGetWorkspaceRecipientsQuery = (workspaceId: number) => {
  return useQuery({
    queryKey: [GET_SHARED_WORKSPACE_RECIPIENTS_QUERY_KEY, workspaceId],
    queryFn: async () => {
      const response = await getSharedWorkspaceRecipients(workspaceId);
      return response.data;
    },
    retry: false,
  });
};

/**
 * Custom hook for unsharing a workspace.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * @returns The mutation object.
 */
export const useUnshareWorkspaceMutation = () => {
  return useMutation<void, Error, { workspaceId: number; payload: UnshareWorkspaceRequest }>({
    mutationFn: async ({ workspaceId, payload }) => {
      await withErrorHandling(unshareWorkspace(workspaceId, payload), {
        loading: 'Removing user from the list of shared workspace recipients...',
        success: 'User removed from the list of shared workspace recipients',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_SHARED_WORKSPACE_RECIPIENTS_QUERY_KEY] });
    },
  });
};
