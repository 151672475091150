import { UseQueryResult, useMutation, useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  User,
  UserCreateRequest,
  UserCreateResponse,
  UsersFilters,
  UsersPaginateResponse,
  UserUpdateRequest,
} from '@/modules/users/types.ts';
import { createUser, deleteUser, getUser, getUsersList, updateUser } from '@/modules/users/api';
import { ROUTES } from '@shared/constants';
import * as _ from 'lodash';
import { withErrorHandling } from '@/shared/helpers/withErrorHandling';
import { FormikErrors, FormikValues } from 'formik';

export const USERS_LIST_QUERY_KEY = 'xanalytica/users-list';
export const USER_QUERY_KEY = 'xanalytica/user';

/**
 * Custom hook for fetching the list of users.
 * It uses the `useQuery` hook from `react-query` to handle the query logic.
 * @param filters - users filters
 * @param disabled - The flag to disable the query.
 * @returns The query object.
 */
export const useUsersListQuery = (filters: UsersFilters, disabled = false): UseQueryResult<UsersPaginateResponse> =>
  useQuery<UsersPaginateResponse>({
    queryKey: [USERS_LIST_QUERY_KEY, filters],
    queryFn: async () => {
      const params = _.omitBy(filters, (val) => ['', null, undefined].includes(val as string)) as UsersFilters;
      const response = await getUsersList(params);
      return response.data;
    },
    enabled: !disabled,
    placeholderData: keepPreviousData,
  });

/**
 * Custom hook for fetching a user.
 * It uses the `useQuery` hook from `react-query` to handle the query logic.
 * @param id - The user ID.
 * @returns The query object.
 */
export const useUserQuery = (id: string) => {
  const query = useQuery<User>({
    queryKey: [USER_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getUser(id);
      return response.data;
    },
    enabled: !!id,
  });

  return query;
};

/**
 * Custom hook for creating a new user.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the users list query and navigates to the users list page on success.
 * @returns The mutation object.
 */
export const useCreateUserMutation = ({ setErrors }: { setErrors: (errors: FormikErrors<FormikValues>) => void }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation<UserCreateResponse, Error, UserCreateRequest>({
    mutationFn: async (payload) => {
      const response = await withErrorHandling(createUser(payload), {
        loading: 'User creation in progress...',
        success: 'User created successfully',
        setErrors,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_LIST_QUERY_KEY] });
      navigate(ROUTES.USERS.LIST);
    },
  });

  return mutation;
};

/**
 * Custom hook for updating a user.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the users list and user query and navigates back on success.
 * @returns The mutation object.
 */
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<UserUpdateRequest, Error, { userId: number; payload: UserUpdateRequest }>({
    mutationFn: async ({ userId, payload }) => {
      const response = await withErrorHandling(updateUser(userId, payload), {
        loading: 'User update in progress...',
        success: 'User updated successfully',
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_LIST_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
    },
  });

  return mutation;
};

/**
 * Custom hook for deleting a user.
 * It uses the `useMutation` hook from `react-query` to handle the mutation logic.
 * It also invalidates the users list query on success.
 * @returns The mutation object.
 */
export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<number, Error, number>({
    mutationFn: async (id) => {
      const response = await withErrorHandling(deleteUser(id), {
        loading: 'User deletion in progress...',
        success: 'User deleted successfully',
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_LIST_QUERY_KEY] });
    },
  });

  return mutation;
};
